<template>
  <div>
    <div class="is-flex is-align-content-center is-justify-content-center is-text-center h-100">
      <button class="control-button control-button-minus is-flex is-align-items-center" :disabled="disabled || localValue === minimum" @click="change(-1)">
        <svg width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.4375 1.29999H16.5625" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <div class="order-item-quantity is-size-6">
        <span class="is-text-center">{{ localValue }}</span>
      </div>
      <button class="control-button control-button-plus is-flex is-align-items-center" :disabled="disabled || localValue === maximum" @click="change(+1)">
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 9H16.125" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.5625 1.4375V16.5625" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
    <!--<a href="#" class="is-flex has-text-danger is-align-content-center is-justify-content-center is-text-center is-size-7 mt-1" @click.prevent="change(-localValue)">
      <span v-if="localValue > 1" >Reset</span>
      <span v-else>&nbsp;</span>
    </a>-->
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1
    },
    minimum: {
      type: Number,
      default: 1
    },
    maximum: {
      type: Number,
      default: 99
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localValue: this.value
    }
  },
  methods: {
    change (quantity) {
      this.localValue += quantity
      if (this.localValue < this.minimum) {
        this.localValue = this.minimum
      } else if (this.localValue >= this.maximum) {
        this.localValue = this.maximum
      }
      this.$emit('input', this.localValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.h-100 {
  height: 100%;
}
.control-button {
  display: flex;
  justify-content: center;
  margin: 0px;
  width: 2.5rem;
  text-align: center;
  vertical-align: middle;
  padding: 4px 7px;
  background: white;
  user-select: none;
  font-weight: bold;
  border: 0;
  outline: 0;
  background: #C5C5C5;
  height: 2.5rem;
}
.control-button:hover {
  cursor: pointer;
  background-color: #d6d6d6;
}
.quantity-display {
  text-align: center;
  margin: 0px;
  padding: 7px 16px;
  background-color: white;
  font-weight: bold;
}
.control-button-minus {
  border-radius: 4px 0px 0px 4px;
  border-left: 1px solid #C5C5C5;
  border-top: 1px solid #C5C5C5;
  border-bottom: 1px solid #C5C5C5;
}
.control-button-plus {
  border-radius: 0px 4px 4px 0px;
  border-right: 1px solid #C5C5C5;
  border-top: 1px solid #C5C5C5;
  border-bottom: 1px solid #C5C5C5;
}
.is-small {
  .control-button {
    width: auto;
    padding: 4px 7px;
  }
  .quantity-display {
    padding: 5px 10px;
  }
}

.order-item-quantity {
  display: flex;
  align-items: center;
  //background: #182632;
  //color: #ffffff;
  background: #ffffff;
  font-weight: 600;
  padding: 0px 14px;
  margin-right: 0px;
  text-align: center;
  width: 2.5rem;
  vertical-align: text-bottom;
  border: unset;
  max-height: 40px;
  justify-content: center;


  @media (max-width: 580px) {
    font-size: 12px;
    padding: 0 5px;
  }
}

</style>
