
<div class="columns is-vcentered is-mobile">
  <div class="column is-3">
    <figure v-if="product.photo_key" class="image height-70">
      <img :src="imageUrl(product.photo_key, { resize: { width: 132, height: 66 }})">
    </figure>
  </div>
  <div class="column is-8">
    <h5 class="title is-5 is-size-5-mobile">
      {{ product.title }}
    </h5>
    <p class="subtitle is-6">
      {{ product.code }}
    </p>
  </div>
</div>
