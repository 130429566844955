
<ul class="columns is-gapless is-multiline">
  <li v-for="city in cities" :key="city.id" class="column is-2">
    <a
      :href="cityPath(city)"
      class="button is-text is-text-decoration-none is-fullwidth"
    >
      {{ city.name }}
    </a>
  </li>
</ul>
