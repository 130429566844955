const CLOUDFRONT_HOST = import.meta.env.VITE_CLOUDFRONT_HOST

export const defaultEdits = {
  resize: {
    width: 800,
    height: 600,
    fit: 'contain'
  },
  webp: {
    quality: 50
  }
}

export const storageDefaults = {
  bucket: import.meta.env.VITE_AWS_BUCKET || import.meta.env.VUE_APP_AWS_BUCKET,
  region: import.meta.env.VITE_AWS_REGION || import.meta.env.VUE_APP_AWS_REGION,
  edits: defaultEdits
}

export const imageUrl = (key, edits) => {
  const params = {
    ...storageDefaults,
    key
  }
  if (edits) {
    params.edits = { ...edits }
  }
  const json = JSON.stringify(params)
  return `${CLOUDFRONT_HOST}/${btoa(json)}`
}
