<template>
  <span style="width: 25px; text-align: center;" v-if="type === 'non_working_refrigerated'">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9997 6.01172C8.56215 6.01172 5.76367 8.80931 5.76367 12.2505C5.76367 15.6918 8.56003 18.4894 11.9997 18.4894C15.4394 18.4894 18.2358 15.6918 18.2358 12.2505C18.2358 8.80931 15.4394 6.01172 11.9997 6.01172ZM11.9997 17.115C9.31804 17.115 7.13531 14.9334 7.13531 12.2484C7.13531 9.56553 9.31591 7.38184 11.9997 7.38184C14.6836 7.38184 16.8642 9.56341 16.8642 12.2484C16.8642 14.9334 14.6836 17.115 11.9997 17.115Z"
        fill="#3F4756" />
      <path
        d="M14.5321 5.96899C14.617 6.00297 14.704 6.01997 14.7911 6.01997C15.0607 6.01997 15.3198 5.85853 15.4281 5.59088L17.1989 1.19375C17.339 0.843251 17.1713 0.443898 16.8209 0.299451C16.4706 0.159253 16.0714 0.327066 15.927 0.677562L14.1541 5.07469C14.0118 5.42519 14.1796 5.82666 14.5321 5.96899Z"
        fill="#3F4756" />
      <path
        d="M18.2357 9.59043C18.3461 9.85171 18.6009 10.0068 18.8684 10.0068C18.9576 10.0068 19.0489 9.98978 19.136 9.95367L23.4993 8.09923C23.8475 7.95266 24.0089 7.54693 23.8624 7.19856C23.7159 6.85019 23.3124 6.68875 22.9621 6.83532L18.5988 8.68976C18.2505 8.84058 18.0892 9.24206 18.2357 9.59043Z"
        fill="#3F4756" />
      <path
        d="M23.3158 17.4991C23.5855 17.4991 23.8445 17.3377 23.9528 17.07C24.0929 16.7195 23.9252 16.3202 23.5748 16.1757L19.1797 14.402C18.8293 14.2618 18.4301 14.4296 18.2858 14.7801C18.1456 15.1306 18.3134 15.53 18.6637 15.6744L23.0589 17.4481C23.1459 17.48 23.233 17.4991 23.3158 17.4991Z"
        fill="#3F4756" />
      <path
        d="M14.6495 18.4807C14.3013 18.6273 14.14 19.033 14.2865 19.3814L16.1401 23.7467C16.2505 24.008 16.5053 24.163 16.7728 24.163C16.862 24.163 16.9533 24.146 17.0404 24.1099C17.3886 23.9633 17.5499 23.5576 17.4034 23.2093L15.5498 18.844C15.4033 18.4956 15.002 18.3321 14.6495 18.4807Z"
        fill="#3F4756" />
      <path
        d="M7.18074 24.1985C7.26567 24.2325 7.35273 24.2495 7.43978 24.2495C7.70944 24.2495 7.96848 24.088 8.07676 23.8204L9.8497 19.4232C9.98984 19.0727 9.8221 18.6734 9.47176 18.5289C9.12142 18.3887 8.72224 18.5566 8.57786 18.9071L6.80492 23.3042C6.65841 23.6568 6.82615 24.0562 7.18074 24.1985Z"
        fill="#3F4756" />
      <path
        d="M0.138925 17.2848C0.249335 17.546 0.504129 17.7011 0.771662 17.7011C0.860839 17.7011 0.95214 17.6841 1.03919 17.648L5.40253 15.7936C5.75075 15.647 5.91212 15.2413 5.76561 14.8929C5.6191 14.5445 5.21356 14.3831 4.86534 14.5297L0.502005 16.3841C0.153788 16.5349 -0.00970448 16.9364 0.138925 17.2848Z"
        fill="#3F4756" />
      <path
        d="M0.428214 8.31014L4.8234 10.0839C4.90833 10.1178 4.99538 10.1348 5.08244 10.1348C5.35209 10.1348 5.61113 9.9734 5.71942 9.70575C5.85956 9.35525 5.69182 8.9559 5.34148 8.81145L0.946294 7.03773C0.595953 6.89753 0.196777 7.06535 0.0523939 7.41584C-0.0941122 7.77059 0.0778733 8.16994 0.428214 8.31014Z"
        fill="#3F4756" />
      <path
        d="M8.44673 5.65186C8.55714 5.91314 8.81193 6.06821 9.07946 6.06821C9.16864 6.06821 9.25994 6.05122 9.347 6.0151C9.69522 5.86853 9.85658 5.46281 9.71008 5.11444L7.85646 0.751293C7.70995 0.402922 7.3044 0.241481 6.95619 0.388052C6.60797 0.534623 6.4466 0.940349 6.59311 1.28872L8.44673 5.65186Z"
        fill="#3F4756" />
      <path
        d="M12.0004 5.25332C12.3784 5.25332 12.6884 4.94744 12.6884 4.56508V1.89918C12.6884 1.52107 12.3826 1.21094 12.0004 1.21094C11.6183 1.21094 11.3125 1.51682 11.3125 1.89918V4.5672C11.3146 4.94531 11.6225 5.25332 12.0004 5.25332Z"
        fill="#3F4756" />
      <path
        d="M16.9449 7.30097C17.0808 7.43692 17.2549 7.50278 17.4311 7.50278C17.6073 7.50278 17.7815 7.43692 17.9173 7.30097L19.8028 5.41255C20.0704 5.14489 20.0704 4.70943 19.8028 4.4439C19.5353 4.17625 19.1 4.17625 18.8346 4.4439L16.947 6.33233C16.6795 6.59998 16.6795 7.03332 16.9449 7.30097Z"
        fill="#3F4756" />
      <path
        d="M19.6801 12.937H22.347C22.7249 12.937 23.0349 12.6312 23.0349 12.2488C23.0349 11.8686 22.7292 11.5605 22.347 11.5605H19.6801C19.3022 11.5605 18.9922 11.8664 18.9922 12.2488C18.9922 12.629 19.3001 12.937 19.6801 12.937Z"
        fill="#3F4756" />
      <path
        d="M19.3163 20.2547C19.4926 20.2547 19.6667 20.1889 19.8026 20.0529C20.0701 19.7853 20.0701 19.3498 19.8026 19.0843L17.915 17.1959C17.6474 16.9282 17.2122 16.9282 16.9467 17.1959C16.6792 17.4635 16.6792 17.899 16.9467 18.1645L18.8343 20.0529C18.9639 20.1889 19.1422 20.2547 19.3163 20.2547Z"
        fill="#3F4756" />
      <path
        d="M12.6884 22.5975V19.9295C12.6884 19.5513 12.3826 19.2412 12.0004 19.2412C11.6204 19.2412 11.3125 19.5471 11.3125 19.9295V22.5975C11.3125 22.9756 11.6183 23.2857 12.0004 23.2857C12.3826 23.2857 12.6884 22.9777 12.6884 22.5975Z"
        fill="#3F4756" />
      <path
        d="M7.05646 17.1959C6.78892 16.9282 6.35365 16.9282 6.08824 17.1959L4.20065 19.0843C3.93312 19.3519 3.93312 19.7874 4.20065 20.0529C4.33654 20.1889 4.51065 20.2547 4.68688 20.2547C4.86311 20.2547 5.03722 20.1889 5.17311 20.0529L7.0607 18.1645C7.32187 17.8968 7.32187 17.4635 7.05646 17.1959Z"
        fill="#3F4756" />
      <path
        d="M4.32158 12.937C4.69952 12.937 5.00952 12.6312 5.00952 12.2488C5.00952 11.8686 4.70377 11.5605 4.32158 11.5605H1.65474C1.2768 11.5605 0.966797 11.8664 0.966797 12.2488C0.966797 12.629 1.27255 12.937 1.65474 12.937H4.32158Z"
        fill="#3F4756" />
      <path
        d="M6.08612 7.30097C6.22201 7.43692 6.39612 7.50278 6.57235 7.50278C6.74858 7.50278 6.92269 7.43692 7.05858 7.30097C7.32611 7.03332 7.32611 6.59786 7.05858 6.33233L5.16886 4.4439C4.90133 4.17625 4.46606 4.17625 4.20065 4.4439C3.93312 4.71155 3.93312 5.14702 4.20065 5.41255L6.08612 7.30097Z"
        fill="#3F4756" />
    </svg>
  </span>
  <span style="width: 25px; text-align: center;" v-else-if="type === 'working_refrigerated'">
    <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.03499 10.7992C0.188201 11.1504 -0.336806 9.81794 0.512398 9.46676L2.76243 8.5303L0.449494 7.13557C-0.344064 6.65737 0.359977 5.41706 1.15112 5.89276L3.45195 7.28002L3.11566 4.8218C2.98743 3.88783 4.37132 3.68858 4.49713 4.62256L4.98584 8.20652L9.52461 10.9462C9.53671 9.13551 9.60929 7.34727 9.62139 5.53162L6.86087 3.3125C6.13989 2.7322 6.99877 1.59898 7.71975 2.17928L9.63106 3.71598L9.648 0.956402C9.65284 0.00997667 11.0488 0.0174485 11.044 0.961384L11.027 3.70602L12.9263 2.17679C13.6472 1.59649 14.5061 2.72971 13.7852 3.31001L11.015 5.5391L10.9787 10.9561C12.5029 10.0645 14.0585 9.22517 15.5828 8.33603L16.069 4.76452C16.1973 3.83055 17.5787 4.03229 17.4505 4.96377L17.1142 7.43693L19.4441 6.07208C20.2425 5.60136 20.9344 6.85164 20.1385 7.31987L17.8207 8.67475L20.0562 9.60375C20.9054 9.95741 20.3804 11.2899 19.5336 10.9362L16.2723 9.58133L11.7021 12.2612C13.2214 13.1777 14.6876 14.1366 16.2094 15.0557L19.4562 13.7058C20.3054 13.3521 20.828 14.6846 19.9788 15.0382L17.7287 15.9747L20.0417 17.3694C20.8352 17.8476 20.1312 19.0879 19.3401 18.6122L17.0392 17.225L17.3755 19.6832C17.5037 20.6171 16.1199 20.8164 15.9916 19.8824L15.5053 16.2935L10.9666 13.5538C10.9545 15.3645 10.8819 17.1527 10.8698 18.9684L13.6303 21.1875C14.3513 21.7678 13.4924 22.901 12.7714 22.3207L10.8601 20.784L10.8408 23.5436C10.8359 24.49 9.43993 24.4826 9.44477 23.5386L9.46171 20.794L7.56249 22.3232C6.84151 22.9035 5.98263 21.7703 6.70361 21.19L9.4738 18.9609L9.51009 13.5439C7.98104 14.4405 6.43989 15.2698 4.906 16.1665L4.41971 19.738C4.29148 20.6719 2.91001 20.4702 3.03824 19.5387L3.37695 17.0656L1.04708 18.4304C0.248685 18.9011 -0.443259 17.6509 0.352719 17.1826L2.67049 15.8277L0.432559 14.8938C-0.416646 14.5401 0.105942 13.2076 0.955146 13.5613L4.21648 14.9187L8.79154 12.2413C7.27216 11.3247 5.8036 10.3659 4.28422 9.44684L1.03499 10.7992Z"
        fill="#3F4756" />
    </svg>
  </span>
  <span style="width: 25px; text-align: center;" v-else-if="type === 'shipping_export'">
    <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5534 21.2826C21.2525 21.1783 20.9272 21.3337 20.8235 21.6364C20.5226 22.5077 19.7032 23.0967 18.7842 23.0967C17.8652 23.0967 17.0499 22.5097 16.749 21.6364C16.6453 21.3337 16.32 21.1783 16.0191 21.2826C15.8686 21.3337 15.7527 21.4462 15.6877 21.5812C15.6796 21.5935 15.6755 21.6119 15.6613 21.6364C15.3603 22.5077 14.541 23.0967 13.622 23.0967C12.6908 23.0967 11.8023 22.4831 11.5095 21.6364C11.5054 21.6241 11.5014 21.62 11.4973 21.6057C11.4363 21.4585 11.3204 21.3378 11.1578 21.2785C10.8568 21.1742 10.5315 21.3296 10.4278 21.6323C10.1269 22.4913 9.2608 23.0926 8.31131 23.0926C7.39638 23.0926 6.57701 22.5056 6.27609 21.6323C6.1724 21.3296 5.84709 21.1742 5.54618 21.2785C5.39166 21.3296 5.27577 21.4462 5.21478 21.5853C5.20664 21.5975 5.20258 21.616 5.19241 21.6364C4.8915 22.5077 4.07213 23.0967 3.15313 23.0967C2.23413 23.0967 1.41883 22.5097 1.11385 21.6364C1.01016 21.3337 0.684851 21.1783 0.38394 21.2826C0.0830296 21.3869 -0.0714921 21.7141 0.0322001 22.0168C0.495765 23.3503 1.7482 24.2502 3.15313 24.2502C4.17379 24.2502 5.11515 23.7757 5.73324 22.9985C6.34726 23.7717 7.28658 24.2502 8.31334 24.2502C9.35636 24.2502 10.3404 23.7573 10.9707 22.9801C11.601 23.7573 12.5851 24.2502 13.6281 24.2502C14.6487 24.2502 15.5901 23.7757 16.2082 22.9985C16.8222 23.7717 17.7615 24.2502 18.7883 24.2502C20.1912 24.2502 21.4456 23.3524 21.9092 22.0168C22.0088 21.7141 21.8543 21.3869 21.5534 21.2826Z"
        fill="#3F4756" />
      <path
        d="M5.22963 20.0697C5.33332 20.2681 5.5346 20.3847 5.73995 20.3847C5.82535 20.3847 5.91684 20.3622 5.99817 20.3233C6.28078 20.1802 6.3926 19.8304 6.25231 19.5502L3.56445 14.2182L10.402 11.5451V13.259L5.31502 15.5783C5.02834 15.7072 4.89822 16.0528 5.03241 16.3433C5.12593 16.5539 5.33738 16.6807 5.55697 16.6807C5.63423 16.6807 5.71556 16.6644 5.79282 16.6296L10.4081 14.5271V19.8079C10.4081 20.127 10.6663 20.3868 10.9835 20.3868C11.3007 20.3868 11.5548 20.127 11.5548 19.8079V14.4862L15.9628 16.5151C16.2495 16.648 16.589 16.5192 16.7232 16.2308C16.8574 15.9424 16.7273 15.6008 16.4406 15.4659L11.5528 13.2222V11.543L18.3904 14.2162L15.7046 19.5482C15.5622 19.8325 15.6741 20.1781 15.9587 20.3213C16.2413 20.4645 16.5849 20.352 16.7273 20.0656L19.6937 14.1671C19.7669 14.0199 19.775 13.8521 19.7201 13.7008C19.6652 13.5494 19.5432 13.4288 19.3948 13.3674L16.9773 12.4225V8.93329C16.9773 8.61423 16.7191 8.35857 16.4019 8.35857H12.5491V6.61396C12.5491 6.2949 12.2909 6.03516 11.9737 6.03516H9.96897C9.65179 6.03516 9.39764 6.2949 9.39764 6.61396V8.35857H5.5529C5.23573 8.35857 4.98158 8.61832 4.98158 8.93329V12.4225L2.56413 13.3674C2.41367 13.4288 2.29371 13.5454 2.23882 13.7008C2.18392 13.8521 2.19206 14.0199 2.26525 14.1671L5.22963 20.0697ZM10.5444 7.18868H11.4024V8.2604H10.5444V7.18868ZM6.12219 9.5121H15.8286V11.9787L11.1848 10.1604C11.0527 10.1093 10.9022 10.1093 10.768 10.1604L6.12422 11.9787L6.12219 9.5121Z"
        fill="#3F4756" />
      <path
        d="M4.99809 4.68504C5.91302 4.87934 6.76695 4.61959 7.59446 4.37416C8.2898 4.16758 8.94652 3.96306 9.56461 4.06737C9.74556 4.09805 10.0017 4.16758 10.1827 4.33939C10.3108 4.46006 10.384 4.6339 10.3677 4.77094C10.3291 5.08591 10.5487 5.37633 10.8618 5.41928C10.8882 5.42337 10.9126 5.42337 10.939 5.42337C11.4067 5.42337 11.5063 4.92229 11.5063 4.92229C11.7259 3.83012 11.5449 2.96088 10.9614 2.34935C9.90211 1.23059 7.88723 1.4208 7.07193 1.55579C6.24442 1.68873 5.32949 1.25718 4.90456 0.533156C4.74597 0.261137 4.39423 0.167055 4.11975 0.330676C3.84934 0.494297 3.75988 0.844036 3.91847 1.12015C4.58738 2.25936 5.96181 2.9077 7.26305 2.69091C8.17391 2.53956 9.29826 2.54774 9.92855 2.96702C9.87365 2.95475 9.81672 2.93634 9.75776 2.92816C8.88146 2.78499 8.06615 3.03246 7.27728 3.26972C6.5555 3.48651 5.87439 3.69308 5.24207 3.5581C4.809 3.46811 4.40846 3.20427 4.15228 2.84226C3.96726 2.58251 3.61146 2.5232 3.35324 2.70931C3.09503 2.89543 3.03607 3.25335 3.22109 3.5131C3.63789 4.11032 4.29054 4.53778 4.99809 4.68504Z"
        fill="#3F4756" />
    </svg>
  </span>
  <span style="width: 25px; text-align: center;" v-else-if="type === 'domestic_storage'">
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.1678 22.1197C22.4813 22.1197 22.736 21.8623 22.736 21.5456V15.0145C22.736 14.6978 22.4813 14.4404 22.1678 14.4404C21.8544 14.4404 21.5996 14.6978 21.5996 15.0145V21.5497C21.6016 21.8664 21.8524 22.1197 22.1678 22.1197Z"
        fill="#3F4756" />
      <path
        d="M20.1112 22.1011C20.4246 22.1011 20.6794 21.8437 20.6794 21.527V14.7528C20.6794 14.4361 20.4246 14.1787 20.1112 14.1787C19.7978 14.1787 19.543 14.4361 19.543 14.7528V21.5332C19.543 21.8478 19.7978 22.1011 20.1112 22.1011Z"
        fill="#3F4756" />
      <path
        d="M10.6835 22.0198C10.9969 22.0198 11.2517 21.7624 11.2517 21.4458V13.409C11.2517 13.0924 10.9969 12.835 10.6835 12.835C10.37 12.835 10.1152 13.0924 10.1152 13.409V21.4437C10.1152 21.7624 10.366 22.0198 10.6835 22.0198Z"
        fill="#3F4756" />
      <path
        d="M24.5324 13.0365L13.8859 6.97523C14.4177 6.4788 14.7574 5.76992 14.7574 4.97932C14.7574 3.68003 13.8455 2.59116 12.6403 2.32559V0.824055C12.6403 0.507406 12.3855 0.25 12.0721 0.25C11.7586 0.25 11.5038 0.507406 11.5038 0.824055V3.41445H12.0721C12.9254 3.41445 13.623 4.11925 13.623 4.98136C13.623 5.57789 13.2914 6.09678 12.8061 6.36236L12.4927 6.18667H12.4886C12.3228 6.09678 12.1186 6.08657 11.9426 6.18258L11.4816 6.4359C11.1682 6.30516 10.9012 6.0784 10.7273 5.77605C10.5716 5.50026 10.2218 5.41037 9.95085 5.56767C9.67786 5.72497 9.58889 6.0784 9.74459 6.35215C9.9003 6.62589 10.1066 6.86287 10.3431 7.06103L0.295229 12.5769C0.291185 12.5809 0.287141 12.585 0.281075 12.585C0.111217 12.6851 0 12.871 0 13.0774V23.6759C0 23.9926 0.254787 24.25 0.568216 24.25H8.27047C8.29676 24.25 8.32911 24.25 8.3554 24.2459H24.2614C24.5748 24.2459 24.8296 23.9885 24.8296 23.6719V13.5391C24.8235 13.3266 24.7063 13.1366 24.5324 13.0365ZM7.99748 10.4196L5.16854 11.204L9.5161 8.81588L7.99748 10.4196ZM7.69821 11.6882V23.1039H4.31116V12.632L7.69821 11.6882ZM12.0579 7.69229C12.0619 7.69229 12.066 7.69229 12.0721 7.69229C12.1873 7.69229 12.3046 7.68412 12.4158 7.66573L16.6158 11.7229L9.40892 10.5626L12.0579 7.69229ZM18.5105 12.0314C18.4802 11.964 18.4397 11.9007 18.3811 11.8455L15.8736 9.42671L21.2181 12.4686L18.5105 12.0314ZM1.13441 13.5166L3.18079 12.9487V23.1039H1.13441V13.5166ZM23.6891 23.0978H8.83262V11.633L23.6891 14.0253V23.0978Z"
        fill="#3F4756" />
      <path
        d="M13.3065 22.0413C13.6199 22.0413 13.8747 21.7839 13.8747 21.4673V13.786C13.8747 13.4693 13.6199 13.2119 13.3065 13.2119C12.9931 13.2119 12.7383 13.4693 12.7383 13.786V21.4693C12.7383 21.7839 12.9931 22.0413 13.3065 22.0413Z"
        fill="#3F4756" />
      <path
        d="M15.5526 22.0639C15.866 22.0639 16.1208 21.8065 16.1208 21.4898V14.1619C16.1208 13.8453 15.866 13.5879 15.5526 13.5879C15.2392 13.5879 14.9844 13.8453 14.9844 14.1619V21.4919C14.9864 21.8085 15.2412 22.0639 15.5526 22.0639Z"
        fill="#3F4756" />
      <path
        d="M17.8299 22.0868C18.1434 22.0868 18.3981 21.8294 18.3981 21.5128V14.4852C18.3981 14.1685 18.1434 13.9111 17.8299 13.9111C17.5165 13.9111 17.2617 14.1685 17.2617 14.4852V21.5128C17.2617 21.8315 17.5165 22.0868 17.8299 22.0868Z"
        fill="#3F4756" />
      <path
        d="M5.34556 19.0367C5.65899 19.0367 5.91377 18.7793 5.91377 18.4627V17.1532C5.91377 16.8365 5.65899 16.5791 5.34556 16.5791C5.03213 16.5791 4.77734 16.8365 4.77734 17.1532V18.4627C4.77734 18.7793 5.03213 19.0367 5.34556 19.0367Z"
        fill="#3F4756" />
      <path
        d="M2.30454 16.999C1.99112 16.999 1.73633 17.2564 1.73633 17.5731V18.4617C1.73633 18.7784 1.99112 19.0358 2.30454 19.0358C2.61797 19.0358 2.87276 18.7784 2.87276 18.4617V17.5731C2.87276 17.2523 2.61797 16.999 2.30454 16.999Z"
        fill="#3F4756" />
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    type: String
  }
}
</script>
