<template>
  <div class="is-flex add-quote-buttons-container">
    <button
      class="button is-primary bg-link-color add-button has-text-weight-semibold"
      :style="'background-color: ' + brand_color + '!important'"
      :disabled="loading"
      @click="addToQuote"
    >
      <span v-if="loading">Loading...</span>
      <span v-else>Add To Quote</span>
    </button>
    <div v-if="showZipCode">
      <button
        v-if="lead && lead.id && zipcode && organization"
        class="ml-5 button is-secondary zipcode-button has-text-weight-semibold neutral-gray"
        :disabled="loading"
        @click="changeZipCode('livingPrice')"
      >
        <span>Change Zip Code</span>
      </button>
    </div>
    <!-- <button v-else class="ml-5 button secondary zipcode-button" @click="changeZipCode('livingPrice')">
      <span>
        Live Pricing & Availability
      </span>
    </button> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    priceBookEntry: {
      type: Object,
      required: false
    },
    quantity: {
      type: Number,
      required: true
    },
    changeZipCode: {
      type: Function,
      required: false,
      default: () => true
    },
    showZipCode: {
      type: Boolean,
      required: false,
      default: () => true
    },
    brand_color: {
      type: String,
      required: false,
      default: () => 'white'
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('quote', ['quote', 'errors', 'lead', 'organization']),
    ...mapState('shared', ['zipcode'])
  },
  methods: {
    async addToQuote() {
      if (!this.zipcode || !this.lead || !this.organization) {
        const event = new CustomEvent('vue-app:init', {
          detail: { name: 'lead-flow' }
        })
        document.dispatchEvent(event)
        return
      }
      this.loading = true
      // create a quote via lead if none exists
      const quote =
        this.quote || (await this.$store.dispatch('quote/quote.save'))
      if (!quote) {
        return
      }

      await this.$store.dispatch('quote/quote.save', {
        eventName: 'item.add',
        input: {
          item: {
            title: this.priceBookEntry.product.name,
            priceBookEntryId: this.priceBookEntry.id,
            quantity: this.quantity
          }
        }
      })
      this.loading = false
    }
  }
}
</script>

<style scoped>
.quote {
  padding: 1em;
  border: 1px solid blue;
}

.add-button {
  padding: 0.25rem 0.875rem;
  border-radius: 2px;
}

.zipcode-button {
  padding: 0.25rem 0.875rem;
  border-radius: 2px;
}
</style>
