
<div class="is-flex add-quote-buttons-container">
  <button
    class="button is-primary bg-link-color add-button has-text-weight-semibold"
    :style="'background-color: ' + brand_color + '!important'"
    :disabled="loading"
    @click="addToQuote"
  >
    <span v-if="loading">Loading...</span>
    <span v-else>Add To Quote</span>
  </button>
  <div v-if="showZipCode">
    <button
      v-if="lead && lead.id && zipcode && organization"
      class="ml-5 button is-secondary zipcode-button has-text-weight-semibold neutral-gray"
      :disabled="loading"
      @click="changeZipCode('livingPrice')"
    >
      <span>Change Zip Code</span>
    </button>
  </div>
  <!-- <button v-else class="ml-5 button secondary zipcode-button" @click="changeZipCode('livingPrice')">
    <span>
      Live Pricing & Availability
    </span>
  </button> -->
</div>
