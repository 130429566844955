<template>
  <validation-observer
    tag="form"
    ref="validation"
    @submit.prevent="handleSubmit"
  >
    <fieldset class="fieldset">
      <div class="columns">
        <div class="column field">
          <label class="label span"><b class="required-star">*</b> Email</label>
          <validated-input
            v-model="lead.email"
            name="Email"
            rules="required|email"
            :showErrorIcon="true"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column field">
          <label class="label span"><b class="required-star">*</b> Existing Quote Number</label>
          <validated-input         
            v-model="lead.quoteNumber"           
            name="Quote Number"
            rules="required"
            :showErrorIcon="true"
          />
        </div>
      </div>
      <p v-if="otherErrors" class="help is-danger">{{otherErrors}}</p>
    </fieldset>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import ValidatedInput from 'shared/components/validated-input.vue'
export default {
  components: {
    ValidationObserver,
    ValidatedInput
  },
  data () {
    return {
      lead: {},
      otherErrors: ''
    }
  },
  methods: {
    async handleSubmit() {
      const quote = await this.$store.dispatch('quote/quote.load_by_reference', {reference: this.lead.quoteNumber, customerEmail: this.lead.email})
      if (quote) {
        if (quote.opportunity.closed) {
          this.error = true
          this.otherErrors = 'This quote cannot be changed because it\'s already closed'
          return { errors: [ 'This quote cannot be changed because it\'s already closed' ] }
        } else {
          const lead = await this.$store.dispatch('quote/lead.load', quote.opportunity.leadId)
          const result = await this.$store.dispatch('quote/lead.save', {...lead, ...this.updates, id: lead.id})
          await this.$store.dispatch('quote/pricing.load')
          await this.$store.dispatch('quote/flow/options.compute')
          return { lead: result.lead, hasQuote: true }
        }
      } else {
        this.error = true
        this.otherErrors = 'Previous quote not found'
        return { errors: [ 'Previous quote not found' ] }
      }
    }
  }
}
</script>
