export const jsonFromTemplate = (selector, type) => {
  try {
    const $el = document.querySelector(selector)
    let text = JSON.parse($el.textContent)
    if(type) {
      text[0].data.title = text[0].data.title.replace('{type}', type)
    }
    return text
  } catch (e) {
    console.warn('Could not load json via', selector)
  }
}

export default jsonFromTemplate
