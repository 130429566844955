<template>
  <modal @close="close" class="retrieve-quote-modal" :showCloseButton="false">
    <div slot="header" class="container">
      <header class="header-retrieve-quote is-relative">
        <h4 class="title is-5 has-text-white has-text-centered has-text-weight-bold m-0">
          <slot name="title ">Retrieve Quote</slot>
        </h4>
        <a class="close-icon has-text-white" @click.prevent="handleClose">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.1875 4.8125L4.8125 17.1875" stroke="currentColor" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M17.1875 17.1875L4.8125 4.8125" stroke="currentColor" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </a>
      </header>
    </div>
    <div slot="body" class="retrieve-quote-form">
      <p class="neutral-gray has-text-weight-semibold text-sm mb-2">To retrieve a past quote please enter the email and
        quote number associated with your quote.</p>
      <retrieve-quote-form ref="retrieveQuoteForm" />
    </div>
    <div slot="footer" class="is-flex-grow-1">
      <div class="buttons-container">
        <div class="level is-mobile is-align-items-flex-end">
          <div class="level-item is-halfwidth">
            <button @click="retrieveQuote" class="button action-button is-fullwidth">
              Retrieve Quote
            </button>
          </div>
          <div class="level-item is-halfwidth is-flex-direction-column	">
            <p class="neutral-gray has-text-weight-semibold text-sm mb-2 has-text-centered">
              Don't have a quote?
            </p>
            <button data-vue-init="lead-flow" data-props='{ "gtag": "build_quote"}' @click="handleClose" class="button close-button is-fullwidth">
              Build Quote
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import hasModal from 'shared/mixins/has-modal'
import RetrieveQuoteForm from './form.vue'
export default {
  mixins: [hasModal],
  components: {
    RetrieveQuoteForm
  },
  props: {
    detail: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      lead: null
    }
  },
  methods: {
    async retrieveQuote() {
      const result = await this.$refs.retrieveQuoteForm.handleSubmit()
      if (result.hasQuote) {
        window.location.href = '/cart'
        this.close()
        window.scrollTo(0, 0)
      }
    },
    handleClose() {
      this.close()
    }
  }
}
</script>
<style scoped lang="scss">
.header-retrieve-quote {
  margin: -20px -20px 20px -20px;
  padding: 2rem;
  background-image: url('/quote-modal-background.png');
}

.close-icon {
  position: absolute;
  right: 1.5rem;
  top: calc(50% - 12px);
}

.retrieve-quote-form {
  padding: 0 20px;
}
</style>
