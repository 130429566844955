
<div class="summary">
  <div class="header has-text-centered p-5 mb-2 has-background-white neutral-black">
    <h2 class="title is-4 has-text-weight-bold mb-4 neutral-black">Thank You!</h2>
    <div class="my-body-section">
      <div class="confirmation-main-copy">
        <p class="is-size-6">
          We've sent you an email with your reservation details to 
          <b class="highlight">{{ quote.customer.email }}</b>.
        </p>
        <br />
        <p>
          We will be following up to <b>finalize your delivery details and schedule a date &amp; time</b>.
        </p>
      </div>
      <div class="confirmation-sub-copy mt-5">
        <p>If you have any questions or would like to modify your order please <a
          href="mailto:sales@containeralliance.com" class="link-color">contact us</a>.</p>          
      </div>
    </div>
  </div>
</div>
