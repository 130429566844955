<template>
  <div class="testimonials">
    <div class="testimonials-header">
      <h1 class="title">Testimonials</h1>
      <p>Don't take our word for it.<br />Trust our customers.</p>
    </div>

    <carousel class="slider" :perPage="1" paginationPosition="bottom-overlay" paginationActiveColor="#174984"
      :navigationEnabled="false">
      <slide v-for="(item, slideIndex) in testimonialsItems" :key="slideIndex" class="hero slide">

        <div class="hero-body">
          <section class="testimonials-cards-container columns is-narrow">
            <div
              v-for="(testimonial, index) in item"
              :key="index"
              class="testimonials-card column"
              :class="selectClass(item.length, index)"
            >
              <div class="customer-info">
                <img
                  class="customer-photo"
                  :src="testimonial.data.customerPhoto.url"
                  :alt="testimonial.data.customerName"
                />
                <div class="customer-name">

                  <b>{{ testimonial.data.customerName }}</b>
                  <p>{{ testimonial.data.location }}</p>
                </div>
              </div>
              <p class="customer-testimonial custom-scroll">
                {{ testimonial.data.testimonial }}
              </p>
            </div>
          </section>
        </div>
      </slide>

    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
export default {
  components: {
    Carousel,
    Slide
  },
  props: {
    testimonials: Array
  },
  methods: {
    selectClass(length, index) {
      if (index == 1 && length == 3) {
        return 'middle-item'
      } else if (length == 2 && index == 0) {
        return 'left-item'
      } else if (length == 2 && index == 1) {
        return 'right-item'
      }
      return ''
    }
  },
  computed: {
    testimonialsItems() {

      const newArray = []
      let posInitial = 0
      let posFinal = 3
      let total = 0
      do {
        const generatedArray = this.testimonials.slice(posInitial, posFinal)
        newArray.push(generatedArray)
        posInitial += 3
        posFinal += 3
        total += generatedArray.length
      } while (total < this.testimonials.length)
      return newArray
    }
  }
}
</script>
<style scoped lang="scss">
.VueCarousel {
  margin: 0;
}

.slide {
  height: auto;
}
.customer-photo {
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
}
.testimonials {
  background: #061627;
  text-align: center;
  color: #fff;
  padding: 3rem 3rem 1rem 3rem;
  background-image: url('/quotes.png');
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 5%;

  @media(max-width: 850px) {
    padding: 3rem 0rem 1rem 0rem;
  }

  @media(max-width: 550px) {
    padding: 1.5rem 0rem 1rem 0rem;
    background-position-y: 2%
  }
}

.testimonials-header {
  margin-bottom: 2.75rem;

  @media(max-width: 550px) {
    margin-bottom: 0rem;
  }
}

.title {
  color: #fff;
  font-size: 1.5rem;
}
.testimonials-cards-container {
  display: flex;
  justify-content: space-between;

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: center;
  }
}
.testimonials-card {
  background: #fff;
  box-shadow: 0px 0px 0px 1px #0A0A0A05;
  border-radius: 4px;
  padding: 1.375rem;
  max-width: 33%;
  margin: 0 auto;

  @media (max-width: 850px) {
    flex-direction: column;
    margin: 0;
    max-width: 100%;
  }
}

.customer-info {
  display: flex;
  margin-bottom: 2.75rem;
}

.customer-testimonial {
  color: #4A4A4A;
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
  max-height: 200px;
  min-height: 200px;
  overflow: auto;
}

.customer-name {
  color: #363636;
  text-align: left;
  margin-left: 0.75rem;

  b {
    font-size: 1.25rem;
    font-weight: 600;
  }
  p {
    font-size: 1rem;
    font-weight: 400;
    color: #767676;
  }
}

.single-item {
  margin: 0 auto;
}

.left-item {
  margin-left: auto;
  margin-right: 0.75rem;
}
.right-item {
  margin-right: auto;
  margin-left: 0.75rem;
}

.middle-item {
  margin: 0 1.5rem;

  @media(max-width: 850px) {
    margin: 1.5rem 0;
  }
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #C1C1C1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #7D7D7D;
  }
}
</style>