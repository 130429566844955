<template>
  <div id="sidebar">
    <!-- Sticky Sidebar wants this wrapper div -->
    <div class="sidebar__inner">
      <!-- Sticky Sidebar wants this wrapper div -->
      <div
        v-if="
          organization && !organization.hide_partner_box && !options.hidePartner
        "
        class="box"
      >
        <h5 v-if="organization.box_title" class="heading">
          {{ organization.box_title }}
        </h5>
        <div class="block">
          <h5 v-if="organization.logo_url" class="title is-5">
            <img :src="organization.logo_url" :alt="organization.name" />
          </h5>
          <h5 v-else class="title is-5">{{ organization.name }}</h5>
          <ul>
            <li v-if="organization.service_area" class="mb-3">
              <h6 class="heading">Serving:</h6>
              <p>{{ organization.service_area }}</p>
            </li>
            <li v-if="organization.hours">
              <h6 class="heading">Hours:</h6>
              <p>{{ organization.hours }}</p>
            </li>
          </ul>
          <p class="mt-3">
            <a :href="organization.partner_url"> View Partner </a>
          </p>
        </div>
      </div>
      <div class="boxes">
        <div class="box quote-box mt-5"
        v-if="options.showQuoteBox"
        >
          <div class="block">
            <h3 class="title has-text-centered is-4 is-size-5-mobile">
              <span class="icon is-medium">
                <i data-feather="phone-call"></i>
              </span>
              Request a Quote
            </h3>
            <p class="subtitle is-6 has-text-centered">
              Quickly tell us your needs and we will be in touch shortly
            </p>
          </div>
          <br />
          <div class="level is-fullwidth is-tablet">
            <div class="level-item">
              <button
                data-vue-init="lead-flow"
                data-props='{ "gtag": "build_quote"}'
                class="button is-fullwidth is-medium is-primary"
              >
                Build Quote
              </button>
            </div>
            <div class="level-item">
              <button
                data-vue-init="lead-modal"
                class="button is-fullwidth is-medium"
              >
                Contact Us
              </button>
            </div>
          </div>
          <div class="has-text-centered">
            <a :href="`tel:${companyNumber}`" class="is-size-5 is-underlined has-text-weight-bold">{{formatPhoneNumber(companyNumber)}}</a>
          </div>
        </div>
        <div
          v-if="options.showFindInventory && !options.isProductPage"
          class="box has-background-primary"
        >
          <h3 class="title has-text-centered is-4">
            <span class="icon">
              <i data-feather="search" width="1.5rem" height="1.5rem"></i>
            </span>
            Find Inventory
          </h3>
          <p class="subtitle is-6 has-text-centered">Where are you located?</p>
          <location-search />
        </div>
        <div
          v-if="!options.isSimple"
          class="box"
          :class="!options.isSimple ? 'has-background-light' : ''"
        >
          <h3 class="title has-text-centered is-4">
            <span class="icon">
              <i data-feather="phone-call" width="1.5rem" height="1.5rem"></i>
            </span>
            Request a Quote
          </h3>
          <p class="subtitle is-6 has-text-centered px-5 pb-2">
            Quickly tell us your needs and we will be in touch shortly
          </p>
          <div class="level is-fullwidth is-tablet">
            <div class="level-item">
              <a
                href="/quote"
                class="button is-fullwidth is-medium"
                :class="{'is-flex-grow-1': !options.brand_color}"
                :style="options.brand_color ? 'background-color: ' + options.brand_color : 'background-color: #174984'"
                data-vue-init="lead-flow"
                data-props='{ "gtag": "build_quote"}'
              >
                <span style="color: white">Build a Quote</span>
              </a>
            </div>
            <div class="level-item">
              <a
                href="/contact"
                class="button is-fullwidth is-medium"
                style="width: 100%"
                data-vue-init="lead-modal"
              >
                <span>Contact us</span>
              </a>
            </div>
          </div>
          <div class="has-text-centered">
            <a
              :href="`tel:${companyNumber}`"
              class="is-fullwidth is-medium"
            >
              <span
                class="is-size-5 has-text-weight-bold is-underlined"
                style="text-decoration-thickness: 0.5px !important;"
              >
                {{companyNumber}}
              </span>
            </a>
          </div>
        </div>
      </div>

      <div
        v-if="options.showBuild"
        class="location-search-container flex"
        style="margin-bottom: 5.2rem"
      >
        <button
          v-if="zipcode"
          data-vue-init="lead-flow"
          data-props='{ "gtag": "build_quote"}'
          class="button is-primary mr-5 is-size-6"
          style="padding: 0.75rem; border-radius: 2px"
        >
          Build Quote
        </button>

        <button
          data-vue-init="lead-flow"
          class="button is-primary mr-5 is-size-6"
          style="padding: 0.75rem; border-radius: 2px"
        >
          Buy Now
        </button>
        <span
          class="is-align-self-center is-size-6 is-underlined link-color-secondary"
          >{{ organization.phone }}</span
        >
      </div>

      <div
        v-if="!options.showFindInventory && !options.showBuild"
        class="location-search-container"
        style="margin-bottom: 3.5rem"
      >
        <location-search :showSearchByGeolocate="false" :brand_color="options.brand_color" />
      </div>
      <div v-if="options.showProductFilter">
        <products-filter :isRent="isRent" />
      </div>
      <div v-if="solutions">
        <div v-for="solution in solutions" :key="solution.id">
          <a
            v-if="solutions_path[solution.id] !== getCurrentLocation()"
            :href="solutions_path[solution.id]"
            :style="'height: 64px; background-color: ' + options.brand_color"
            class="is-flex is-flex-direction-row is-align-items-center my-3 has-border-radius"
          >
            <icon :type="iconType(solution.title)" class="bt-icon"/>
            <b class="category-title">
              {{ solution.title }}
            </b>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LocationSearch from '../location-search/index.vue'
import ProductsFilter from './products-filter.vue'
import { formatPhoneNumber } from '../../shared/utils'
import Icon from './helpers/solutionIcon.vue'
export default {
  data() {
    return {
      showDropdown: false
    }
  },
  components: {
    LocationSearch,
    ProductsFilter,
    Icon
  },
  props: {
    options: {
      type: Object,
      default: () => ({
        isSimple: false,
        hidePartner: false,
        showProductFilter: false,
        isProductPage: false,
        showBuild: false,
        showFindInventory: true,
        showCategoriesSection: false,
        brand_color: 'white',
        showQuoteBox: false
      })
    },
    products: {
      type: Array,
      required: false
    },
    solutions: {
      type: Array,
      required: false
    },
    solutions_path: {
      type: Object,
      required: false
    },
    isRent: {
      type: Boolean,
      default: window.location.pathname.search('rentals') != -1
    },
    companyNumber: {
      type: String,
      required: false
    }
  },
  methods: {
    iconType(title) {
      if (title.toLowerCase().includes('sale')) {
        return 'container-sales'
      } else if (title.toLowerCase().includes('rental')) {
        return 'container-rentals'
      } else if (title.toLowerCase().includes('refrigerated')) {
        return 'refrigerated-container'
      } else if (title.toLowerCase().includes('office')) {
        return 'portable-offices'
      } else if (title.toLowerCase().includes('modification')) {
        return 'container-modifications'
      } else if (title.toLowerCase().includes('accessor')) {
        return 'container-accessories'
      }
    },
    getCurrentLocation() {
      return window.location.pathname
    },
    formatPhoneNumber
  },
  computed: mapState('shared', [
    'organization',
    'organization.phone',
    'phone',
    'zipcode'
  ])
}
</script>

<style lang="scss">
.location-search-container {
  width: 100%;
  button {
    background-color: #194985 !important;
    color: #ffffff !important;

    &hover {
      color: #ffffff !important;
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 1200px) {
  .level {
    flex-direction: column;
  }
  .level div {
    width: 100%;
    margin: 10px 0 !important;
  }
}

@media (max-width: 875px) {
  .level {
    flex-direction: row;
  }
  .level div {
    width: auto;
    margin: 0 10px !important;
  }
}
@media (max-width: 770px) {
  .level div {
    margin: 10px 0 !important;
  }
}

.bt-icon {
  width: 36px;
}
.has-background-primary {
  .title,
  .subtitle {
    color: white;
  }
}
.menu-ul li {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.dropdwn-side-menu {
  position: absolute;
  z-index: 999;
  background: white;
  padding: 6px;
  width: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px #b2b2b2;
}
.has-border-radius {
  border-radius: 4px !important;
}
.category-title {
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}
.quote-box{
  background: #F7F9FF;

}
.category-card {
  background: #194985;
  color: white;
  border: 3px solid transparent;
  cursor: pointer;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  padding-left: 32px;
  gap: 6%;

  .category-icon {
    width: 96px !important;
    height: 100% !important;
  }
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
  }
}

@media screen and (max-width:1200px) and (min-width:875px) {
  .level{
    display:flex;
    flex-direction: column;
    justify-content:stretch;

    .level-item{
      width:100%
    }

    .level-item:last-child{
      margin-top: 6px;
      margin-left: -10px;
    }
  }
}
</style>
