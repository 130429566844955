<template>
  <section class="content" v-html="block.html"></section>
</template>

<script>
export default {
  props: {
    block: {
      type: Object
    }
  }
}
</script>
<style>
  .table-wrapper {
    position: relative;
    max-width: 100%;
    overflow: scroll;
    border-radius: 4px;
    box-shadow: 0 1px 5px rgba(0,0,0,.25);
  }
</style>
