<template>
  <div calss="component-container">
    <div class="card-container">
      <div class="row">
        <button
          @click="cardClick('sale')"
          class="card"
        >
          <div class="header-section">
            <icon type="container-sales" />
            <b>Container Sales</b>
          </div>
          <p>{{ shortDescription('container-sales') }}</p>
        </button>
        <button
          @click="cardClick('rental')"
          class="card"
        >
          <div class="header-section">
            <icon type="container-rentals" />
            <b>Container Rentals</b>
          </div>
          <p>{{ shortDescription('container-rentals') }}</p>
        </button>
        <button
          @click="cardClick('refrigerate')"
          class="card"
        >
          <div class="header-section">
            <icon type="refrigerated-container" />
            <b>Refrigerated Containers</b>
          </div>
          <p>{{ shortDescription('refrigerated-containers') }}</p>
        </button>
      </div>
      <div class="row">
        <button
          @click="cardClick('office')"
          class="card"
        >
          <div class="header-section">
            <icon type="portable-office" />
            <b>Portable Offices</b>
          </div>
          <p>{{ shortDescription('portable-offices') }}</p>
        </button>
        <button
          @click="cardClick('modification')"
          class="card"
        >
          <div class="header-section">
            <icon type="container-modification" />
            <b>Container Modifications</b>
          </div>
          <p>{{ shortDescription('container-modifications') }}</p>
        </button>
        <button
          class="card"
          @click="routingMethod('/solutions/accessories')"
        >
          <div class="header-section">
            <icon type="container-accessory" />
            <b>Container Alliance Accessories</b>
          </div>
          <p>{{ shortDescription('accessories') }}</p>
        </button>
      </div>
      <zipcodeModal
        v-if="modalOpen"
        @close="modalOpen = false"
        :solutionSlug="solutionSlug"
      />
    </div>
  </div>
</template>

<script>
import Icon from './helpers/icon.vue'
import ZipcodeModal from '../location-search/modal.vue'

export default {
  components: {
    Icon,
    ZipcodeModal
  },
  data() {
    return {
      modalOpen: false,
      solutionSlug: ''
    }
  },
  props: {
    solutions: Array
  },
  methods: {
    routingMethod(route) {
      return (window.location.href = route)
    },
    shortDescription(slug) {
      const solution = this.solutions.find((solution) => solution.slug === slug)
      if (solution) {
        return (
          solution.html_content
            .replace(/<[^>]*>?/gm, '')
            .split('&nbsp;')
            .join('')
            .substring(0, 200) + '...'
        )
      }
      return ''
    },
    cardClick(slug) {
      this.solutionSlug = slug
      this.modalOpen = true
    }
  }
}
</script>
<style scoped lang="scss">
.component-container {
  width: 100%;
}

.card-container {
  margin-top: 1rem;
  display: flex; 
  flex-direction: column;
  row-gap: 2rem;
}

.row {
  display: flex; 
  flex-direction: row; 
  justify-items: center; 
  justify-content: space-evenly;

  @media (max-width: 450px) {
    flex-direction: column;
    padding-left: 15px; 
    padding-right: 15px;
  }
}

.card{
  max-width: 30%;
  box-shadow: 0px 8px 16px -2px #0a0a0a1a;
  padding: 1.375rem;
  padding-bottom: 2.625rem;
  border-radius: 0.25rem;
  border: 3px solid transparent;
  cursor: pointer;
  background: #ffffff;
  @media (max-width: 450px) {
    flex-direction: column;
  }

  @media (max-width: 875px) {
    max-width: 100%;
  }

  p {
    color: #4a4a4a;
    font-size: 0.875rem;
    font-weight: 400;
  }

  &:hover {
    border: 3px solid #194985;
    box-shadow: 0px 16px 16px 0px #0a0a0a33;
  }
}

.header-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.875rem;

  b {
    margin-left: 0.75rem;
    color: #0062a8;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: left;
  }

  p {
    font-size: 8.75rem;
  }
}
</style>
