<template>
  <section class="content" v-html="block.content.html"></section>
</template>
<script>
export default {
  props: {
    block: {
      type: Object
    }
  }
}
</script>
