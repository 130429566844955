<template>
  <div>
    <article v-if="errors && errors.length" class="message is-danger is-size-7-mobile">
      <div class="message-body">{{ errors[0].message }}</div>
    </article>
    <fieldset class="fieldset lead-form">
      <div class="columns">
        <div class="column">
          <label class="label span is-size-7">First Name</label>
          <validated-input
            v-model="updates.firstName"
            name="First Name"
            rules="required"
            :showErrorIcon="true"
          />
        </div>
        <div class="column">
          <label class="label span is-size-7">Last Name</label>
          <validated-input
            v-model="updates.lastName"
            name="Last Name"
            rules="required"
            :showErrorIcon="true"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label span is-size-7">Email</label>
          <validated-input
            v-model="updates.email"
            name="Email"
            rules="required|email"
            :showErrorIcon="true"
          />
        </div>
        <div class="column">
          <label class="label span is-size-7">Phone</label>
          <validated-input
            v-model="updates.phone"
            name="Phone" 
            :rules="{ required: true, regex: /^\+?[0-9()-]*$/ }"
            :showErrorIcon="true"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label span is-size-7">Delivery Zip</label>
          <validated-input
            v-model="updates.zipcode"
            name="Delivery zip"
            ref="zipfield"
            :rules="{ required: true, regex: ZIP_REGEX }"
            :showErrorIcon="true"
          />
          <p v-if="error" class="help is-danger is-size-7-mobile">
            We couldn't find that zip code.
            <a @click.prevent="reset" class="has-text-black">Try a different zipcode?</a>
          </p>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { DynamicStep } from '../../flow/steps/mixin'
import ahoy from 'shared/ahoy'
const ZIP_REGEX = /^\d{5}$/
const isBlank = (value) => typeof value === 'string' ? value.trim().length === 0 : true
export default {
  mixins: [
    DynamicStep('zipcode', {
      validator: value => ZIP_REGEX.test(value)
    })
  ],
  data () {
    return {
      ZIP_REGEX,
      error: false,
      otherErrors: ''
    }
  },
  computed: {
    ...mapState('shared', [ 'zipcode' ]),
    ...mapState('quote', [ 'lead', 'errors' ]),
    ...mapGetters('quote/flow', ['steps'])
  },
  created () {
    const { id, zipcodeId, firstName, lastName, email, phone } = this.lead
    if (this.lead) {
      this.updates = {
        ...this.updates,
        id,
        firstName,
        lastName,
        email,
        phone,
        zipcode: zipcodeId,
        leadSource: 'containeralliance.com'
      }
    }
    if (this.zipcode && isBlank(this.updates.zipcode)) {
      this.updates.zipcode = this.zipcode
    }
  },
  methods: {
    reset () {
      this.error = false
      this.updates.zipcode = ''
      this.$refs.zipfield.reset()
      this.$refs.zipfield.focus()
    },
    async save () {
      try {       
        if (this.updates.quoteNumber) {          
          const quote = await this.$store.dispatch('quote/quote.load_by_reference', {reference: this.updates.quoteNumber, customerEmail: this.updates.email})
          if (quote) {
            if (quote.opportunity.closed) {
              this.error = true
              this.otherErrors = 'This quote cannot be changed because it\'s already closed'
              return { errors: [ 'This quote cannot be changed because it\'s already closed' ] }
            } else {
              const lead = await this.$store.dispatch('quote/lead.load', quote.opportunity.leadId)
              const result = await this.$store.dispatch('quote/lead.save', {...lead, ...this.updates, id: lead.id})
              await this.$store.dispatch('quote/pricing.load')
              await this.$store.dispatch('quote/flow/options.compute')
              return { lead: result.lead, hasQuote: true }
            }
          } else {
            this.error = true
            this.otherErrors = 'Previous quote not found'
            return { errors: [ 'Previous quote not found' ] }
          }
        } else {    
          ahoy.track('pricing.search', { zipcode: this.updates.zipcode })
          const zipCodeResult = await this.$store.dispatch('quote/pricing.load', this.updates.zipcode)
          const result = await this.$store.dispatch('quote/lead.save', {...this.updates, organizationId: zipCodeResult.organization.id})      
          if (result.lead) {
            await this.$store.dispatch('quote/flow/options.compute')
            return { lead: result.lead, hasQuote: false }   
          } else {
            return result
          }
        }
      } catch (e) {
        console.warn(e)
        this.error = true
        return {
          errors: [ e.message ]
        }
      }
    }
  }
}
</script>

<style scoped>
  .lead-form .columns {
    margin-bottom: 0;
  }
</style>