
<div>
  <div class="columns">
    <div class="column is-3">
      <label class="label" for="condition">Condition</label>
      <span class="select is-fullwidth">
        <select id="condition" v-model="condition" aria-label="menu" @change="update()">
          <option v-for="(option, label) in conditions" :key="option" :value="option">
            {{ label }}
          </option>
        </select>
      </span>
    </div>

    <div class="column is-3">
      <label class="label" for="size">Size</label>
      <span class="select is-fullwidth">
        <select id="size" v-model="size" aria-label="menu" @change="update()">
          <option>All</option>
          <option v-for="(label, size) in sizes" :key="size" :value="size">
            {{ label }}
          </option>
        </select>
      </span>
    </div>

    <div v-show="condition !== 'All' || size !== 'All'" class="column is-3">
      <label class="label">&nbsp;</label>
      <a @click="reset()" class="button">Clear Filters</a>
    </div>
  </div>
</div>
