<template>
  <div class="home_slider">
    <carousel 
      class="slider" 
      :perPage="1" 
      :autoplay="true" 
      :loop="true"
      paginationPosition="bottom-overlay" 
      paginationActiveColor="#174984" 
      :navigationEnabled="true" 
      :navigationNextLabel="nextNavigationButton()"
      :navigationPrevLabel="previousNavigationButton()"
      :autoplayTimeout="5000"
    >
      <slide v-for="(slide, index) in slides" :key="index" class="hero slide has-background-dark" :style="`background-image: url(` + slide.data.image.url + `)`">
        <div class="hero-body">
          <!-- Speeds up image rending -->
          <img :src="slide.data.image.url" :alt="slide.data.image.altText ? slide.data.image.altText : slide.data.image.fileName" style="display: none;" />
          
          <div v-if="slide.data.title || slide.data.subtitle" class="text-container">
            <h1 v-if="slide.data.title" class="title">
              {{ slide.data.title }}
            </h1>
            <h4 v-if="slide.data.subtitle" class="subtitle is-size-6 has-text-white is-size-7-mobile mb-5">
              {{ slide.data.subtitle }}
            </h4>
          </div>          

          <div v-if="slide.data.buttonText" class="btn-shipping-containers">
            <a class="button is-medium" :href="slide.data.buttonLink">
              <span class="mr-4">{{slide.data.buttonText}}</span>          
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 16H27" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18 7L27 16L18 25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>         
            </a>
          </div>
        </div>
        <!--<div class="hero-foot">
          <div v-if="slide.data.title || slide.data.subtitle" class="hero-slide-content">
            <component v-if="slide.data.title" :is="is_root && index == 0 ? 'h1' : 'h2'" class="title is-3 has-text-white is-size-4-mobile">
              {{ slide.data.title }}
            </component>
            <h4 v-if="slide.data.subtitle" class="subtitle is-size-6 has-text-white is-size-7-mobile">
              {{ slide.data.subtitle }}
            </h4>
          </div>
        </div>-->
      </slide>     
    </carousel>
    <lead-modal v-if="modalOpen" @close="modalOpen = false"></lead-modal>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import LeadModal from '../cover/helpers/lead-modal.vue'
import jsonFromTemplate from 'shared/json-from-template'

export default {
  props: {
    is_root: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Carousel,
    Slide,
    LeadModal
  },
  data () {
    return {
      modalOpen: false,
      slides: jsonFromTemplate('#home_slider')
    }
  },
  methods: {
    previousNavigationButton() {
      return `
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30 39L15 24L30 9" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`
    },
    nextNavigationButton() {
      return `
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 9L33 24L18 39" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `
    },
    openModal() {
      this.modalOpen = true
    }    
  }
}
</script>

<style lang="scss">
  .home_slider {
    button.VueCarousel-dot {
      width: 100px !important;
      height: 4px !important;
      border-radius: unset !important;
    }
    .VueCarousel-dot-container {
      display: table !important;
      margin: 0 auto !important;
      padding: 0 !important;
    }
    .VueCarousel-pagination--bottom-overlay {
      bottom: 2rem !important;
    }
    .VueCarousel-navigation-prev {
      left: 6rem !important;
      @media (max-width: 570px) { 
        display: none !important;;
      }
    }
    .VueCarousel-navigation-next {
      right: 6rem !important;;
      @media (max-width: 570px) { 
        display: none !important;;
      }
    }
    .VueCarousel-navigation-button {
      outline: unset !important;
    }
    .VueCarousel-dot {
      outline: unset !important;
    }
  }
</style>
<style scoped lang="scss">
  .text-container {
    max-width: 70%;
    
    @media (max-width: 920px) { 
      max-width: 100%;
    }
  }
  .title {
    font-size: 3rem;
    color: #fff;

    @media (max-width: 920px) { 
     font-size: 1.5rem;
    }
  }
  .hero-body {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 0) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10rem;

    @media (max-width: 920px) { 
     text-align: center;
     padding-left: 0;
     padding-right: 0;
    }
  }
  .btn-shipping-containers {
    span {
      font-weight: 400;
      font-size: 1.125rem;
      
    }
    button, a {
      font-weight: 700;
      font-size: 1.125rem;
      border: 1px solid #fff;
      background: transparent;
      color: #fff;
    }

    @media (max-width: 365px) { 
      button, a {
        span {
          margin-right: 0 !important;
        }
        svg {
          display: none;
        }
      }
    }
  }
</style>