
<form @submit.prevent="search">
  <fieldset>
    <div class="field dropdown is-active">
      <div class="dropdown-trigger">
        <div class="field has-addons">
          <div class="control is-expanded">
            <input v-model="query" :class="{ 'is-danger': error }" class="input" type="search"
              :placeholder="inputPlaceholder" />
          </div>
          <div class="control">
            <button v-if="error" @click.prevent="resetSearch()" class="button is-danger">
              Reset
            </button>
            <button v-else :class="{ 'is-loading': loading }" :disabled="isButtonDisabled" type="submit"
              class="button" :style="'background-color: ' + brand_color + '!important; color: ' + brand_color === 'white' ? '#767676' : 'white'">
              {{ submitLabel }}
            </button>
          </div>
        </div>
        <div v-if="error" class="help" :style="errorStyle">
          {{ error }}
          <b><a @click.prevent="resetSearch()">Try Again</a></b>
        </div>
      </div>
      <div v-if="!coordinates && cities.length" class="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <p class="dropdown-item"><b>We found mulitple results...</b></p>
          <a v-for="city in cities" :key="city.id" :href="getPath(city.node)" class="dropdown-item">
            {{ city.node.name }}, {{ city.node.stateId | upcase }}
          </a>
        </div>
      </div>
    </div>
    <geolocate @success="searchByCoordinates" v-if="showSearchByGeolocate" />
  </fieldset>
</form>
