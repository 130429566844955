<template>
  <div
    class="container-card column container-type-card m-5"
    :style="'color:' + brandColor"
  >
    <a :href="url">
      <div class="header-section">
        <icon :type="iconType(solution.title)" />
        <b>{{ solution.title }}</b>
      </div>
      <p>{{ shortDescription() }}</p>
    </a>
  </div>
</template>

<script>
import Icon from './helpers/icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    solution: Object,
    url: String,
    brandColor: String,
    content: String
  },
  methods: {
    shortDescription() {
      if (this.content) {
        return (
          this.content
            .replace(/<[^>]*>?/gm, '')
            .split('&nbsp;')
            .join('')
            .split('amp;')
            .join('')
            .substring(0, 200) + '...'
        )
      }
      return ''
    },
    iconType(title) {
      if (title.toLowerCase().includes('sale')) {
        return 'container-sales'
      } else if (title.toLowerCase().includes('rental')) {
        return 'container-rentals'
      } else if (title.toLowerCase().includes('refrigerated')) {
        return 'refrigerated-container'
      } else if (title.toLowerCase().includes('office')) {
        return 'portable-offices'
      } else if (title.toLowerCase().includes('modification')) {
        return 'container-modifications'
      } else if (title.toLowerCase().includes('accessor')) {
        return 'container-accessories'
      }
    }
  }
}
</script>
<style scoped lang="scss">
.column {
  margin-bottom: 10px !important;
}

@media (max-width: 875px) {
  column {
    margin: 0 !important;
  }
}

.container-card {
  padding: 0rem 6rem;
  margin-bottom: 1.375rem;

  @media (max-width: 450px) {
    padding: 0 2rem;
  }
}

.is-one-third-custom {
  width: 32%;
  flex: none;
  margin: 0 auto;
  margin-bottom: 1.375rem;
  @media (max-width: 876px) {
    width: auto;
    max-width: 450px;
  }
}

.container-type-card {
  box-shadow: 0px 8px 16px -2px #0a0a0a1a;
  padding: 1.375rem;
  padding-bottom: 2.625rem;
  border-radius: 0.25rem;
  cursor: pointer;

  p {
    color: #4a4a4a;
    font-size: 0.875rem;
    font-weight: 400;
  }

  &:hover {
    border: 3px solid;
    box-shadow: 0px 16px 16px 0px #0a0a0a33;
  }
}

.header-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.875rem;

  b {
    margin-left: 0.75rem;

    font-size: 1.25rem;
    font-weight: 600;
    text-align: left;
  }

  p {
    font-size: 8.75rem;
  }
}

.middle-card {
  margin-left: 0.75rem;
  margin-right: 0.75rem;

  @media (max-width: 875px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.second-section {
  margin-top: 1.375rem;
}
</style>
