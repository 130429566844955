<template>
  <modal @close="$emit('close')">
    <div slot="header">
      <h4 class="title is-4">Thanks for the inquiry, {{ lead.firstName }}.</h4>
    </div>
    <div slot="body">
      One of our representatives will be in contact shortly.
    </div>
    <div slot="actions">
      <button @click="$emit('close')" class="button is-primary">Continue</button>
    </div>
  </modal>
</template>

<script>
import Modal from 'shared/components/modal.vue'
export default {
  components: {
    Modal
  },
  props: {
    lead: {
      type: Object,
      required: true
    }
  }
}
</script>
