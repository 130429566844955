<template>
  <div>
    <fieldset class="fieldset">
      <p class="neutral-gray has-text-weight-semibold text-sm mb-5">
        Rate the below factors based on importance to you when considering a container purchase.
      </p> 
      <div class="field" v-for="category in categories" :key="category.value">
        <p class="neutral-black is-size-6 mb-3">{{category.label}}</p>
        <div class="is-flex is-justify-content-space-between neutral-gray is-size-7 mb-3">
          <span>Low Importance</span>
          <span>Hight Importance</span>
        </div>
        <div class="is-flex">
          <a @click="chooseRate(category.value, index)" class="rate-index" :class="updates.conditionCost && updates.conditionCost[category.value] == index && 'active'" v-for="index in Array.from({length: 5}, (_, i) => i + 1)" :key="index">
            {{index}}
          </a>
        </div>       
      </div>
      <!--<div class="field">
        <p class="neutral-black is-size-6 mb-3">Nice Cosmetics</p>
        <div class="is-flex is-justify-content-space-between neutral-gray is-size-7 mb-3">
          <span>Low Importance</span>
          <span>Hight Importance</span>
        </div>
        <div class="is-flex">
          <a @click="chooseRate('cosmetic', index)" class="rate-index" v-for="index in Array.from({length: 5}, (_, i) => i + 1)">
            {{index}}
          </a>
        </div>       
      </div>
      <div class="field">
        <p class="neutral-black is-size-6 mb-3">Longevity of Use</p>
        <div class="is-flex is-justify-content-space-between neutral-gray is-size-7 mb-3">
          <span>Low Importance</span>
          <span>Hight Importance</span>
        </div>
        <div class="is-flex">
          <a @click="chooseRate('longevity', index)" class="rate-index" v-for="index in Array.from({length: 5}, (_, i) => i + 1)">
            {{index}}
          </a>
        </div>       
      </div>-->
    </fieldset>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { DynamicStep } from './mixin'
export default {
  
  mixins: [ DynamicStep('conditionCost'), {
    validator: value => value != ''
  }],
  computed: {
    ...mapState('quote', [ 'lead' ])
  },
  data() {
    return {    
      rates: {
        cost: null,
        cosmetic: null,
        longevity: null
      },
      categories: [
        {label: 'Low Cost', value: 'cost'},
        {label: 'Nice Cosmetics', value: 'cosmetic'},
        {label: 'Longevity of Use', value: 'longevity'}
      ]
    }
  },
  methods: {
    chooseRate(type, value) {
      if (type == 'cost') {
        Object.assign(this.updates, {
          conditionCost: {
            ...this.updates.conditionCost,
            cost: value
          }
        })
      } else if (type == 'cosmetic') {
        Object.assign(this.updates, {
          conditionCost: {
            ...this.updates.conditionCost,
            cosmetic: value
          }
        })        
      } else {
        Object.assign(this.updates, {
          conditionCost: {
            ...this.updates.conditionCost,
            longevity: value
          }
        })   
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .rate-index {
    border: 1px solid #EBEBEB;
    width: 100%;
    margin-right: 2rem;
    text-align: center;
    font-size: 0.875rem;
    color: #194985;
    padding: 0.25rem 0px;
    font-weight: 600;
  }
  .rate-index.active {
    background-color: #194985;
    color: white;
    border-color: #194985;
  }
  .rate-index:last-child {
    margin-right: 0;
  }
</style>
