<template>
  <div v-if="quote && quote.items && quote.items.length > 0" class="summary-wrapper checkout-form">
    <div class="is-flex is-justify-content-space-between is-align-items-center mb-4">
      <p class="has-text-black has-text-weight-semibold text-18">Summary</p>
      <!--<button v-if="showPrice" @click="generatePdf(quote)" class="button download-pdf-button is-flex"><span class="mr-2">Download</span> <icon type="pdf" /></button>-->

    </div>

    <ul class="order-summary-list-items">
      <p class="neutral-gray text-sm has-text-weight-semibold mb-2 px-2">Container{{ containers.length > 1 ? 's' : '' }}</p>
      <li v-for="item in containers" :key="item.id" class="order-summary-item">        
        <div class="row flex-on-mobile vertical-align-middle row-flex-start-item-max-width is-relative">
          <div class="is-flex row is-3 p-0">
            <a class="remove-icon" @click="removeItem(item.id)">
              <icon type="remove-item-button" />
            </a>
            <figure v-if="item.priceBookEntry.product.primaryPhoto" class="summary-image">
              <img :src="imageUrl(item.priceBookEntry.product.primaryPhoto.key, { resize: { width: 180, height: 130 } } )">
            </figure>
            <div class="item item-8 item-conditional__to-8-on-desktop item-conditional__to-7-on-mobile">
              <div class="order-summary-item-title is-size-6">
                <span class="order-item-title text-sm">{{ item.priceBookEntry.product.name }}</span>
              </div>
            </div>
          </div>
          <div class="is-flex item item-2 text-right m-text-right order-summary-price order-complete">
            <span :class="`order-item-quantity text-18 ${showPrice ? 'mr-4' : 'mr-0'}`">{{ item.quantity }}</span>
            <span v-if="showPrice" class="text-sm has-text-black has-text-weight-semibold min-w-7xl">{{ item.priceCents | dollars }}</span>
          </div>
        </div>
      </li>
      <p class="neutral-gray text-sm has-text-weight-semibold mb-2 px-2">Delivery</p>
      <li v-for="item in deliveries" :key="item.id" class="order-summary-item">        
        <div class="row neutral-gray flex-on-mobile vertical-align-middle row-flex-space-between is-relative">
          <div>
            <div class="is-3 p-0">
              <figure v-if="item.priceBookEntry.product.primaryPhoto" class="summary-image">
                <img :src="imageUrl(item.priceBookEntry.product.primaryPhoto.key, { resize: { width: 180, height: 130 } } )">
              </figure>
            </div>
            <div class="item item-8 item-conditional__to-8-on-desktop item-conditional__to-7-on-mobile">
              <div class="order-summary-item-title is-size-6 item-delivery-row">
                <span class="order-item-title text-sm neutral-gray">{{ item.priceBookEntry.product.name }}</span>
              </div>
            </div>

          </div>
          <div class="is-flex item item-2 text-right m-text-right order-summary-price order-complete">
            <span :class="`order-item-quantity text-18 ${showPrice ? 'mr-4' : 'mr-0'}`">{{ item.quantity }}</span>
            <span v-if="showPrice" class="text-sm has-text-black has-text-weight-semibold min-w-7xl">{{ item.priceCents | dollars }}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { dollars } from 'shared/filters'
import Icon from './icon.vue'
import { imageUrl } from 'shared/s3'

export default {
  components: {
    Icon
  },
  filters: {
    dollars
  },
  computed: {
    ...mapState('quote', ['quote', 'priceBook']),
    containers() {
      return this.quote.items.filter(item => item.priceBookEntry.product.classification == 'container' || item.priceBookEntry.product.classification == 'container_rental')
    },
    deliveries() {
      return this.quote.items.filter(item => item.priceBookEntry.product.classification != 'container' && item.priceBookEntry.product.classification != 'container_rental')
    },
    showPrice() {
      if (this.priceBook.priceVisibility == 'private') {
        return false
      } else if (this.quote) {
        return this.quote.items.filter(item => item.priceBookEntry.priceVisibility == 'private').length == 0
      }
      return true
    }
  },
  data() {
    return {
      documentCount: 0,
      documents: []
    }
  },
  async created () {
    await this['pricing.get']()
  },
  methods: {
    ...mapActions('quote', ['pricing.get']),
    imageUrl,
    download(pdf) {
      let linkSource = `data:application/pdf;base64,${pdf}`
      const downloadLink = document.createElement('a')
      const fileName = `quote_${Date.now()}.pdf`

      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
      linkSource = ''
      downloadLink.remove()
    },
    async generatePdf(record) {
      const { pdf } = await this.$store.dispatch('quote/quote.generatePdf', {              
        id: record.id       
      })

      if (pdf) {
        this.download(pdf)
      }
    },
    async removeItem(id) {
      await this.$store.dispatch('quote/quote.save', {
        eventName: 'item.delete',
        input: {
          item: {
            id
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .summary-wrapper.checkout-form {
      padding-top: 1.375rem
  }
  .min-w-7xl {
    min-width: 4.5rem
  }
  .checkout-form button, .checkout-form .button.download-pdf-button {
    background-color: #6B778C;
    color: #ffffff;
    margin-top: 0;
    height: 40px;
    line-height: 1.25rem;
    font-size: 0.875rem;
    font-weight: 600;
  }
  .checkout-form button:hover:not([disabled]), .checkout-form .button.download-pdf-button:hover:not([disabled]) {
    background-color: #6B778C;
    color: #ffffff;
  }
  .checkout-form .summary-image {
    width: 48px;
    height: auto;
    margin-right: 0.75rem;
  }
  .checkout-form .remove-icon {
    position: absolute;
    top: -5px;
    left: -10px;
  }
</style>