<template>
  <span>
    <a :href="`https://www.facebook.com/sharer.php?u=${ url }`" target="blank" rel="noopener noreferrer">
      <span class="icon">
        <i data-feather="facebook" width="1.5rem" height="1.5rem"></i>
      </span>
    </a>
    <a :href="`https://twitter.com/intent/tweet?url=${ url }&text=${ title }&amp;hashtags=shippingcontainers`"
       target="blank"
       rel="noopener noreferrer">
      <span class="icon">
        <i data-feather="twitter" width="1.5rem" height="1.5rem"></i>
      </span>
    </a>
    <a :href="`https://www.linkedin.com/shareArticle?url=${ url }`" target="blank" rel="noopener noreferrer">
      <span class="icon">
        <i data-feather="linkedin" width="1.5rem" height="1.5rem"></i>
      </span>
    </a>
    <a :href="url" @click.prevent="copyToClipBoard()" :data-href="url" target="blank" rel="noopener noreferrer">
      <span v-show="linkCopied" class="icon show-message" data-title="Copied!">
        <i data-feather="check" width="1.5rem" height="1.5rem"></i>
      </span>
      <span v-show="!linkCopied" class="icon">
        <i data-feather="link" width="1.5rem" height="1.5rem"></i>
      </span>
    </a>
  </span>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      default: 'https://containeralliance.com'
    },
    title: {
      type: String,
      default: 'Container Alliance'
    }
  },
  data() {
    return {
      linkCopied: false
    }
  },
  methods: {
    copyToClipBoard() {
      const el = document.createElement('textarea')
      el.value = this.url
      document.body.appendChild(el)
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.linkCopied = true
      setTimeout(() => this.linkCopied = false, 800)
    }
  }
}
</script>
<style>
.show-message {
  position: relative;
}
.show-message:after {
  content: attr(data-title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -25px;
  background: #2196f3;
  padding: 1px 5px;
  color: #fff;
  font-size: 13px;
  border-radius: 4px;
}

</style>
