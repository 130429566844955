
<section class="container">
  <div class="is-flex">
    <div 
      class="step" 
      :class="steps.indexOf(current) > index && 'done' || current == step && 'active'"
      v-for="(step, index) in quoteSteps" 
      :key="step"
    />
  </div>
  <span v-html="currentText" class="neutral-black is-size-7"/>
</section>
