<template>
  <div>
    <div class="columns">
      <div class="column is-3">
        <label class="label" for="condition">Condition</label>
        <span class="select is-fullwidth">
          <select id="condition" v-model="condition" aria-label="menu" @change="update()">
            <option v-for="(option, label) in conditions" :key="option" :value="option">
              {{ label }}
            </option>
          </select>
        </span>
      </div>

      <div class="column is-3">
        <label class="label" for="size">Size</label>
        <span class="select is-fullwidth">
          <select id="size" v-model="size" aria-label="menu" @change="update()">
            <option>All</option>
            <option v-for="(label, size) in sizes" :key="size" :value="size">
              {{ label }}
            </option>
          </select>
        </span>
      </div>

      <div v-show="condition !== 'All' || size !== 'All'" class="column is-3">
        <label class="label">&nbsp;</label>
        <a @click="reset()" class="button">Clear Filters</a>
      </div>
    </div>
  </div>
</template>

<script>
const SIZES = {
  'ODD': 'Odd',
  '10': '10\'',
  '15': '15\'',
  '20': '20\'',
  '20HC': '20\' High Cube',
  '24': '24\'',
  '25': '25\'',
  '30': '30\'',
  '40': '40\'',
  '40HC': '40\' High Cube',
  '45': '45\'',
  '45HC': '45\' High Cube',
  '53HC': '53\' High Cube',
  '60': '60\''
}
const toggle = (elements, filters) => {
  elements.forEach(i => {
    let visible = true
    Object.entries(filters).forEach(([filter, value]) => {
      if (!value || value === 'All') return

      visible = visible && i.config[filter] === value
    })
    i.style.display = visible ? 'block' : 'none'
  })
}
export default {
  data() {
    return {
      condition: 'All',
      conditions: {
        'All': 'All',
        'One Trip (Like New)': 'one_trip',
        'Cargo Worthy': 'cargo_worthy',
        'Refurbished': 'refurbished',
        'WWT': 'wind_water_tight'
        // ,
        // 'Other': 'other'
      },
      size: 'All',
      sizes: {}
    }
  },
  mounted() {
    const elements = document.querySelectorAll('[data-config]')
    elements.forEach(i => {
      i.config = JSON.parse(i.getAttribute('data-config'))
      this.sizes[i.config.size] = SIZES[i.config.size] || i.config.size
    })
    // sort sizes
    this.sizes = Object.keys(this.sizes).sort().reduce((obj, key) => {
      obj[key] = this.sizes[key]
      return obj
    }, {})
  },
  methods: {
    update() {
      const elements = document.querySelectorAll('[data-config]')
      toggle(elements, { condition: this.condition, size: this.size })
    },
    reset() {
      this.condition = 'All'
      this.size = 'All'
      this.update()
    }
  }
}
</script>
