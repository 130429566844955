
<span v-if="type === 'caret-down'">
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 12L16 22L6 12" stroke="#174984" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</span> 
<span v-else-if="type === 'caret-up'">
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 20L16 10L26 20" stroke="#174984" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</span>
