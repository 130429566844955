<template>
  <div>
    <div class="is-flex is-justify-content-space-between px-3">
      <span class="has-text-weight-bold">Quote Summary</span> 
      <span class="tag">Zipcode {{ quote.zipcodeId }}</span>
      <!-- <span class="has-text-danger is-clickable" @click="">Clear</span> -->
    </div>
    <hr class="title-divider" />
    <div v-if="quote && quote.totalQuantity === 0" class=" has-text-centered empty-quote-container">
      <div>
        <h5 class="title is-5 mb-2">Your quote is empty.</h5>
        <p>Call us at {{ phoneNumber }} to speak with a representative.</p>
      </div>
      <div class="buttons-container">
        <button
          :class="{ 'disabled': invalid, 'is-loading': loading }"
          :disabled="invalid || loading > 0"
          class="button is-primary is-fullwidth is-medium"
          @click="handleFinishQuote()"
        >
          Continue quote
        </button>
        <button
          :class="{ 'disabled': invalid, 'is-loading': loading }"
          :disabled="invalid || loading > 0"
          class="button is-primary is-fullwidth is-medium"
          @click="handleNewQuote()"
          data-vue-init="lead-flow"
          data-props='{ "gtag": "get_quote"}'
        >
          New quote
        </button>
      </div>
    </div>

    <div v-else>
      <table class="table is-borderless is-fullwidth is-marginless mb-2">
        <quote-item
          v-for="item in quote.items"
          :key="item.id"
          :item="item"
          :showPrice="showPrice"
        ></quote-item>
      </table>
      <quote-totals />
      <div class="px-3">
        <button
          :class="{ 'disabled': invalid, 'is-loading': loading }"
          :disabled="invalid || loading > 0"
          class="button is-primary is-fullwidth is-medium"
          @click="handleFinishQuote()"
        >
          Finish Quote
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import QuoteItem from './item.vue'
import QuoteTotals from './totals.vue'
export default {
  components: {
    QuoteItem,
    QuoteTotals
  },
  data() {
    return {
      loading: 0,
      invalid: false
    }
  },
  computed: {
    ...mapState('quote', [ 'quote' ]),
    ...mapState('shared', [ 'phoneNumber' ]),
    showPrice () {
      if (this.quote && this.quote.priceBook && this.quote.priceBook.priceVisibility == 'private') {
        return false
      } else if (this.quote.items.filter(item => item.priceBookEntry.priceVisibility == 'private').length > 0) {
        return false
      }
      return true
    }
  },
  methods: {
    handleFinishQuote() {
      this.$emit('hide')
      window.location.href = '/cart'
      // const event = new CustomEvent('vue-app:init', { detail: { name: 'complete-quote-modal' }})
      // document.dispatchEvent(event)
    },
    handleNewQuote() {
      this.$emit('hide') // hides the 'view quote modal'
      this.$store.commit('quote/reset')
      this.$store.commit('quote/flow/reset')
    }
  }
}
</script>

<style lang="scss" scoped>
.title-divider {
  margin: 8px 0;
}
.empty-quote-container {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 5px;

  .buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding: 0px 12px;
  }

  .buttons-container button {
    width: 100%;
  }
}
</style>
