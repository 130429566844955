
<validation-observer v-slot="{ invalid }" slim>
  <modal @close="handleClose" @confirm="handleCloseConfirmation" class="checkout-custom-modal"
    :class="centralizedModals.includes(stepComponent) ? 'modal-centralized' : ''" :showCloseButton="false">
    <div v-if="prompt" slot="prompt">
      <h5 class="title is-4">Are you sure you'd like to leave?</h5>
      <!-- <p class="subtitle is-size-6">Any progress on this quote will be lost.</p> -->
    </div>

    <div slot="header" class="container">
      <header class="header-quote is-relative">
        <h4 class="title is-5 has-text-white has-text-centered has-text-weight-bold m-0">
          <slot  name="title ">{{ headerText }}</slot>
        </h4>
        <a class="close-icon has-text-white" @click.prevent="handleClose">
          <icon type="x-close" />
        </a>
      </header>
      <step-tabs v-if="quoteSteps.includes(stepComponent)" :current="stepComponent" :steps="steps" />
    </div>
    <div :class="stepComponent != 'options' ? 'component-slot' : ''" slot="body">
      <component :is="stepComponent" ref="stepComponent" @next="next" @close="handleClose"
        @enabled="(enabled) => disabled = !enabled">
      </component>
    </div>

    <div slot="footer" class="is-flex-grow-1">
      <div class="summary-totals" v-if="quote && ['options', 'checkout', 'confirmation'].includes(stepComponent)">
        <!--<quote-totals />-->
      </div>
      <div class="buttons-container">
        <div class="level is-mobile">
          <template v-if="isComplete">
            <div class="level-item">
              <a href="/solutions/container-sales" class="button close-button is-fullwidth">See Other Containers</a>
              <button @click="handleClose" class="button action-button is-fullwidth">Close</button>
            </div>
          </template>
          <template v-else>

            <div v-if="canGoBack" class="level-item is-halfwidth">
              <button @click="back()" class="button close-button is-fullwidth">Back</button>
            </div>
            <div class="level-item is-halfwidth">
              <button @click="next" :class="{ 'is-loading': loading }" :disabled="invalid || disabled || loading"
                class="button action-button is-fullwidth">
                {{ nextButtonText }}
              </button>
            </div>
          </template>
        </div>
        <div v-if="(stepComponent == 'options' || stepComponent == 'email-quote') && showPrice" class="is-fullwidth">
          <p class="neutral-gray has-text-weight-semibold text-sm mb-2 has-text-centered	">Ready to make a purchase?
          </p>
          <button @click="reserveOrder" class="button action-button is-fullwidth"
            :disabled="invalid || disabled || loading" :class="{ 'is-loading': loading }">
            Reserve Order
          </button>
        </div>
      </div>
    </div>


    <!-- <div slot="footer" class="tags">
      <span v-for="entry in priceBookEntries" :key="entry.id" class="tag">
        {{ entry.product.code }}
      </span>
    </div> -->
  </modal>
</validation-observer>
