
<div class="columns is-multiline container">
  <div v-for="image in block.images" :key="image.id" class="column" :class="grid">
    <figure class="box">
      <img :src="image.url" @click="$emit('setModalImage', image)" :alt="image.fileName" />
    </figure>
    <figcaption v-if="image.altText">
      {{ image.altText }}
    </figcaption>
  </div>
</div>
