
<div>
  <span v-if="(lead && priceBookEntry) || !lead">
    <button class="button is-primary bg-link-color add-button has-text-weight-semibold m-auto" :disabled="loading" @click="addToQuote">
      <span v-if="loading">Loading...</span>
      <span v-else>Add To Quote</span>
    </button>
  </span>
  <span v-else>
    <div v-if="organization" class="is-size-7">
      <p><b>{{ organization.name }}</b> is our partner company for zipcode <b>{{ lead.zipcodeId }}</b>.</p>
      <p>Give them a call to get a quote and reserve the next unit available today!</p>
      <p><b>{{ organization.phone }}</b></p>
    </div>

  </span>
  <lead-modal v-if="modalOpen" :product="product"  @close="modalOpen = false" :redirectType="redirectType"></lead-modal>
</div>
