// 29044 - No rentals
// 85001 - No refers
// 59901 - All options

export const PRICING_ENABLED = import.meta.env.VITE_PRICING_ENABLED === 'true'

const LEAD_ATTRIBUTES = {
  zipcode: '',
  company: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  notes: '',
  saleType: '',
  useCase: '',
  size: '',
  condition: ''
}

export const newLead = () => {
  return {
    ...LEAD_ATTRIBUTES
  }
}
