
<div>
  <fieldset class="fieldset">
    <p class="neutral-gray has-text-weight-semibold text-sm mb-5">
      Rate the below factors based on importance to you when considering a container purchase.
    </p> 
    <div class="field" v-for="category in categories" :key="category.value">
      <p class="neutral-black is-size-6 mb-3">{{category.label}}</p>
      <div class="is-flex is-justify-content-space-between neutral-gray is-size-7 mb-3">
        <span>Low Importance</span>
        <span>Hight Importance</span>
      </div>
      <div class="is-flex">
        <a @click="chooseRate(category.value, index)" class="rate-index" :class="updates.conditionCost && updates.conditionCost[category.value] == index && 'active'" v-for="index in Array.from({length: 5}, (_, i) => i + 1)" :key="index">
          {{index}}
        </a>
      </div>       
    </div>
    <!--<div class="field">
      <p class="neutral-black is-size-6 mb-3">Nice Cosmetics</p>
      <div class="is-flex is-justify-content-space-between neutral-gray is-size-7 mb-3">
        <span>Low Importance</span>
        <span>Hight Importance</span>
      </div>
      <div class="is-flex">
        <a @click="chooseRate('cosmetic', index)" class="rate-index" v-for="index in Array.from({length: 5}, (_, i) => i + 1)">
          {{index}}
        </a>
      </div>       
    </div>
    <div class="field">
      <p class="neutral-black is-size-6 mb-3">Longevity of Use</p>
      <div class="is-flex is-justify-content-space-between neutral-gray is-size-7 mb-3">
        <span>Low Importance</span>
        <span>Hight Importance</span>
      </div>
      <div class="is-flex">
        <a @click="chooseRate('longevity', index)" class="rate-index" v-for="index in Array.from({length: 5}, (_, i) => i + 1)">
          {{index}}
        </a>
      </div>       
    </div>-->
  </fieldset>
</div>
