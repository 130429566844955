
<div v-if="quote && quote.totalQuantity > 0 && showPrice">
  <div class="divisor"/>
  <!--<div class="px-3 has-text-weight-bold mb-3">Shipping &amp; Delivery Estimates</div>-->
  <div class="has-text-grey mb-3 spacing">
    <div class="is-flex is-justify-content-space-between mb-3 text-sm">
      <span>Item Total:</span>
      <span class="text-sm has-text-black has-text-weight-semibold">{{ dollars(quote.totalItemsCents) }}</span>
    </div>
    <div class="is-flex is-justify-content-space-between mb-3 text-sm">
      <span>
        Delivery Total:
        <!--<i class="is-size-7">(Non-Taxable)</i>-->
      </span>
      <span class="text-sm has-text-black has-text-weight-semibold">{{ dollars(quote.totalDeliveryCents) }}</span>
    </div>
    <div class="is-flex is-justify-content-space-between mb-3 text-sm">
      <span class="is-flex">
        Tax: ({{ percentage(quote.taxRate) }})
        <b-tooltip class="total ml-2" position="is-right">                 
          <span class="inform-icon-color">
            <icon type="warning-circle" />
          </span>
          <template v-slot:content>
            <i class="is-size-7">(Zip {{ quote.zipcode.id }})</i>
          </template>      
        </b-tooltip>
      </span>
      <span class="text-sm has-text-black has-text-weight-semibold">{{ dollars(quote.totalTaxCents) }}</span>
    </div>
  </div>
  <div class="divisor"/>
  <div class="is-flex is-justify-content-space-between text-sm mb-3 spacing">
    <span class="has-text-grey">Total: </span>
    <span class="total has-text-weight-semibold text-18">{{ dollars(quote.totalCents) }}</span>
  </div>
</div>
