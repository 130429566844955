
<div class="home_slider">
  <carousel 
    class="slider" 
    :perPage="1" 
    :autoplay="true" 
    :loop="true"
    paginationPosition="bottom-overlay" 
    paginationActiveColor="#174984" 
    :navigationEnabled="true" 
    :navigationNextLabel="nextNavigationButton()"
    :navigationPrevLabel="previousNavigationButton()"
    :autoplayTimeout="5000"
  >
    <slide v-for="(slide, index) in slides" :key="index" class="hero slide has-background-dark" :style="`background-image: url(` + slide.data.image.url + `)`">
      <div class="hero-body">
        <!-- Speeds up image rending -->
        <img :src="slide.data.image.url" :alt="slide.data.image.altText ? slide.data.image.altText : slide.data.image.fileName" style="display: none;" />
        
        <div v-if="slide.data.title || slide.data.subtitle" class="text-container">
          <h1 v-if="slide.data.title" class="title">
            {{ slide.data.title }}
          </h1>
          <h4 v-if="slide.data.subtitle" class="subtitle is-size-6 has-text-white is-size-7-mobile mb-5">
            {{ slide.data.subtitle }}
          </h4>
        </div>          

        <div v-if="slide.data.buttonText" class="btn-shipping-containers">
          <a class="button is-medium" :href="slide.data.buttonLink">
            <span class="mr-4">{{slide.data.buttonText}}</span>          
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 16H27" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18 7L27 16L18 25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>         
          </a>
        </div>
      </div>
      <!--<div class="hero-foot">
        <div v-if="slide.data.title || slide.data.subtitle" class="hero-slide-content">
          <component v-if="slide.data.title" :is="is_root && index == 0 ? 'h1' : 'h2'" class="title is-3 has-text-white is-size-4-mobile">
            {{ slide.data.title }}
          </component>
          <h4 v-if="slide.data.subtitle" class="subtitle is-size-6 has-text-white is-size-7-mobile">
            {{ slide.data.subtitle }}
          </h4>
        </div>
      </div>-->
    </slide>     
  </carousel>
  <lead-modal v-if="modalOpen" @close="modalOpen = false"></lead-modal>
</div>
