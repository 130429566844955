
<div calss="component-container">
  <div class="card-container">
    <div class="row">
      <button
        @click="cardClick('sale')"
        class="card"
      >
        <div class="header-section">
          <icon type="container-sales" />
          <b>Container Sales</b>
        </div>
        <p>{{ shortDescription('container-sales') }}</p>
      </button>
      <button
        @click="cardClick('rental')"
        class="card"
      >
        <div class="header-section">
          <icon type="container-rentals" />
          <b>Container Rentals</b>
        </div>
        <p>{{ shortDescription('container-rentals') }}</p>
      </button>
      <button
        @click="cardClick('refrigerate')"
        class="card"
      >
        <div class="header-section">
          <icon type="refrigerated-container" />
          <b>Refrigerated Containers</b>
        </div>
        <p>{{ shortDescription('refrigerated-containers') }}</p>
      </button>
    </div>
    <div class="row">
      <button
        @click="cardClick('office')"
        class="card"
      >
        <div class="header-section">
          <icon type="portable-office" />
          <b>Portable Offices</b>
        </div>
        <p>{{ shortDescription('portable-offices') }}</p>
      </button>
      <button
        @click="cardClick('modification')"
        class="card"
      >
        <div class="header-section">
          <icon type="container-modification" />
          <b>Container Modifications</b>
        </div>
        <p>{{ shortDescription('container-modifications') }}</p>
      </button>
      <button
        class="card"
        @click="routingMethod('/solutions/accessories')"
      >
        <div class="header-section">
          <icon type="container-accessory" />
          <b>Container Alliance Accessories</b>
        </div>
        <p>{{ shortDescription('accessories') }}</p>
      </button>
    </div>
    <zipcodeModal
      v-if="modalOpen"
      @close="modalOpen = false"
      :solutionSlug="solutionSlug"
    />
  </div>
</div>
