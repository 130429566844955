<template>
  <div v-click-outside="() => isMobile() ? null :toggle(false)">
    <button class="button is-primary is-fullwidth is-small-tablet" @click="toggle()">
      View Quote
    </button>
    <div v-if="showSummary" class="quote-summary-dropdown">
      <quote-summary @hide="toggle(false)" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ClickOutside from 'vue-click-outside'
import QuoteSummary from './index.vue'
export default {
  components: {
    QuoteSummary
  },
  directives: {
    ClickOutside
  },
  computed: mapState('quote', ['showSummary' ]),
  methods: {
    toggle (visible) {
      this.$store.commit('quote/summary.visible', visible === undefined ? !this.showSummary : visible)
    },
    isMobile() {
      return screen.width <= 579
    }
  }
}
</script>

<style scoped>
.quote-summary-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  padding: 1em 0em;
  width: 450px;
  margin-top: 10px;
  margin-right: 8px;
  border-radius: 5px;
  -webkit-box-shadow: -2px 6px 14px 1px rgba(0,0,0,0.25);
  -moz-box-shadow: -2px 6px 14px 1px rgba(0,0,0,0.25);
  box-shadow: -2px 6px 14px 1px rgba(0,0,0,0.25);
  
}
@media(min-width: 580px) and (max-width: 1024px){
  .quote-summary-dropdown {
    left: -15px;
    top: 54px;
  }
}
@media(max-width: 579px){
  .quote-summary-dropdown {
    left: 0px;
    width: 100%;
  }
}
</style>
