<template>
  <div>
    <fieldset class="fieldset">
      <p class="neutral-gray has-text-weight-semibold text-xs mb-5">
        What will you be using containers for?
      </p>    
      <div class="field">
        <div class="control radio-list is-flex-tablet	is-justify-content-space-between">       
          <a 
            v-for="(label, value) in stepOptions" 
            :key="value" 
            @click="chooseCase(value)" 
            class="select-card is-flex is-flex-direction-column is-justify-content-center	is-align-items-center neutral-gray is-size-7 mb-2" 
            :class="updates.useCase == value ? 'active' : ''">
            <!--<input v-model="updates.useCase" type="radio" :value="value">-->          
              <icon :type="value" />
              <span class="mt-1">{{ label }}</span>
          </a>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { DynamicStep } from './mixin'
import Icon from './helpers/icon.vue'
export default {
  mixins: [ DynamicStep('useCase') ],
  components: {
    Icon
  },
  methods: {
    chooseCase(value) {
      this.updates.useCase = value
    }
  }
}
</script>
