
<div class="checkout-form quote summary pd-0">
  <div class="header has-text-centered p-5 mb-2 has-background-white neutral-black">
    <h2 class="title is-4 has-text-weight-bold mb-4 neutral-black">Thank You!</h2>
    <div class="my-body-section">
      <div class="confirmation-main-copy">
        <p class="is-size-6">
          We've sent you an email with your quote details to
          <b class="highlight">{{ quote.customer.email }}</b>.
        </p>
      </div>
      <div class="confirmation-sub-copy mt-5">
        <p>
          If you're ready to buy, you can <a @click.prevent="buyNow" class="link-color">checkout now</a>.
          <br />
          Did you know we also offer <a href="/containers/rentals" class="link-color">Renting / Leasing</a> and <a
            href="/container-modifications" class="link-color">Modifications</a>.
          <br />
          Have more questions? Give us a call <a href="tel:+1-800-386-2345" class="link-color">(800) 386-2345</a> or <a
            href="mailto:sales@containeralliance.com" class="link-color">Email us</a>.
        </p>
      </div>
    </div>
  </div>
</div>
