<template>
  <header class="header" :class="navClass">
    <div class="mobile-overlay" @click="toggleNav"></div>
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a id="site-logo" class="navbar-item" href="/">Container Alliance</a>
        <a :href="`tel:${organizationPhone || phoneNumber}}`" class="navbar-item has-text-primary is-show-mobile">{{ formatPhoneNumber(organizationPhone || phoneNumber) }}</a>
        <a @click="toggleNav" class="navbar-burger burger" :class="navClass" role="button" aria-label="menu">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      
      <div class="navbar-menu" :class="navClass">
        <div class="navbar-end">
          <a href="#" @click="openModal('sale')" class="navbar-item has-text-primary has-text-weight-bold">Buy a
            Container</a>
          <a href="#" @click="openModal('rental')" class="navbar-item has-text-primary has-text-weight-bold">Rent a
            Container</a>
          <template v-for="page in pages">
            <div v-if="page.pages" :key="page.slug" class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link">
                {{ page.name }}
              </a>
              <div class="navbar-dropdown">
                <page-link v-for="page in page.pages" :key="page.slug" :page="page" />
              </div>
            </div>
            <page-link v-else :page="page" :key="page.slug" />
          </template>
        </div>
        <hr class="navbar-divider">
        <a href="#" @click="inventoryModalActive = !inventoryModalActive" class="navbar-item">Find Inventory</a>
        <a :href="`tel:${organizationPhone || phoneNumber}}`" class="navbar-item has-text-primary">{{ formatPhoneNumber(organizationPhone || phoneNumber) }}</a>
        <div class="navbar-item">
          <quote-summary-dropdown v-if="quote" />
          <button v-else class="button is-primary is-fullwidth is-small-tablet" data-vue-init="lead-flow" data-props='{ "gtag": "get_quote"}'>
            Get Quote
          </button>
        </div>
      </div>
    </nav>

    <modal v-if="quote" v-show="showMobileQuote" @close="showMobileQuote = false">
      <quote-summary slot="body" @hide="showMobileQuote = false" />
    </modal>

    <zipcodeModal v-if="inventoryModalActive" @close="inventoryModalActive = false" :solutionSlug="solutionSlug" :isTypeChange="true"/>
    <zipcodeModal v-if="modalOpen" @close="modalOpen = false" :solutionSlug="solutionSlug" />
  </header>
</template>

<script>
import { mapState } from 'vuex'
import QuoteSummaryDropdown from '../quote/summary/dropdown.vue'
import QuoteSummary from '../quote/summary/index.vue'
import PageLink from './page-link.vue'
import Modal from 'shared/components/modal.vue'
import ZipcodeModal from '../location-search/modal.vue'
import { formatPhoneNumber } from '../../shared/utils'

export default {
  components: {
    PageLink,
    QuoteSummaryDropdown,
    QuoteSummary,
    Modal,
    ZipcodeModal
  },
  props: {
    organizationPhone: String
  },
  data() {
    return {
      navOpened: false,
      isActive: false,
      showMobileQuote: false,
      inventoryModalActive: false,
      modalOpen: false,
      solutionSlug: '',
      pages: [
        // { name: 'Solutions', slug: '/solutions', pages: [
        //   { name: 'Container Sales', slug: '/solutions/container-sales' },
        //   { name: 'Container Rentals', slug: '/solutions/container-rentals' },
        //   { name: 'Refrigerated Containers', slug: '/solutions/refrigerated-containers' },
        //   { name: 'Portable Offices', slug: '/solutions/portable-offices' },
        //   { name: 'Container Modifications', slug: '/solutions/container-modifications' },
        //   { name: 'Container Alliance Accessories', slug: '/solutions/accessories' }
        // ]},
        {
          name: 'Resources', slug: '#resources', pages: [
            { name: 'Container Buyers Guide', slug: '/containers-for-sale' },
            { name: 'Refrigerated Buyers Guide', slug: '/refrigerated-containers-for-sale' },
            { name: 'Container Rental Guide', slug: '/containers-for-rent' },
            { name: 'Refrigerated Rental Guide', slug: '/refrigerated-containers-for-rent' },
            { name: 'Informative Articles', slug: '/informative-articles' },
            { name: 'Delivery Information', slug: '/delivery-information' },
            { name: 'Container Conditions', slug: '/container-conditions' },
            { name: 'Container Specifications', slug: '/shipping-container-specifications' },
            { name: 'Container Modifications', slug: '/container-modifications' }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState('shared', ['phoneNumber', 'wizardEnabled']),
    ...mapState('quote', ['quote']),
    navClass() {
      return this.navOpened ? 'is-active' : null
    }
  },
  methods: {
    toggleNav() {
      this.navOpened = !this.navOpened
    },
    openModal(slug) {
      this.modalOpen = true
      this.solutionSlug = slug
    },
    formatPhoneNumber
  }
}
</script>

<style lang="scss" scoped>
.navbar-brand {
  padding: 0.5rem 1rem;
}

.navbar-tablet-phone {
  position: absolute;
  top: 31px;
  right: 94px;
}

.header .navbar-menu {
  position: relative;
  z-index: 11;
}

#site-logo {
  display: block;
  background: transparent url('/images/logo-container-alliance.svg') center center no-repeat;
  background-size: contain;
  font-size: 0 !important;
  width: 150px;
  height: 48px;
  padding: 0;
  margin: 0.5rem;
}


@media (max-width: 1024px) {
  .header {
    .mobile-overlay {
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 11;
    }

    &.is-active .mobile-overlay {
      pointer-events: all;
      visibility: visible;
      opacity: 1;
    }
  }
}

@media (max-width: 874px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
  }

  .header.is-active {
    height: 100%;
    overflow: scroll;
  }
}


.inventory-search-box {
  margin: 50px 0;
  color: #000;
  min-height: 300px;
}
</style>

<style lang="scss">
@media (min-width: 1025px) {
  .navbar-mobile-actions {
    display: none !important;
  }
}

.navbar-mobile-actions {
  position: absolute;
  top: 19px;
  right: 80px;
  margin: 0;

  a {
    padding: 3px 6px;
  }

  @media (max-width: 580px) {
    right: 60px;

    a {
      padding: 3px;
      font-size: 0.75rem !important;
    }
  }
}
</style>
