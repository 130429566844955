<template>
  <form class="address-form mt-5">
    <div class="is-flex is-justify-content-space-between">
      <label class="container mr-3">
        <span class="span"><b class="required-star">*</b> Street</span>
        <validated-input v-model="deliveryAddress.line1" name="Street" rules="required" />
      </label>
      <!-- <label class="container">
        <span class="span"><b class="required-star">*</b> Street2</span>
        <validated-input v-model="deliveryAddress.line2" name="Street2" />
      </label> -->
    </div>
    <div class="is-flex is-justify-content-space-between">
      <label class="container mr-3">
        <span class="span"><b class="required-star">*</b> City</span>
        <validated-input v-model="deliveryAddress.locality" name="City" rules="required" />
      </label>
      <label class="container state select mr-3">
        <span class="span"><b class="required-star">*</b> State</span>
        <address-state-select v-model="deliveryAddress.region" class="input custom-select"/>
      </label>
      <label class="container zip">
        <span class="span"><b class="required-star">*</b> Zip</span>
        <input v-model="deliveryAddress.postalCode" name="Zip" readonly class="input"/>
      </label>
    </div>
    <!-- <label class="container">
      <span class="span">Company Name <span class="optional-flag">(optional)</span></span>
      <input v-model="customer.companyName" type="text" name="Company" class="input" />
    </label> -->
  </form>
</template>

<script>
import { mapState } from 'vuex'
import Step from './../mixin'
import AddressStateSelect from 'shared/components/state-select.vue'
export default {
  mixins: [Step],
  components: {
    AddressStateSelect
  },
  data () {
    return {
      deliveryAddress: {},
      customer: {}
    }
  },
  computed: {
    ...mapState('quote', ['quote'])
  },
  mounted() {
    if(!this.quote || !this.quote.deliveryAddress) {
      if (this.lead) {        
        this.deliveryAddress = {} 
        this.deliveryAddress.postalCode = this.lead.zipcode.id || this.lead.zipcodeId
        this.deliveryAddress.countryCode = 'US'
        this.customer.companyName = this.quote.customer.companyName || ''
      }
    } else {
      this.deliveryAddress = {} 
      this.deliveryAddress = this.quote.deliveryAddress
      this.deliveryAddress.postalCode = this.lead.zipcodeId
      this.deliveryAddress.countryCode = 'US'

    }
  }
}
</script>

<style scoped lang="scss">
  .span {
    color: #061627;
    font-weight: 600;
    font-size: 0.75rem;
  }
  .state.select:not(.is-multiple):not(.is-loading)::after {
    top: 2.5rem;
    margin-top: -2px;
  }
</style>