<template>
  <section>
    <figure class="image is-16by9">
      <iframe
        :src="`https://www.youtube.com/embed/${getVideoID()}`"
        class="has-ratio"
        width="640"
        height="360"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      >
      </iframe>
    </figure>
    <figcaption v-if="block.videoCaption">
      {{ block.videoCaption }}
    </figcaption>
  </section>
</template>
<script>
export default {
  props: {
    block: {
      type: Object
    }
  },
  methods: {
    getVideoID() {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = this.block.videoUrl.match(regExp)
      return (match&&match[7].length==11)? match[7] : false
    }
  }
}
</script>
