<template>
  <div>
    <fieldset class="fieldset">
     <p class="neutral-gray has-text-weight-semibold text-sm mb-2">
        Rate the below factors based on importance to you when considering a container purchase.               
      </p>
      <p class="neutral-gray has-text-weight-semibold text-sm mb-5">
        *You will be only selecting grades, not the final product
      </p>
       <div class="field">
        <div class="control radio-list">
          <a 
            v-for="(label, value) in stepOptions" 
            :key="value" 
            @click="chooseCondition(value)" 
            class="select-card is-flex is-justify-content-space-between	is-align-items-center neutral-gray is-size-7 mb-3"
            :class="updates.condition == value && 'active'"
          >
            <img :src="'/images/'+value+'.png'" />
            <span class="condition-title neutral-black has-text-weight-semibold text-sm">{{ label }}</span>
            <div class="is-flex-tablet">
              <div class="space-right is-flex-direction-column is-align-content-flex-start">
                <span class="is-size-7 has-text-gray">Condition</span>
                <div class="is-flex">
                  <icon v-for="(index) in Array.from(Array(5).keys())" :key="index" :type="index + 1 <= rates(value, 'condition') ? 'filled-star' : 'unfilled-star'"/>
                </div>
              </div>
              <div class="space-right is-flex-direction-column is-align-items-end">
                <span class="is-size-7 has-text-gray">Longevity</span>
                <div class="is-flex">
                  <icon v-for="(index) in Array.from(Array(5).keys())" :key="index" :type="index + 1 <= rates(value) ? 'filled-star' : 'unfilled-star'" />
                </div>
              </div>
              <div class="is-flex-direction-column is-align-items-end">
                <span class="is-size-7 has-text-gray">Cost</span>
                <div class="is-flex">
                  <icon v-for="(index) in Array.from(Array(5).keys())" :key="index" :type="index + 1 <= rates(value) ? 'filled-dollar' : 'unfilled-dollar'" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { DynamicStep } from './mixin'
import Icon from './helpers/icon.vue'
export default {
  mixins: [ DynamicStep('condition') ],
  components: {
    Icon
  },
  methods: {
    rates(condition, type = '') {
      switch (condition) {
      case 'one_trip':
        return 5
      case 'refurbished':
        return 4
      case 'cargo_worthy':
        return 3
      case 'wind_water_tight':
        return type == 'condition' ? 2 : 1
      }
    },
    chooseCondition(value) {
      this.updates.condition = value
    }
  }
}
</script>

<style lang="scss" scoped>
  .condition-title {
    width: 5.8rem;
  }  
  .checkout-custom-modal .select-card {
    text-align: left;
  }
  .select-card img {
    @media (max-width: 720px) {
      width: 60px;
    }
  }
  .space-right {
    margin-right: 3rem;
    @media (max-width: 720px) {
      margin-right: 0.25rem;
    }
  }
</style>