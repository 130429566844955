
<section>
  <figure class="image is-16by9">
    <iframe
      :src="`https://www.youtube.com/embed/${getVideoID()}`"
      class="has-ratio"
      width="640"
      height="360"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    >
    </iframe>
  </figure>
  <figcaption v-if="block.videoCaption">
    {{ block.videoCaption }}
  </figcaption>
</section>
