<template>
  <select v-model="localValue" name="state" @change="update">
    <option v-if="include_blank" :value="null"></option>
    <option v-for="state in states" :value="state.abrv" :key="state.abrv">
      {{ state.name }}
    </option>
  </select>
</template>

<script>
export default {
  props: [
    'value',
    'include_blank'
  ],
  data () {
    return {
      states: [],
      localValue: null
    }
  },
  async created () {
    const result = await this.$store.dispatch('quote/states.load')
    this.states = result
    this.localValue = this.value
    this.$emit('input', this.localValue)
  },
  methods: {
    update () {
      this.$emit('input', this.localValue)
    }
  }
}
</script>