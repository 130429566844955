import Modal from 'shared/components/modal.vue'

export default {
  components: {
    Modal
  },
  props: {
    showCloseButton: {
      default: true,
      type: Boolean,
      required: false
    }
  },
  methods: {
    close() {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    }
  }
}
