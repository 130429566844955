
<div>
  <article v-if="errors && errors.length" class="message is-danger is-size-7-mobile">
    <div class="message-body">{{ errors[0].message }}</div>
  </article>
  <fieldset class="fieldset">
    <p class="neutral-gray has-text-weight-semibold text-sm mb-5">
      Confirm your contact information and receive this quote as a printable PDF file directly in your inbox.
    </p>                
    <div class="columns mb-0">
      <div class="column field">
        <label class="label span">Zip Code</label>
        <input v-model="updates.zipcode" name="Zip" readonly class="input"/>          
      </div>
      <div class="column field">
        <label class="label span">Phone</label>
        <validated-input
          v-model="updates.phone"
          name="Phone"
          rules=""
        />
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column field">
        <label class="label span">First Name</label>
        <validated-input
          v-model="updates.firstName"
          name="First Name"
          rules="required"
        />
      </div>
      <div class="column field">
        <label class="label span">Last Name</label>
        <validated-input
          v-model="updates.lastName"
          name="Last Name"
          rules="required"
        />
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column field">
        <label class="label span">Email</label>
          <validated-input
            v-model="updates.email"
            name="Email"
            rules="required|email"
          />   
      </div>     
    </div>
    <div class="columns">
      <div class="column field">
        <label class="label span">Notes <span class="optional-flag">Optional</span></label>
        <textarea
         v-model="updates.notes"
          rows="8"
          class="textarea"
          placeholder="Type your notes or questions here."  
        />
      </div>
    </div>
  </fieldset>
</div>
