<template>
  <span>{{ organizationNumber || phoneNumber }}</span>
</template>

<script>
import  { mapState } from 'vuex'
export default {
  props: {
    organizationNumber: String
  },
  computed: mapState('shared', [ 'phoneNumber' ])
}
</script>
