
<div class="mb-5">
  <div v-if="(lead && !priceBookEntry) || organization_id != organization.id">
    <article class="message is-warning">
      <div class="message-body" v-if="organization">
        <p><b>{{ organization.name }}</b> is our partner company for zipcode <b>{{ lead ? lead.zipcodeId : zipcode }}</b>.</p>
        <p>Give them a call to get a quote and reserve the next unit available today!</p>
        <p><b>{{ formatPhoneNumber(organization.phone) }}</b></p>
      </div>
    </article>
    <button class="button is-secondary zipcode-button has-text-weight-semibold neutral-gray" @click="changeZipCode('livingPrice')">
      <span>Change Zip Code</span>
    </button>
  </div>
  <div class="pricebook-block" v-else>
    <h3 class="price-heading has-text-weight-bold mb-5">
      <span v-if="priceVisible" class="neutral-black">
        <span v-if="!isPrivate" class="price-number mr-1 has-text-weight-bold link-color">
          {{ price }}<span v-if="isRental" class="is-size-7">/mo</span>
        </span>
        for
        <span class="is-6">{{ lead ? lead.zipcodeId : zipcode }}</span>
        <span class="neutral-gray mr-2 is-6">
          + Delivery
          <span v-if="isRental">
            & Pickup
          </span>
        </span>
        <span class="has-text-weight-bold has-text-danger is-6" v-if="priceBookEntry">
          {{ priceBookEntry.estimatedDeliveryTime || 'In Stock' }}
        </span>
      </span>
    </h3>
    <!--<div v-if="priceVisible" class="is-flex mb-5">
      <label class="radio-label is-flex">
        <input type="radio" value="positive">
        <span class="ml-1 neutral-gray is-6">Rental</span>
      </label>
      <label class="radio-label ml-2 is-flex">
        <input type="radio" value="positive">
        <span class="ml-1 neutral-gray is-6">Purchase</span>
      </label>
      <span class="ml-1 is-flex">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25Z" stroke="#194985" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.3125 10.3125H11V15.125H11.6875" stroke="#194985" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.8281 8.25C11.3977 8.25 11.8594 7.78829 11.8594 7.21875C11.8594 6.64921 11.3977 6.1875 10.8281 6.1875C10.2586 6.1875 9.79688 6.64921 9.79688 7.21875C9.79688 7.78829 10.2586 8.25 10.8281 8.25Z" fill="#194985"/>
        </svg>
      </span>

    </div>-->
    <div class="is-flex">
      <div class="mr-5 h-100" v-if="priceVisible && priceBookEntry && lead && lead.id && organization">
        <quantity-control v-model="quantity" />
      </div>
      <span v-if="(lead && priceBookEntry) || !lead">
        <add-to-quote :price-book-entry="priceBookEntry" :quantity="quantity" :changeZipCode="changeZipCode" :brand_color="brand_color"/>
      </span>
    </div>
  </div>
</div>
