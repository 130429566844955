<template>
  <div class="cover-container">
    <div class="text-container">
      <div>
        <h1 class="title blue pl-4">The Shipping</h1>
        <div class="title-grey-background pl-4 mb-3 mt-3">
          <h1 class="title">Container Company</h1>
        </div>
        <h1 class="title blue pl-4">With The Largest Provider Network In the U.S.</h1>
        <p class="description mb-5 pr-5 pl-4">
          With providers nationwide, Container Alliance offers an extensive amount of knowledge to help you find the best container solution — with prices you won't find anywhere else.
        </p>
        <div class="btn-shipping-containers">
          <button class="button is-medium is-primary" @click="openModal">
            <span>View our shipping containers</span>
            <svg
              class="ml-4"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 16H27"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18 7L27 16L18 25"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <zipcodeModal v-if="modalOpen" @close="modalOpen = false" />
        </div>
      </div>
    </div>
    <div class="is-hidden-touch hero-image">
      <img src="/CA-train-track.jpeg" style="width: 100%"/>
    </div>
  </div>
</template>

<script>
import ZipcodeModal from '../location-search/modal.vue'
export default {
  components: {
    ZipcodeModal
  },
  data() {
    return {
      modalOpen: false
    }
  },
  methods: {
    openModal() {
      this.modalOpen = true
    }
  }
}
</script>

<style lang="scss" scoped>
.cover-container {
  display: flex;
  padding-left: 3rem;

  @media (max-width: 1215px) {
    padding: 1.5rem;
  }
  @media (min-width: 1215px) {
  }
}

.title-grey-background {
  background: #363e4a;
  padding-bottom: 8px;
}

.title {
  font-size: 3rem;
  font-weight: 600;
  color: #fff;

  @media (max-width: 365px) {
    font-size: 2rem;
  }
}

.blue {
  color: #174984;
}

.description {
  font-size: 1rem;
  font-weight: 600;
  color: #767676;

  @media (max-width: 365px) {
    font-size: 0.75rem;
  }
}

.btn-shipping-containers {
  padding-left: 1rem;
  
  button {
    font-weight: 700;
    font-size: 1.125rem;
  }

  @media (max-width: 390px) {
    button {
      width:100%;

      svg {
        display: none;
      }
    }
  }
}

@media (max-width:390px){
   .btn-shipping-containers {
      padding-left: 0 ;
    }
}
.text-container {
  display: flex;
  align-items: center;

  @media (max-width: 1215px) {
    width: 100%;
  }

  @media (max-width: 365px) {
    display: block;
  }
}

.hero-image {
  min-width: 576px;
  min-height: 416px;
  width: 70%;
  @media (min-width: 1920px) {
    min-width: 720px;
    min-height: 520px;
  }
}
</style>
