<template>
  <div class="checkout-form-container checkout-form">
    <span class="is-size-4 neutral-black has-text-weight-semibold ">My Info</span>
    <div class="container-title-company-name">
      <div class="container check-info my-5 mr-3" >
        <icon type="check-item" class="check-item" />
        <span class="mb-2">Tell us about yourself.</span>
      </div>
    </div>
    <section class="my-body-section mt-5">
      <div>
        <div class="is-flex is-justify-content-space-between">
          <label class="container mr-3">
            <span class="span"><b class="required-star">*</b> First Name</span>
            <validated-input v-model="updates.firstName" name="First name" />
          </label>
          <label class="container">
            <span class="span"><b class="required-star">*</b> Last Name</span>
            <validated-input v-model="updates.lastName" name="Last name" rules="required" />
          </label>
        </div>
        <div class="is-flex flex is-justify-content-space-between">
          <label class="container mr-3">
            <span class="span"><b class="required-star">*</b> Email</span>
            <validated-input v-model="updates.email" name="Email" rules="required|email" />
          </label>
          <label class="container">
            <span class="span"><b class="required-star">*</b> Phone</span>
            <validated-input v-model="updates.phone" name="Phone" :rules="{ required: true, regex: /^\+?[0-9()-]*$/ }" />
          </label>
        </div>
        <div class="is-flex flex is-justify-content-space-between">
          <label class="container mr-3" >
            <span class="span">Company Name <span class="optional-flag">(optional)</span></span>
            <validated-input v-model="customer.companyName" name="Company" :rules="{ required: false }" />
          </label>
          <label class="container" >
          </label>
        </div>
      </div>
    </section>   
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Icon from './icon.vue'
import Step from '../../flow/steps/mixin'

export default {
  mixins: [Step],
  data () {
    return {
      customer: {}
    }
  },
  components: {
    Icon
  },
  computed: {
    ...mapState('quote', ['quote'])
  },
  props: {
    showPrice: Boolean
  }, 
  mounted () {
    if (this.quote) {
      this.updates = this.quote.customer
    } else if (this.lead) {
      const { firstName, lastName, email, phone } = this.lead
      //this.customer = {}
      this.updates = {
        ...this.updates,
        firstName,
        lastName,
        email,
        phone
      }
    }
  },
  methods: {   
    async save() {
      await this.updateCustomerInfo()
      await this.updateLeadInfo()
    },   
    updateCustomerInfo() {
      return this.$store.dispatch('quote/quote.save', {
        eventName: 'customer.update',
        input: {
          customer: {
            ...this.quote.customer,
            firstName: this.updates.firstName,
            lastName: this.updates.lastName,
            phone: this.updates.phone,
            email: this.updates.email            
          }
        }
      })
    },
    async updateLeadInfo() {
      return await this.$store.dispatch('quote/lead.save', {...this.lead, ...this.updates, id: this.lead.id})
    }
  }
}
</script>

<style>
  @import '../../flow/styles/checkout-form.scss';
</style>
<style scoped lang="scss">
  .check-info {
    display: flex;
    align-items: center;

    .check-item {
      margin-right: 12px;
    }

    span {
      color: #767676;
      font-size: 0.875rem;
      font-weight: 600;
    }
  }
  .container-title-company-name {
    display: flex; 
    flex-direction: row; 
    justify-content: space-between;
    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

</style>