
<div class="payment-form">   
  <label>
    <span class="span"><b class="required-star">*</b> Card Number</span>
    <div id="card-number-element" ref="card" class="stripe-input"></div>
  </label>
  <div class="is-flex mt-4">
    <label class="container mr-3">
      <span class="span"><b class="required-star">*</b> Expiry Date</span>
      <div id="card-expiry-element" ref="cardExpiry" class="stripe-input"></div>
    </label>
    <label class="container">
      <span class="span"><b class="required-star">*</b> CVC</span>
      <div id="card-cvc-element" ref="cardCvc" class="stripe-input"></div>
    </label>
  </div>
  <div class="stripe-errors mt-2 has-text-danger">{{stripeValidationError}}</div>
</div>
