<template>
  <transition name="modal">
    <div class="modal is-active">
      <div class="modal-background" @click="close()"></div>
      <div class="box modal-card is-paddingless">
        <div v-if="$slots.prompt" class="modal-close-prompt">
          <div class="prompt is-vcentered">
            <div class="propt-content">
              <slot name="prompt"></slot>
              <br/>
              <div class="buttons is-centered">
                <button @click="$emit('confirm', true)" class="button is-danger">Yes</button>
                <button @click="$emit('confirm', false)" class="button is-primary">No</button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="$slots.header" class="modal-card-head">
          <slot name="header"></slot>
        </div>
        <div v-if="$slots.body" 
            class="modal-card-body" 
            :style="modalCardBodyStyle">
          <slot name="body"></slot>
        </div>
        <div v-if="$slots.actions" class="modal-card-body">
          <slot name="actions"></slot>
        </div>
        <div v-if="$slots.footer" class="modal-card-foot">
          <slot name="footer"></slot>
        </div>
        <button v-if="showCloseButton" id="modal-close-btn"  class="modal-close is-large" aria-label="close" @click="close()"></button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  methods: {
    close () {
      this.$emit('close')
    }
  },
  props: {
    showCloseButton: {
      default: true,
      type: Boolean,
      required: false
    },
    bodyBackgroundColor: {
      default: '#fff',
      type: String,
      required: false
    },
    bodyMinHeight: {
      default: '',
      type: String,
      required: false
    }
  },
  mounted: function () {
    // hacky? maybe...
    document.body.appendChild(this.$el)
    this.popupItem = this
    document.addEventListener('keydown', (e) => {
      if (e.keyCode == 27) {
        this.close()
      }
    })
  },
  computed: {
    modalCardBodyStyle() {
      const isDesktop = window.innerWidth >= 1024
      const styles = { backgroundColor: this.bodyBackgroundColor }

      if (isDesktop && this.bodyMinHeight) {
        styles.minHeight = this.bodyMinHeight + 'px'
      }

      return styles
    }
  }
}
</script>

<style lang="scss">
 #modal-close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  background: hsl(0deg, 0%, 96%);

  &::before,
  &::after {
    background: black;
  }

  &:hover {
    background: hsl(0deg, 0%, 96%);
    opacity: 0.7;
  }
}

.modal {
  transition: all 0.3s ease;
  z-index: 1000 !important;
}

.modal-enter,
.modal-leave-active {
  opacity: 0;
}

.modal-leave-active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-close-prompt {
  flex: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  background: #fff6f8;
  display: flex;
  align-items: center;
  justify-content: center;

  .prompt {
    text-align: center;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
  }

  .propt-content {
    display: block;
    width: 100%;
    text-align: center;
  }
}

// Custom scrollbar styling for WebKit-based browsers
.modal-card-body {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #C1C1C1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #7D7D7D;
  }
}
</style>
