
<span>
  <a :href="`https://www.facebook.com/sharer.php?u=${ url }`" target="blank" rel="noopener noreferrer">
    <span class="icon">
      <i data-feather="facebook" width="1.5rem" height="1.5rem"></i>
    </span>
  </a>
  <a :href="`https://twitter.com/intent/tweet?url=${ url }&text=${ title }&amp;hashtags=shippingcontainers`"
     target="blank"
     rel="noopener noreferrer">
    <span class="icon">
      <i data-feather="twitter" width="1.5rem" height="1.5rem"></i>
    </span>
  </a>
  <a :href="`https://www.linkedin.com/shareArticle?url=${ url }`" target="blank" rel="noopener noreferrer">
    <span class="icon">
      <i data-feather="linkedin" width="1.5rem" height="1.5rem"></i>
    </span>
  </a>
  <a :href="url" @click.prevent="copyToClipBoard()" :data-href="url" target="blank" rel="noopener noreferrer">
    <span v-show="linkCopied" class="icon show-message" data-title="Copied!">
      <i data-feather="check" width="1.5rem" height="1.5rem"></i>
    </span>
    <span v-show="!linkCopied" class="icon">
      <i data-feather="link" width="1.5rem" height="1.5rem"></i>
    </span>
  </a>
</span>
