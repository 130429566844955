<template>
  <form @submit.prevent="search">
    <fieldset>
      <div class="field dropdown is-active">
        <div class="dropdown-trigger">
          <div class="field has-addons">
            <div class="control is-expanded">
              <input v-model="query" :class="{ 'is-danger': error }" class="input" type="search"
                :placeholder="inputPlaceholder" />
            </div>
            <div class="control">
              <button v-if="error" @click.prevent="resetSearch()" class="button is-danger">
                Reset
              </button>
              <button v-else :class="{ 'is-loading': loading }" :disabled="isButtonDisabled" type="submit"
                class="button" :style="'background-color: ' + brand_color + '!important; color: ' + brand_color === 'white' ? '#767676' : 'white'">
                {{ submitLabel }}
              </button>
            </div>
          </div>
          <div v-if="error" class="help" :style="errorStyle">
            {{ error }}
            <b><a @click.prevent="resetSearch()">Try Again</a></b>
          </div>
        </div>
        <div v-if="!coordinates && cities.length" class="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <p class="dropdown-item"><b>We found mulitple results...</b></p>
            <a v-for="city in cities" :key="city.id" :href="getPath(city.node)" class="dropdown-item">
              {{ city.node.name }}, {{ city.node.stateId | upcase }}
            </a>
          </div>
        </div>
      </div>
      <geolocate @success="searchByCoordinates" v-if="showSearchByGeolocate" />
    </fieldset>
  </form>
</template>

<script>
import ahoy from 'shared/ahoy'
import Geolocate from './geolocate.vue'
import { zipQuery, citySearchQuery, geoSearchQuery } from 'shared/graphql/queries'
import { graphqlQuery } from 'shared/graphql'
import { cityPath } from '../../shared/city-helper'
import { partnerPath } from '../../shared/partner-helper'
import { mapState } from 'vuex'
const ZIP_REGEX = /^\d{5}$/
const redirectToCity = (city) => window.location.href = cityPath(city)
const redirectToPartner = (organization_id) => window.location.href = partnerPath(organization_id)
export default {
  components: {
    Geolocate
  },
  props: {
    showSearchByGeolocate: {
      type: Boolean,
      required: false,
      default: true
    },
    brand_color: {
      type: String,
      required: false,
      default: () => 'white'
    },
    organizationId: {
      type: Number,
      required: false
    },
    inZipcodeModal: {
      type: Boolean,
      required: false,
      default: false
    },
    submitLabel: {
      type: String,
      required: false,
      default: () => 'Search'
    },
    inputPlaceholder: {
      type: String,
      required: false,
      default: () => 'City or Zip code to change location'
    },
    errorStyle: {
      type: Object,
      required: false,
      default: () => {}
    },
    queryProp: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  data() {
    return {
      loading: 0,
      error: null,
      coordinates: null,
      cities: [],
      query: this.queryProp
    }
  },
  filters: {
    upcase(string) {
      return string.toUpperCase()
    }
  },
  computed: {
    ...mapState('quote', ['lead']),
    isButtonDisabled() {
      // 5 digits, or at least 3 characters
      return !/^(\d{5})|(\D{3,})$/i.test(this.query)
    }
  },
  watch: {
    query() {
      if (this.query === '') {
        this.error = null
        this.cities = []
      } else if (this.error && this.query !== this.lastQuery) {
        this.error = null
      }
    }
  },
  methods: {
    cityPath,
    getPath(city) {
      if (city.organization && city.organization.id) {
        return partnerPath(city.organization.id)
      }
      return cityPath(city)
    },
    searchByCoordinates(coordinates) {
      this.coordinates = coordinates
      this.query = ''
      this.search()
    },
    resetSearch() {
      this.query = ''
    },
    getRequestBody() {
      if (ZIP_REGEX.test(this.query)) {
        return {
          query: zipQuery,
          variables: {
            id: this.query
          }
        }
      } else if (this.coordinates) {
        return {
          query: geoSearchQuery,
          variables: {
            coordinates: this.coordinates
          }
        }
      } else {
        return {
          query: citySearchQuery,
          variables: {
            query: this.query
          }
        }
      }
    },
    async trackSearch() {
      ahoy.track('location.search', {
        coordinates: this.coordinates,
        query: this.query
      })
    },
    async search() {
      this.cities = []
      this.loading++
      this.lastQuery = this.query = this.query.trim()
      try {
        this.trackSearch()
        const result = await graphqlQuery(this.getRequestBody())
        this.loading = 0
        return this.handleResult(result.data || {})
      } catch (e) {
        // handle error
        console.warn(e)
        this.error = 'There was an error while searching.'
        this.loading = 0
      }
    },
    async handleResult(data) {
      let { zipcode, zipcodes, cities } = data

      if (!zipcode && zipcodes && zipcodes.edges.length === 1) {
        zipcode = zipcodes.edges[0].node
      }
      if (zipcode) {
        if (this.lead) {
          await this.$store.dispatch('quote/lead.zipcode.update', {lead: this.lead, zipcode: zipcode.id})
        } else {
          this.$store.commit('shared/zipcode.set', zipcode.id)
        }
        if (zipcode.city.organization && zipcode.city.organization.id) {
          if (this.inZipcodeModal && zipcode.city.organization.id == this.organizationId) {
            return window.location = window.location.href.split('?')[0]
          } else {
            return redirectToPartner(zipcode.city.organization.id)
          }
        } else {
          return redirectToCity(zipcode.city)
        }
      } else if (cities && cities.edges.length) {
        if (cities.edges.length === 1) {
          if (cities.edges[0].node.organization && cities.edges[0].node.organization.id) {
            return redirectToPartner(cities.edges[0].node.organization.id)
          } else {
            return redirectToCity(cities.edges[0].node)
          }
        } else {
          this.cities = cities.edges
        }
      } else {
        this.error = 'Sorry, we couldn\'t find that location.'
      }
      this.loading = 0
    }
  }
}
</script>

<style scoped>
.dropdown,
.dropdown-trigger,
.dropdown-menu {
  width: 100%;
}

.input {
  width: 100%;
}

.help,
.help a {
  color: inherit;
}
.button:disabled {
  background-color: #8fa3c3 !important;
  opacity: 1 !important;
  color: #fff;
}
</style>
