
<tbody class="">
  <tr class="has-text-weight-semibold is-borderless">
    <td class="has-text-centered">
      <span class="control-wrapper" v-if="product.classification !== 'transportation'">
        <quantity-control
          v-model="quantity"
          :minimum="0"
          :disabled="loading"
          class="is-small"
          @input="handleQuantityChange" />
      </span>
      <span v-else class="py-1 px-2 delivery-quantity">{{ item.quantity }}</span>
    </td>
    <td>
      <template v-if="product.classification !== 'transportation'">
        <a :href="`/products/${product.slug}`">{{ product.name }}</a>
      </template>
      <template v-else>
        {{ product.name }}
      </template>
    </td>
    <td v-if="showPrice" class="has-text-right">{{ dollars(item.priceCents) }}</td>
  </tr>
  </tbody>
