<template>
  <div class="payment-form">   
    <label>
      <span class="span"><b class="required-star">*</b> Card Number</span>
      <div id="card-number-element" ref="card" class="stripe-input"></div>
    </label>
    <div class="is-flex mt-4">
      <label class="container mr-3">
        <span class="span"><b class="required-star">*</b> Expiry Date</span>
        <div id="card-expiry-element" ref="cardExpiry" class="stripe-input"></div>
      </label>
      <label class="container">
        <span class="span"><b class="required-star">*</b> CVC</span>
        <div id="card-cvc-element" ref="cardCvc" class="stripe-input"></div>
      </label>
    </div>
    <div class="stripe-errors mt-2 has-text-danger">{{stripeValidationError}}</div>
  </div>
</template>

<script>

let stripe, elements, card, cardExpiry, cardCvc

const initStripe = () => {
  if (typeof(Stripe) === 'function') {
    /* global Stripe */
    stripe = Stripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)
    elements = stripe.elements()
    return true
  }
}

export default {
  mounted () {
    this.initWithTimeout()
  },
  data() {
    return {
      stripeValidationError: ''
    }
  },
  methods: {
    initWithTimeout () {
      setTimeout(() => {
        if (initStripe()) this.initElements()
        else this.initWithTimeout()
      }, 10)
    },
    setValidationError(event) {
      this.stripeValidationError = event.error ? event.error.message : ''
    },
    initElements () {
      const style = {
        base: {
          color: '#313b3f',
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '0.75rem',
          fontWeight: 'bold',
          '::placeholder': {
            color: '#777777'
          },
          ':-webkit-autofill': {
            color: '#32325d'
          }
        },
        invalid: {
          color: '#fe3860',
          iconColor: '#fe3860',
          ':-webkit-autofill': {
            color: '#fe3860'
          }
        }
      }


      card = elements.create('cardNumber', {style})
      cardExpiry = elements.create('cardExpiry', {style})
      cardCvc = elements.create('cardCvc', {style})

      card.mount(this.$refs.card)
      cardExpiry.mount(this.$refs.cardExpiry)
      cardCvc.mount(this.$refs.cardCvc)

      card.on('change', this.setValidationError)
      cardExpiry.on('change', this.setValidationError)
      cardCvc.on('change', this.setValidationError)
    },

    tokenize () {
      return stripe.createToken(card)
    }

  }
}
</script>

<style lang="scss" scoped>
  .checkout-form {
    input,
    .StripeElement {
      padding: 16px;
      background-color: white;
      border-radius: 4px;
      border: 1px solid transparent;
      font-weight: bold;
    }
    .stripe-input {
      border: 1px solid #c5c5c5;
      width: 100%;
      padding: 0.75rem 0.625rem;
      border-style: solid;
      color: #313b3f;
      border-radius: 2px;
      height: 2.5rem;
      outline: none;
    }
    input:focus,
    .StripeElement--focus {
      box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
      border-color: #fe3860;
    }

    .StripeElement--webkit-autofill {
      background-color: #fefde5 !important;
    }
    .payment-form {
      margin-bottom: 22px;
    }
    .stripe-errors {
      font-size: 0.75rem;
    }
  }
</style>
