
<article>
  <div class="columns is-vcentered is-mobile p-3 has-background-white recommended-product">
    <div class="is-hidden-mobile column is-3 p-0">
      <figure v-if="product.primaryPhoto" class="image product-figure">
        <img :src="imageUrl(product.primaryPhoto.key, { resize: { width: 180, height: 130 } } )">
      </figure>
    </div>
    <div class="column">
      <h5 class="product-title text-sm has-text-weight-semibold is-size-6-mobile mb-1 neutral-black">
        {{ product.name }}
      </h5>
      <!--<p class="is-hidden-mobile subtitle is-6 mb-2 neutral-gray">
        {{ product.code }}
      </p>-->
      <p v-if="pricingActive" class="text-sm neutral-gray is-size-7-mobile mb-1">
        <span v-if="product.classification === 'container_rental'">
          <!--from-->
          {{ dollars(priceBookEntry.priceCents) }}/month
          <!--<i>&plus; Tax, Delivery  &amp; Pickup</i>-->
        </span>
        <span v-else>
          <!--from-->
          {{ dollars(priceBookEntry.priceCents) }}
          <!--<i class="is-size-7">&plus; Tax &amp; Delivery</i>-->
        </span>
      </p>
      <div class="is-flex is-justify-content-space-between">
        <a :href="'/solutions/container-sales/' + product.slug" class="text-sm link-color is-size-7-mobile is-underlined mr-1">
          View Product Page
        </a>
        <quote-quantity :price-book-entry="priceBookEntry" />
      </div>
    </div>    
  </div>
</article>
