<template>
  <confirmation v-if="lead" :lead="lead" @close="close"></confirmation>
  <modal v-else @close="close">
    <div slot="header">
      <h4 class="title is-4">Contact Us</h4>
      <p class="subtitle is-size-6">
        Please let us know more information and a representative will get back to you shortly.
      </p>
    </div>
    <div slot="body">
      <lead-form class="lead-form" :product="detail" @success="handleSuccess" />
    </div>
  </modal>
</template>

<script>
import hasModal from 'shared/mixins/has-modal'
import LeadForm from '../lead-form/form.vue'
import Confirmation from './confirmation.vue'
export default {
  mixins: [hasModal],
  components: {
    LeadForm,
    Confirmation
  },
  props: {
    detail: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      lead: null
    }
  },
  methods: {
    handleSuccess (lead) {
      this.lead = lead
      if (window.gtag) { 
        window.gtag('event', 'contact_us')
        window.gtag('event', 'conversion', {'send_to': 'AW-1039884839/0NUSCPGWqawYEKfE7e8D'})
      }
    }
  }
}
</script>

<style scoped>
  .lead-form {
    min-width: 80%;
  }
</style>