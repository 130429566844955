<template>
  <validation-observer v-slot="{ invalid }" slim>
    <modal @close="handleClose" @confirm="handleCloseConfirmation" class="checkout-custom-modal"
      :class="centralizedModals.includes(stepComponent) ? 'modal-centralized' : ''" :showCloseButton="false">
      <div v-if="prompt" slot="prompt">
        <h5 class="title is-4">Are you sure you'd like to leave?</h5>
        <!-- <p class="subtitle is-size-6">Any progress on this quote will be lost.</p> -->
      </div>

      <div slot="header" class="container">
        <header class="header-quote is-relative">
          <h4 class="title is-5 has-text-white has-text-centered has-text-weight-bold m-0">
            <slot  name="title ">{{ headerText }}</slot>
          </h4>
          <a class="close-icon has-text-white" @click.prevent="handleClose">
            <icon type="x-close" />
          </a>
        </header>
        <step-tabs v-if="quoteSteps.includes(stepComponent)" :current="stepComponent" :steps="steps" />
      </div>
      <div :class="stepComponent != 'options' ? 'component-slot' : ''" slot="body">
        <component :is="stepComponent" ref="stepComponent" @next="next" @close="handleClose"
          @enabled="(enabled) => disabled = !enabled">
        </component>
      </div>

      <div slot="footer" class="is-flex-grow-1">
        <div class="summary-totals" v-if="quote && ['options', 'checkout', 'confirmation'].includes(stepComponent)">
          <!--<quote-totals />-->
        </div>
        <div class="buttons-container">
          <div class="level is-mobile">
            <template v-if="isComplete">
              <div class="level-item">
                <a href="/solutions/container-sales" class="button close-button is-fullwidth">See Other Containers</a>
                <button @click="handleClose" class="button action-button is-fullwidth">Close</button>
              </div>
            </template>
            <template v-else>

              <div v-if="canGoBack" class="level-item is-halfwidth">
                <button @click="back()" class="button close-button is-fullwidth">Back</button>
              </div>
              <div class="level-item is-halfwidth">
                <button @click="next" :class="{ 'is-loading': loading }" :disabled="invalid || disabled || loading"
                  class="button action-button is-fullwidth">
                  {{ nextButtonText }}
                </button>
              </div>
            </template>
          </div>
          <div v-if="(stepComponent == 'options' || stepComponent == 'email-quote') && showPrice" class="is-fullwidth">
            <p class="neutral-gray has-text-weight-semibold text-sm mb-2 has-text-centered	">Ready to make a purchase?
            </p>
            <button @click="reserveOrder" class="button action-button is-fullwidth"
              :disabled="invalid || disabled || loading" :class="{ 'is-loading': loading }">
              Reserve Order
            </button>
          </div>
        </div>
      </div>


      <!-- <div slot="footer" class="tags">
        <span v-for="entry in priceBookEntries" :key="entry.id" class="tag">
          {{ entry.product.code }}
        </span>
      </div> -->
    </modal>
  </validation-observer>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import hasModal from 'shared/mixins/has-modal'
import { ValidationObserver } from 'vee-validate'
import Start from './steps/start.vue'
import PurchaseType from './steps/purchase-type.vue'
import UseCase from './steps/use-case.vue'
import Size from './steps/size.vue'
import Condition from './steps/condition.vue'
import ConditionCost from './steps/condition-cost.vue'
import EmailQuote from './steps/email-quote.vue'
import Customer from './steps/customer.vue'
import Thanks from './steps/thanks.vue'
import Checkout from './steps/checkout.vue'
import Confirmation from './steps/confirmation.vue'
import Quote from './steps/quote.vue'

import QuoteTotals from '../summary/totals.vue'
import StepTabs from './steps/helpers/step-tabs.vue'
import Icon from './steps/helpers/icon.vue'

export default {
  mixins: [hasModal],
  props: {
    completeQuoteModal: {
      type: Boolean,
      default: false
    },
    gtag: String
  },
  components: {
    ValidationObserver,
    Start,
    PurchaseType,
    UseCase,
    Size,
    Condition,
    ConditionCost,
    Customer,
    EmailQuote,
    Thanks,
    Checkout,
    Confirmation,
    QuoteTotals,
    Quote,
    StepTabs,
    Icon
  },
  data() {
    return {
      prompt: false,
      loading: false,
      disabled: false,
      centralizedModals: [
        'purchaseType', 'useCase', 'condition', 'quote', 'thanks'
      ]
    }
  },
  computed: {
    ...mapState('quote', ['priceBook', 'lead', 'quote', 'gtagEvent', 'errors']),
    ...mapState('quote/flow', ['priceBookEntries', 'options']),
    ...mapGetters('quote/flow', ['steps', 'stepComponent', 'canGoBack', 'isComplete']),
    nextButtonText() {
      switch (this.stepComponent) {
      case 'checkout':
        return 'Confirm Payment'
      case 'options':
        return this.showPrice ? 'Email Quote' : 'Request Quote'
      case 'email-quote':
        return 'Get Quote'
      case 'start':
        return 'Begin Quote'
      case 'condition':
        return 'See Results'
      case 'quote':
        return 'Close'
      default:
        return 'Next'
      }
    },
    quoteSteps() {
      return ['start', 'useCase', 'size', 'purchaseType', 'condition', 'options']
    },
    headerText() {
      if (this.quoteSteps.includes(this.stepComponent)) {
        return 'Get a Quote'
      } else if (this.stepComponent == 'checkout') {
        return 'Reserve Order'
      } else if (this.stepComponent == 'quote') {
        return 'Quote Submitted'
      }
      return ''
    },
    showPrice() {
      if (this.priceBook.priceVisibility == 'private') {
        return false
      } else if (this.quote) {
        return this.quote.items.filter(item => item.priceBookEntry.priceVisibility == 'private').length == 0
      }
      return true
    }
  },
  async created() {
    await this['pricing.get']()
    if (!this.stepComponent) {
      this.$store.commit('quote/flow/start')
    }
    //window.scrollTo(0, 0)
  },
  methods: {
    ...mapActions('quote', ['pricing.get']),
    setStep(name) {
      this.$store.commit('quote/flow/step.set', this.steps.indexOf(name))
    },
    handleClose() {
      if (this.isComplete) {
        this.$store.commit('quote/reset')
        this.$store.commit('quote/flow/reset')
        this.close()
        this.prompt = false
      } else if (this.prompt) {
        this.$store.commit('quote/flow/close')
        this.close()
        this.prompt = false
      } else {
        this.prompt = true
      }
    },
    handleCloseConfirmation(confirm) {
      if (confirm) {
        return this.close()
      }
      this.prompt = false
    },
    async reserveOrder() {
      if (this.loading) return
      this.loading = true
      const result = await this.$refs.stepComponent.save()
      if (result && result.errors && result.errors.length) {
        // TODO
        console.warn(result.errors)
      } else {
        this.setStep('checkout')
      }
      //window.scrollTo(0, 0)
      this.loading = false
    },
    async saveStepComponent() {
      const result = await this.$refs.stepComponent.save()
      if (result && result.errors && result.errors.length) {
        console.warn(result.errors)
        return false
      }
      return result
    },
    async next() {
      if (!this.lead) {
        this.$store.commit('quote/lead.init')
      }

      if (this.gtag) {this.$store.commit('quote/flow/gtag.set', this.gtag)}
      if (this.loading) return
      this.loading = true
      const result = await this.saveStepComponent()
      if (result) {
        if ((this.stepComponent == 'size' && !this.steps.includes('condition')) || this.stepComponent == 'condition' || (this.stepComponent == 'start' && result.hasQuote)) {
          this.redirectToCartPage()
        } else {
          this.$store.commit('quote/flow/next')
        }
      }
      //window.scrollTo(0, 0)
      this.loading = false
    },
    redirectToCartPage() {
      window.location.href = '/cart'
    },
    async back() {
      if (this.completeQuoteModal && this.stepComponent == 'email-quote') {
        this.close()
      } else if (this.stepComponent == 'options') {
        await this.$refs.stepComponent.save()
        await this.$store.dispatch('quote/flow/options.compute')
        if (this.lead.data.purchaseType == 'rental') {
          this.setStep('size')
        } else {
          this.setStep('condition')
        }
      } else {
        this.$store.commit('quote/flow/back')
      }
      //window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
/* Component Nesting Hax */
.level-item .button-wrapper {
  display: flex;
  width: 100%;

  .js-button {
    display: flex;
    width: 100%;
  }
}

.level-item {
  flex-grow: 0;
}

.is-halfwidth {
  width: 49%;
}
</style>

<style lang="scss">
@import './styles/build-quote-modal.scss';

.modal-centralized {
  justify-content: center !important;

  @media (max-width: 520px) {
    justify-content: unset !important;
  }
}

.component-slot {
  padding: 0 20px;
}

.checkout-custom-modal {
  z-index: 999;
  top: 0 !important;
  bottom: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.checkout-custom-modal .neutral-gray {
  color: #767676 !important;
}

.checkout-custom-modal .modal-card-foot {
  background-color: white;
  padding: 0;
  border-top: none;
}

.checkout-custom-modal .modal-card-foot .spacing {
  padding-left: 0;
  padding-right: 0;
}

.checkout-custom-modal .action-button {
  background-color: #194985;
  color: #ffffff !important;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.checkout-custom-modal .modal-card-foot .buttons-container {
  background-color: #EDEFF7;
  padding: 1.5rem;
}

.checkout-custom-modal .summary-totals {
  padding: 0 1.5rem 0.5rem 1.5rem;
}

.checkout-custom-modal button[disabled].action-button {
  opacity: 0.5;
  color: #ffffff;
  background-color: #194985;
}

.checkout-custom-modal button:hover.action-button {
  color: #ffffff;
}

.checkout-custom-modal .close-button {
  background-color: #fff;
  color: #061627 !important;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.modal-card {
  max-width: 95%;
}

.modal-card-body {
  flex-grow: unset !important;
  max-height: 400px !important;
}

.checkout-custom-modal .modal-card {
  bottom: auto !important;
  top: auto !important;
}

.checkout-custom-modal .modal-card-body {
  padding: 20px 0px;

  .header {
    margin: -20px -20px 20px -20px;
    padding: 1.5em;
    background: #EDEFF6;

    h4.title {
      margin-bottom: 1.25em;
    }
  }

  .button.is-primary {
    flex: 1;
  }

  .fieldset {
    padding: 0 0.125rem;

    .field:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    .field.column {
      margin-bottom: 0;
    }

    @media (max-width: 767px) {
      padding: 0 1em;
    }
  }
}

.checkout-custom-modal .image img {
  background: #F3F3F3;
  border-radius: 4px;
}

.radio-list {
  margin: 1em 0 2em 0;

  &:last-child {
    margin-bottom: 0;
  }

  .title {
    margin: 1em 0;
  }

  .radio {
    display: block;

    &+.radio {
      margin-left: 0;
      margin-top: .5em;
    }
  }
}
</style>
