<template>
  <div v-if="item">
    <span v-if="product.classification === 'transportation'" >{{ item.quantity }}</span>
    <quantity-control v-else v-model="item.quantity" :minimum="0" @input="updateQuantity" />
  </div>
  <div v-else> 
    <add-to-quote
      :price-book-entry="priceBookEntry"
      :quantity="1"
      class="is-small"
    ></add-to-quote>
  </div>
  
</template>

<script>
import { mapState } from 'vuex'
import AddToQuote from './add-to-quote.vue'
import QuantityControl from 'shared/components/quantity-control.vue'
export default {
  components: {
    AddToQuote,
    QuantityControl
  },
  props: {
    priceBookEntry: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('quote', ['quote', 'errors']),
    item () {
      if (!this.quote) {
        return null
      }
      return this.quote.items.find(i => i.priceBookEntry.id === this.priceBookEntry.id)
    },
    product () {
      if (!this.item) {
        return null
      }
      return this.item.priceBookEntry.product
    }
  },
  methods: {
    async updateQuantity () {
      this.loading = true
      await this.$store.dispatch('quote/quote.save', {
        eventName: 'item.update',
        input: {
          item: {
            id: this.item.id,
            quantity: this.item.quantity
          }
        }
      })
      this.loading = false
    }
  }
}
</script>
