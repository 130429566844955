<template>
  <p class="has-text-centered">
    <a v-if="status" class="button is-static">{{ status }}</a>
    <a v-else @click.prevent="geolocate()" class="button p-0" :class="showIcon ? '' : 'pl-0'" href="#">
      <span class="icon is-small" v-if="showIcon">
        <i data-feather="crosshair"></i>
      </span>
      <span class="has-text-weight-bold">Use my current location</span>
    </a>
  </p>
</template>

<script>
export default {
  data () {
    return {
      status: null
    }
  },
  props: {
    showIcon: {
      default: true,
      required: false,
      type: Boolean
    }
  },
  methods: {
    geolocate () {
      if (!navigator.geolocation) {
        this.status = 'Geolocation is not supported by your browser.'
      } else {
        this.status = 'Locating…'
        navigator.geolocation.getCurrentPosition(this.onSuccess, this.onError)
      }
    },
    onSuccess (position) {
      this.$emit('success', {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      })
      this.status = ''
    },
    onError () {
      this.status = 'Unable to retrieve your location…'
    }
  }
}
</script>

<style scoped lang="scss">
.button {
  background: transparent;
  border: 0;
  color: inherit;
  &.is-static {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
  }
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
  span {
    color: inherit;
  }
  .icon {
    width: 1em !important;
    height: 1em !important;
    color: inherit;
  }
}
</style>
