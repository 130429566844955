<template>
  <validation-observer v-slot="{ invalid }" slim>
    <modal @close="$emit('close')" :showCloseButton="false">
      <div slot="header" style="margin-left: auto">
        <a @click="$emit('close')">
          <div class="mt-2">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 2.20312C9.23233 2.20313 7.50436 2.7273 6.0346 3.70937C4.56483 4.69143 3.41929 6.08728 2.74283 7.72039C2.06637 9.35351 1.88938 11.1505 2.23424 12.8842C2.57909 14.618 3.43031 16.2105 4.68024 17.4604C5.93017 18.7103 7.52268 19.5615 9.25638 19.9064C10.9901 20.2512 12.7871 20.0743 14.4202 19.3978C16.0534 18.7213 17.4492 17.5758 18.4313 16.106C19.4133 14.6363 19.9375 12.9083 19.9375 11.1406C19.933 8.77165 18.9899 6.501 17.3148 4.82588C15.6396 3.15075 13.369 2.20767 11 2.20312ZM14.2398 13.4008C14.3689 13.5311 14.4414 13.7072 14.4414 13.8906C14.4414 14.0741 14.3689 14.2501 14.2398 14.3805C14.1084 14.5075 13.9328 14.5786 13.75 14.5786C13.5672 14.5786 13.3916 14.5075 13.2602 14.3805L11 12.1117L8.73985 14.3805C8.60844 14.5075 8.4328 14.5786 8.25 14.5786C8.06721 14.5786 7.89157 14.5075 7.76016 14.3805C7.63106 14.2501 7.55864 14.0741 7.55864 13.8906C7.55864 13.7072 7.63106 13.5311 7.76016 13.4008L10.0289 11.1406L7.76016 8.88047C7.65053 8.74689 7.5945 8.57731 7.60298 8.40471C7.61146 8.23211 7.68383 8.06884 7.80602 7.94664C7.92822 7.82445 8.09149 7.75208 8.26409 7.7436C8.43669 7.73512 8.60627 7.79115 8.73985 7.90078L11 10.1695L13.2602 7.90078C13.3937 7.79115 13.5633 7.73512 13.7359 7.7436C13.9085 7.75208 14.0718 7.82445 14.194 7.94664C14.3162 8.06884 14.3886 8.23211 14.397 8.40471C14.4055 8.57731 14.3495 8.74689 14.2398 8.88047L11.9711 11.1406L14.2398 13.4008Z" fill="#C5C5C5"/>
            </svg>
          </div>
        </a>
      </div>
      <lead-form slot="body" ref="form">
        <template slot="title">Get Pricing</template>
        <template slot="subtitle">Provide your delivery zipcode and contact information to unlock pricing.</template>
      </lead-form>
      <div slot="actions" class="buttons">       
        <button class="button is-primary is-fullwidth" :class="{ 'is-loading': loading }" :disabled="invalid || loading" @click="save">
          Submit
        </button>
      </div>
    </modal>
  </validation-observer>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Modal from 'shared/components/modal.vue'
import { ValidationObserver } from 'vee-validate'
import LeadForm from './helpers/lead-form.vue'
export default {
  components: {
    Modal,
    ValidationObserver,
    LeadForm
  },
  props: {
    redirectType: String,
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  async created () {
    await this['pricing.get']()
    this.$store.commit('quote/lead.init')
  },
  computed: {
    ...mapState('quote', ['lead', 'depot', 'priceBook', 'organization']),
    priceBookEntry () {
      const { priceBook, product } = this
      if (priceBook && priceBook.entries.length) {
        const id = String(product.id)
        return priceBook.entries.find(i => i.product.id === id)
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions('quote', ['pricing.get']),
    async save () {
      this.loading = true
      this.$store.commit('quote/reset')
      this.$store.commit('quote/flow/reset')
      const result = await this.$refs.form.save()
      this.loading = false
      if (result.lead) {
        if (this.redirectType == 'livingPrice') {
          window.location.href = `/partners/${result.lead.data.organizationId}`
        } else {
          if (this.priceBookEntry && this.priceBookEntry.product) {
            this.addToQuote()
          } else {
            this.$emit('close')
          }
        }
      }
    },
    async addToQuote () {
      this.loading = true
      // create a quote via lead if none exists
      const quote = this.quote || await this.$store.dispatch('quote/quote.save')
      if (!quote) { return }
      await this.$store.dispatch('quote/quote.save', {
        eventName: 'item.add',
        input: {
          item: {
            title: this.priceBookEntry.product.name,
            priceBookEntryId: this.priceBookEntry.id,
            quantity: 1
          }
        }
      })
      this.loading = false
      window.location.href = '/cart'
    }
  }
}
</script>
