
<div
  class="container-card column container-type-card m-5"
  :style="'color:' + brandColor"
>
  <a :href="url">
    <div class="header-section">
      <icon :type="iconType(solution.title)" />
      <b>{{ solution.title }}</b>
    </div>
    <p>{{ shortDescription() }}</p>
  </a>
</div>
