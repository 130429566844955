
<div>
  <article v-if="errors && errors.length" class="message is-danger is-size-7-mobile">
    <div class="message-body">{{ errors[0].message }}</div>
  </article>
  <fieldset class="fieldset lead-form">
    <div class="columns">
      <div class="column">
        <label class="label span is-size-7">First Name</label>
        <validated-input
          v-model="updates.firstName"
          name="First Name"
          rules="required"
          :showErrorIcon="true"
        />
      </div>
      <div class="column">
        <label class="label span is-size-7">Last Name</label>
        <validated-input
          v-model="updates.lastName"
          name="Last Name"
          rules="required"
          :showErrorIcon="true"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label span is-size-7">Email</label>
        <validated-input
          v-model="updates.email"
          name="Email"
          rules="required|email"
          :showErrorIcon="true"
        />
      </div>
      <div class="column">
        <label class="label span is-size-7">Phone</label>
        <validated-input
          v-model="updates.phone"
          name="Phone" 
          :rules="{ required: true, regex: /^\+?[0-9()-]*$/ }"
          :showErrorIcon="true"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label span is-size-7">Delivery Zip</label>
        <validated-input
          v-model="updates.zipcode"
          name="Delivery zip"
          ref="zipfield"
          :rules="{ required: true, regex: ZIP_REGEX }"
          :showErrorIcon="true"
        />
        <p v-if="error" class="help is-danger is-size-7-mobile">
          We couldn't find that zip code.
          <a @click.prevent="reset" class="has-text-black">Try a different zipcode?</a>
        </p>
      </div>
    </div>
  </fieldset>
</div>
