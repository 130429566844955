<template>
	<section class="questions-container">
		<h1 class="title">Frequently Asked Questions</h1>
		<b-collapse
			v-for="(faq, index) in faqs"
			:key="index"
			:aria-id="'question'+index"
			class="panel"
			animation="slide"
			v-model="isOpen[index]">
			<template #trigger>
				<div
					class="panel-heading"
					role="button"
					:aria-controls="'question'+index"
					:aria-expanded="isOpen[index]">
					<strong class="collapse-title">{{faq.data.question}}</strong>
					<icon :type="isOpen[index] ? 'caret-up' : 'caret-down'" />
				</div>

			</template>
			<div class="collapse-content" v-html="faq.data.answer.html"></div>
		</b-collapse>		
	</section>
</template>

<script>
import Icon from './helpers/icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    faqs: Array
  },
  data() {
    return {
      isOpen: [false, false, false]
    }
  }
}
</script>
<style scoped lang="scss">
.title {
	font-size: 1.5rem;
	color: #363636;
	font-weight: 600;
	text-align: center;
	margin-bottom: 2.75rem;
}
.questions-container {
	padding: 0 12rem;

	@media(max-width: 550px) {
		padding: 0 1rem;
	}
}
.panel-heading {
  background-color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}
.collapse-title {
	color: #174984;
	font-weight: 700;
	font-size: 1.25rem;
}
.collapse-content {
	background: #fff;
	color: #363636;
	padding: 1.375rem;
	padding-top: 0;
}
</style>