
<div id="productFilterBar"> <!-- Sticky Sidebar wants this wrapper div -->
  <div class="productFilterBar__inner"> <!-- Sticky Sidebar wants this wrapper div -->
    <h1>Filters</h1>
    <section v-if="!isRent" class="filter-options">
      <h2>Condition</h2>
      <div v-for="(option, label) in conditions" :key="option">
        <label class="checkbox neutral-gray">
          <input type="checkbox" class="mr-2 filter-checkbox" @change="el => update(el, 'condition', option)">
          {{ label }}
        </label>
      </div>
    </section>
    <section class="filter-options">
      <h2>Size</h2>
      <div v-for="(label, size) in sizes" :key="size">
        <label class="checkbox neutral-gray">
          <input type="checkbox" class="mr-2 filter-checkbox" @change="el => update(el, 'size', size)">
          {{ label }}
        </label>
      </div>


    </section>
    <section v-if="!isRent" class="filter-options">
      <h2>Usage Type</h2>
      <div v-for="(option, label) in usageType" :key="option">
        <label class="checkbox neutral-gray">
          <span class="is-flex is-align-items-center">
            <input type="checkbox" class="mr-2 filter-checkbox" @change="el => update(el, 'usageType', option)">
            <icon :type="option" />
            <label class="ml-2"> {{ label }}</label>
          </span>
        </label>
      </div>
    </section>
  </div>
</div>
