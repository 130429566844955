export const currency = (dollars) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(dollars)
}

export const dollars = (cents) => {
  return currency(cents / 100)
}

export const percentage = (number) => {
  return new Intl.NumberFormat('us-EN', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number)
}
