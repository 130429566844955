<template>
  <div class="container">
    <div v-if="formOptions.showIntroText" class="block has-text-centered">
      <div class="columns is-centered">
        <div class="column is-8">
          <h3 class="title is-2">
            Not sure what you need? Get in touch.
          </h3>
          <h4 class="subtitle">
            Our network of knowledgeable experts and trusted providers are waiting to help you find the exact container you need whether buying or renting.
          </h4>
        </div>
      </div>
    </div>
    <div class="columns" :class="formOptions.columnOffset ? 'is-centered' : ''">
      <div class="column" :class="formOptions.columnOffset ? 'is-6' : ''">
        <article v-if="lead" class="message is-medium is-primary">
          <div class="message-header">
            <p>Thanks for the inquiry, {{ lead.firstName }}.</p>
          </div>
          <div class="message-body">
            <p>
              One of our representatives will be in contact shortly.
              If you need immediate service, please call us at {{ phoneNumber }}.
            </p>
          </div>
        </article>
        <p v-else-if="error">
          We are sorry, there was a problem with your request. Error: {{ error }}
        </p>
        <lead-form v-else @success="handleSuccess" @error="handleError" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LeadForm from './form.vue'
export default {
  components: {
    LeadForm
  },
  props: {
    formOptions: {
      showIntroText: {
        type: Boolean,
        default: true
      },
      columnOffset: {
        type: Boolean,
        default: true
      }
    }
  },
  data() {
    return {
      lead: null,
      error: null
    }
  },
  computed: mapState('shared', ['phoneNumber']),
  methods: {
    handleSuccess (lead) {
      this.lead = lead
    },
    handleError (error) {
      this.error = error
    }
  }
}
</script>
