
<validation-provider
  v-slot="{ errors }"
  ref="provider"
  :mode="mode"
  :rules="rules"
>
  <div class="is-flex is-relative">
    <input
      v-bind:value="value"
      :name="name"
      :type="type"
      :class="{ 'is-danger': errors.length, required }"
      :aria-required="required"
      ref="input"
      class="input"
      @input="$emit('input', $event.target.value)"
      @keyup="$emit('keyup', $event)"
    />
    
    <span v-if="errors.length && showErrorIcon" class="is-absolute alert-icon">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4967 6.27711 13.8108 4.62573 12.5925 3.40746C11.3743 2.18918 9.72289 1.5033 8 1.5ZM7.5 5C7.5 4.86739 7.55268 4.74021 7.64645 4.64645C7.74022 4.55268 7.86739 4.5 8 4.5C8.13261 4.5 8.25979 4.55268 8.35356 4.64645C8.44732 4.74021 8.5 4.86739 8.5 5V8.5C8.5 8.63261 8.44732 8.75979 8.35356 8.85355C8.25979 8.94732 8.13261 9 8 9C7.86739 9 7.74022 8.94732 7.64645 8.85355C7.55268 8.75979 7.5 8.63261 7.5 8.5V5ZM8 11.5C7.85167 11.5 7.70666 11.456 7.58333 11.3736C7.45999 11.2912 7.36386 11.1741 7.30709 11.037C7.25033 10.9 7.23548 10.7492 7.26441 10.6037C7.29335 10.4582 7.36478 10.3246 7.46967 10.2197C7.57456 10.1148 7.7082 10.0434 7.85368 10.0144C7.99917 9.98547 8.14997 10.0003 8.28701 10.0571C8.42406 10.1139 8.54119 10.21 8.6236 10.3333C8.70602 10.4567 8.75 10.6017 8.75 10.75C8.75 10.9489 8.67098 11.1397 8.53033 11.2803C8.38968 11.421 8.19892 11.5 8 11.5Z" fill="#EF4444"/>
      </svg>
    </span>
  </div>
  <span v-if="errors" class="help is-danger">
    {{ errors[0] }}
  </span>
</validation-provider>
