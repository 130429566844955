
<div class="container">
  <section class="hero is-small">
    <div class="hero-body">
      <div class="container">

        <div class="columns is-centered">
          <div class="column is-6">
            <div class="box has-background-primary">
              <h5 class="title is-5 has-text-white">
                <span class="icon is-medium">
                  <i data-feather="search"></i>
                </span>
                {{ $props.area.name }} City Search
              </h5>
              <div class="dropdown is-active">
                <div class="dropdown-trigger">
                  <div class="field">
                    <input v-model="query" @keyup="search" type="search" placeholder="Search" class="input" />
                  </div>
                </div>
                <div v-if="query.length" class="dropdown-menu" role="menu">
                  <div class="dropdown-content">
                    <span v-if="filteredCities.length == 0" class="dropdown-item">
                      No results
                    </span>
                    <a v-else v-for="city in filteredCities"
                      :key="city.id"
                      :href="cityPath(city)"
                      class="dropdown-item"
                    >
                      {{ city.name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <aside class="section">
          <div class="columns is-centered has-text-centered">
            <div class="column is-8">
              <h4 class="title is-4">Popular Cities in {{ $props.area.name }}</h4>
            </div>
          </div>
        </aside>

        <list :cities="topCities" />

        <p class="has-text-centered">
          <a @click="showAllCities = !showAllCities">View all cities in {{ $props.area.name }}</a>
        </p>
        <div v-show="showAllCities">
          <hr />
          <list :cities="allCities" />
        </div>
      </div>
    </div>
  </section>

</div>
