<template>
  <section class="container">
    <div class="is-flex">
      <div 
        class="step" 
        :class="steps.indexOf(current) > index && 'done' || current == step && 'active'"
        v-for="(step, index) in quoteSteps" 
        :key="step"
      />
    </div>
    <span v-html="currentText" class="neutral-black is-size-7"/>
  </section>
</template>


<script>
export default { 
  props: {
    steps: Array,
    current: String
  },
  computed: {
    quoteSteps() {
      const quoteSteps = ['start', 'useCase', 'size', 'purchaseType', 'condition', 'options']
      if (this.current == 'start') {
        return quoteSteps
      }     
      return this.steps.filter(step => {
        if (quoteSteps.includes(step)) {
          return true
        }
        return false
      })
    },
    currentText() {
      const totalSteps = this.current == 'start' ? 6 : this.quoteSteps.length
      const step = `${this.quoteSteps.indexOf(this.current) + 1}/${totalSteps}`
      switch (this.current) {
      case 'start':
        return `<span class="neutral-gray step-text">Step ${step}:</span> Availability and Contact`
      case 'useCase':
        return `<span class="neutral-gray step-text">Step ${step}:</span> Container Usage`
      case 'size':
        return `<span class="neutral-gray step-text">Step ${step}:</span> Size Selection`
      case 'purchaseType':
        return `<span class="neutral-gray step-text">Step ${step}:</span> Rent or Buy`
      case 'condition':
        return `<span class="neutral-gray step-text">Step ${step}:</span> Conditions Grades`
      case 'options':
        return `<span class="neutral-gray step-text">Step ${step}:</span> Product Selection`
      case 'email-quote':
        return `<span class="neutral-gray step-text">Step ${step}:</span> Email a Quote`
      default:
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .step {
    border: 2px solid;
    border-radius: 1px;
    width: 100%;
    margin-right: 0.375rem;
    border-color: #E2E5E9;
    background: #E2E5E9;
    height: 0;
  }
  .step.active {
    border-color: #3F4756;
    background: #3F4756;
  }
  .step.done {
    border-color: #44AD79;
    background: #44AD79;
  }
</style>
<style lang="scss">
 .step-text {
    line-height: 17px;
    font-size: 0.75rem;
  }
</style>