
<section class="questions-container">
	<h1 class="title">Frequently Asked Questions</h1>
	<b-collapse
		v-for="(faq, index) in faqs"
		:key="index"
		:aria-id="'question'+index"
		class="panel"
		animation="slide"
		v-model="isOpen[index]">
		<template #trigger>
			<div
				class="panel-heading"
				role="button"
				:aria-controls="'question'+index"
				:aria-expanded="isOpen[index]">
				<strong class="collapse-title">{{faq.data.question}}</strong>
				<icon :type="isOpen[index] ? 'caret-up' : 'caret-down'" />
			</div>

		</template>
		<div class="collapse-content" v-html="faq.data.answer.html"></div>
	</b-collapse>		
</section>
