<template>
  <div>
    <span v-if="(lead && priceBookEntry) || !lead">
      <button class="button is-primary bg-link-color add-button has-text-weight-semibold m-auto" :disabled="loading" @click="addToQuote">
        <span v-if="loading">Loading...</span>
        <span v-else>Add To Quote</span>
      </button>
    </span>
    <span v-else>
      <div v-if="organization" class="is-size-7">
        <p><b>{{ organization.name }}</b> is our partner company for zipcode <b>{{ lead.zipcodeId }}</b>.</p>
        <p>Give them a call to get a quote and reserve the next unit available today!</p>
        <p><b>{{ organization.phone }}</b></p>
      </div>
  
    </span>
    <lead-modal v-if="modalOpen" :product="product"  @close="modalOpen = false" :redirectType="redirectType"></lead-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import LeadModal from '../quote/product/lead-modal.vue'
export default {
  components: {
    LeadModal
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([ 'locationChanged' ]),
    ...mapState('shared', ['zipcode']),
    ...mapState('quote', ['lead', 'depot', 'priceBook', 'organization', 'quote']),
    priceBookEntry () {
      const { priceBook, product } = this
      if (priceBook && priceBook.entries.length) {
        const id = String(product.id)
        const name = product.name
        return priceBook.entries.find(i => i.product.id === id || i.product.name === name)
      } else {
        return null
      }
    }
  },
  data () {
    return {
      modalOpen: false,
      redirectType: '',
      loading: false
    }
  },
  async created () {
    await this['pricing.get']()
  },
  methods: {
    ...mapActions('quote', ['pricing.get']),
    changeZipCode(type) {
      this.redirectType = type
      this.modalOpen = true
    },
    async addToQuote () {
      if (!this.zipcode || !this.lead || !this.organization) {
        this.changeZipCode('addQuote')
        return
      }
      this.loading = true
      // create a quote via lead if none exists
      const quote = this.quote || await this.$store.dispatch('quote/quote.save')
      if (!quote) { return }

      await this.$store.dispatch('quote/quote.save', {
        eventName: 'item.add',
        input: {
          item: {
            title: this.priceBookEntry.product.name,
            priceBookEntryId: this.priceBookEntry.id,
            quantity: 1
          }
        }
      })
      this.loading = false
    }
  }
}
</script>

<style scoped>
  .add-button {
    display: block;
  }
</style>
