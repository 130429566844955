
<transition name="modal">
  <div class="modal is-active">
    <div class="modal-background" @click="close()"></div>
    <div class="box modal-card is-paddingless">
      <div v-if="$slots.prompt" class="modal-close-prompt">
        <div class="prompt is-vcentered">
          <div class="propt-content">
            <slot name="prompt"></slot>
            <br/>
            <div class="buttons is-centered">
              <button @click="$emit('confirm', true)" class="button is-danger">Yes</button>
              <button @click="$emit('confirm', false)" class="button is-primary">No</button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="$slots.header" class="modal-card-head">
        <slot name="header"></slot>
      </div>
      <div v-if="$slots.body" 
          class="modal-card-body" 
          :style="modalCardBodyStyle">
        <slot name="body"></slot>
      </div>
      <div v-if="$slots.actions" class="modal-card-body">
        <slot name="actions"></slot>
      </div>
      <div v-if="$slots.footer" class="modal-card-foot">
        <slot name="footer"></slot>
      </div>
      <button v-if="showCloseButton" id="modal-close-btn"  class="modal-close is-large" aria-label="close" @click="close()"></button>
    </div>
  </div>
</transition>
