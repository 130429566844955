<template>
  <div>
    <fieldset class="fieldset">
      <p class="neutral-gray has-text-weight-semibold text-xs mb-5">
        What size container do you need?
      </p>
      <div class="field">
        <div class="control radio-list">       
          <a 
            v-for="(label, value) in stepOptions.allSizes" 
            :key="value" 
            @click="chooseSize(value)" 
            class="select-card size is-flex is-justify-content-space-between is-align-items-center neutral-gray is-size-7 mb-3"
            :class="updates.size == value && 'active'"
          >
            <!--<input v-model="updates.useCase" type="radio" :value="value">-->          
              <span class="neutral-black has-text-weight-semibold text-sm">{{ label }}</span>
              <span><icon :type="'container-'+value" /></span>
              <span v-html="containerSizesText(value)"/>
          </a>
        </div>
      </div>
      <!--<div class="field">
       <h5 class="title is-5 is-size-6-mobile is-marginless required">Select from Available Sizes</h5>
        <div v-if="isOption(stepOptions.standard)" class="control radio-list">
          <label class="label">Standard Height (8'6")</label>
          <label v-for="(label, value) in stepOptions.standard" :key="value" class="radio is-size-6-mobile">
            <input v-model="updates.size" type="radio" :value="value">
            {{ label }}
          </label>
        </div>
      </div>
      <div v-if="isOption(stepOptions.highCube)" class="field">
        <div class="control radio-list">
          <label class="label">High Cube (9'6")</label>
          <label v-for="(label, value) in stepOptions.highCube" :key="value" class="radio is-size-6-mobile">
            <input v-model="updates.size" type="radio" :value="value">
            {{ label }}
          </label>
        </div>
      </div>
      <div v-else class="field">
        <div class="control radio-list">
          <label class="label">High Cube (9'6")</label>
          <i class="neutral-gray">No Availability at this time. Please contact your sales representative for more information.</i>
        </div>
      </div>-->

      
      <!-- <div class="field">
        <label class="label">Other</label>
        <label class="radio">
          <input v-model="updates.size" type="radio" value="other">
          Open to options
        </label>
      </div> -->
    </fieldset>
  </div>
</template>

<script>
import { DynamicStep } from './mixin'
import Icon from './helpers/icon.vue'
export default {
  components: {
    Icon
  },
  mixins: [ DynamicStep('size') ],
  methods: {
    isOption (hash) {
      return Object.keys(hash).length > 0
    },
    chooseSize(value) {
      this.updates.size = value
    },
    containerSizesText(size) {
      switch (size) {
      case '10':
        return '<span class="neutral-gray is-size-7">10’ x 8’ x 8’6” standard <br> 563.3 cu. ft standard</span>'
      case '20':
        return '<span class="neutral-gray is-size-7">20’ x 8’ x 8’6” standard <br> 1,158 cu. ft  standard <br><br> 20’ x 8’ x 9’6” high cube <br> 1,318 cu. ft  high cube</span>'

      case '40':
        return '<span class="neutral-gray is-size-7">40’ x 8’ x 8’6” standard <br> 2,332 cu. ft  standard <br><br> 40</span>’ x 8’ x 9’6” high cube <br> 2669 cu. ft  high cube'

      case '45':
        return '<span class="neutral-gray is-size-7">45’ x 8’ x 9’6” high cube <br> 3,040 cu. ft  high cube</span>'

      default:
        return ''
      }

    }
  }
}
</script>

<style lang="scss" scoped>
  .checkout-custom-modal .select-card.size {
    padding-left: 1.625rem !important;
    padding-right: 0.75rem !important;
    height: 6.5rem;

    @media (max-width: 480px) {
      flex-direction: column;
      height: auto;

      span {
        margin-bottom: 0.5rem;
      }
    }
  }
</style>