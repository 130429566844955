
<validation-observer
  tag="form"
  ref="validation"
  @submit.prevent="handleSubmit"
>
  <fieldset class="fieldset">
    <div class="columns">
      <div class="column field">
        <label class="label span"><b class="required-star">*</b> Email</label>
        <validated-input
          v-model="lead.email"
          name="Email"
          rules="required|email"
          :showErrorIcon="true"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column field">
        <label class="label span"><b class="required-star">*</b> Existing Quote Number</label>
        <validated-input         
          v-model="lead.quoteNumber"           
          name="Quote Number"
          rules="required"
          :showErrorIcon="true"
        />
      </div>
    </div>
    <p v-if="otherErrors" class="help is-danger">{{otherErrors}}</p>
  </fieldset>
</validation-observer>
