
<div class="testimonials">
  <div class="testimonials-header">
    <h1 class="title">Testimonials</h1>
    <p>Don't take our word for it.<br />Trust our customers.</p>
  </div>

  <carousel class="slider" :perPage="1" paginationPosition="bottom-overlay" paginationActiveColor="#174984"
    :navigationEnabled="false">
    <slide v-for="(item, slideIndex) in testimonialsItems" :key="slideIndex" class="hero slide">

      <div class="hero-body">
        <section class="testimonials-cards-container columns is-narrow">
          <div
            v-for="(testimonial, index) in item"
            :key="index"
            class="testimonials-card column"
            :class="selectClass(item.length, index)"
          >
            <div class="customer-info">
              <img
                class="customer-photo"
                :src="testimonial.data.customerPhoto.url"
                :alt="testimonial.data.customerName"
              />
              <div class="customer-name">

                <b>{{ testimonial.data.customerName }}</b>
                <p>{{ testimonial.data.location }}</p>
              </div>
            </div>
            <p class="customer-testimonial custom-scroll">
              {{ testimonial.data.testimonial }}
            </p>
          </div>
        </section>
      </div>
    </slide>

  </carousel>
</div>
