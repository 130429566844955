
<modal @close="handleClose" class=" modal-centralized checkout-custom-modal" :showCloseButton="false">
  <div slot="header" class="container">
    <header  class="header-quote is-relative">
      <h4 class="title is-5 has-text-white has-text-centered has-text-weight-bold m-0">
        <slot name="title ">{{type == 'reservation' ? 'Order Reserved' : 'Quote Submitted'}}</slot>
      </h4>
      <a class="close-icon has-text-white" @click.prevent="handleClose">
        <icon type="x-close" />
      </a>
    </header>       
  </div>
  <div slot="body" class="thank-you-body">
    <Confirmation v-if="type == 'reservation'" :quote="closedQuote"/>
    <Quote v-else :close="handleClose" :quote="closedQuote"/>
  </div>

    <div slot="footer" class="is-flex-grow-1">
      <div class="buttons-container">
        <div class="level is-mobile">
          <template>
            <div class="level-item">
              <a href="/solutions/container-sales" class="button close-button is-fullwidth">See Other Containers</a>
              <button @click="handleClose" class="button action-button is-fullwidth">Close</button>
            </div>
          </template>                 
        </div>          
      </div>
    </div>
</modal>
