
<div>
  <div class="is-flex is-justify-content-space-between">
    <label class="container mr-3">
      <span class="span">First Name</span>
      <validated-input v-model="customer.firstName" name="First name" rules="required" />
    </label>
    <label class="container">
      <span class="span">Last Name</span>
      <validated-input v-model="customer.lastName" name="Last name" rules="required" />
    </label>
  </div>
  <div class="is-flex flex is-justify-content-space-between">
    <label class="container mr-3">
      <span class="span">Email</span>
      <validated-input v-model="customer.email" name="Email" rules="required|email" />
    </label>
    <label class="container">
      <span class="span">Phone</span>
      <validated-input v-model="customer.phone" name="Phone" :rules="{ required: true, regex: /^\+?[0-9()-]*$/ }" />
    </label>
  </div>
</div>
