
<div class="checkout-form-container checkout-form">
  <div class="check-info my-5">
    <icon type="check-item" class="check-item" />
    <span class="mb-2">Delivery Address</span>
  </div>
  <section class="my-body-section">
    <delivery-form ref="deliveryAddress"> </delivery-form>
  </section>
  <div class="check-info my-5">
    <icon type="check-item" class="check-item" />
    <span class="mb-2">Payment Information</span>
  </div>
  <div class="is-flex is-align-items-center mb-2">
    <icon type="warning-circle" />
    <p class="ml-2 neutral-gray" style="font-size: 0.6875rem;">
      A hold will be placed on your card to complete the reservation. You will not be changed
      in full until our team reviews the order and confirms delivery information.
    </p>
  </div>

</div>
