<template>
  <div class="columns is-multiline container">
    <div v-for="image in block.images" :key="image.id" class="column" :class="grid">
      <figure class="box">
        <img :src="image.url" @click="$emit('setModalImage', image)" :alt="image.fileName" />
      </figure>
      <figcaption v-if="image.altText">
        {{ image.altText }}
      </figcaption>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      required: true
    },
    grid: {
      type: String,
      default: 'is-6'
    }
  }
}
</script>

<style scoped>
.columns {
  margin: 0 !important;
}
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1024px) {
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>