<template>
  <modal @close="handleClose" :showCloseButton="false" :bodyBackgroundColor="'#516a81'" v-if="visible">
    <div slot="header" class="is-flex is-justify-content-space-between is-align-items-center" style="width: 100%">
      <span class="is-size-5"></span>
      <a @click="handleClose">
        <div class="mt-2">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11 2.20312C9.23233 2.20313 7.50436 2.7273 6.0346 3.70937C4.56483 4.69143 3.41929 6.08728 2.74283 7.72039C2.06637 9.35351 1.88938 11.1505 2.23424 12.8842C2.57909 14.618 3.43031 16.2105 4.68024 17.4604C5.93017 18.7103 7.52268 19.5615 9.25638 19.9064C10.9901 20.2512 12.7871 20.0743 14.4202 19.3978C16.0534 18.7213 17.4492 17.5758 18.4313 16.106C19.4133 14.6363 19.9375 12.9083 19.9375 11.1406C19.933 8.77165 18.9899 6.501 17.3148 4.82588C15.6396 3.15075 13.369 2.20767 11 2.20312ZM14.2398 13.4008C14.3689 13.5311 14.4414 13.7072 14.4414 13.8906C14.4414 14.0741 14.3689 14.2501 14.2398 14.3805C14.1084 14.5075 13.9328 14.5786 13.75 14.5786C13.5672 14.5786 13.3916 14.5075 13.2602 14.3805L11 12.1117L8.73985 14.3805C8.60844 14.5075 8.4328 14.5786 8.25 14.5786C8.06721 14.5786 7.89157 14.5075 7.76016 14.3805C7.63106 14.2501 7.55864 14.0741 7.55864 13.8906C7.55864 13.7072 7.63106 13.5311 7.76016 13.4008L10.0289 11.1406L7.76016 8.88047C7.65053 8.74689 7.5945 8.57731 7.60298 8.40471C7.61146 8.23211 7.68383 8.06884 7.80602 7.94664C7.92822 7.82445 8.09149 7.75208 8.26409 7.7436C8.43669 7.73512 8.60627 7.79115 8.73985 7.90078L11 10.1695L13.2602 7.90078C13.3937 7.79115 13.5633 7.73512 13.7359 7.7436C13.9085 7.75208 14.0718 7.82445 14.194 7.94664C14.3162 8.06884 14.3886 8.23211 14.397 8.40471C14.4055 8.57731 14.3495 8.74689 14.2398 8.88047L11.9711 11.1406L14.2398 13.4008Z"
              fill="#C5C5C5" />
          </svg>
        </div>
      </a>
    </div>
    <div slot="body">
      <h3 class="title is-size-5 has-text-centered has-text-white my-5">
        Please, confirm or inform <br>your Zip Code below.
      </h3>
      <location-search
        v-if="query !== null" 
        :showSearchByGeolocate="false"
        :submitLabel="'Submit'"
        :inputPlaceholder="'City or Zip Code'"
        :error-style="{ color: '#fff', textAlign: 'center' }"
        :organizationId=organizationId
        :queryProp=query
        :inZipcodeModal="true"
      />
      <p class="subtitle is-6 has-text-centered has-text-white my-5">It's very important to type the right Zip Code <br>for the best price estimate for your order.</p>
    </div>

  </modal>
</template>


  

<script>
import Modal from 'shared/components/modal.vue'
import LocationSearch from '../location-search/index.vue'
import { googleLocationToZipcode } from 'shared/graphql/queries'
import { graphqlQuery } from 'shared/graphql'
import { cityPath } from '../../shared/city-helper'
import { mapState } from 'vuex'
export default {
  components: {
    Modal,
    LocationSearch
  },
  props: {
    solutionSlug: {
      type: String,
      required: false
    },
    isTypeChange: {
      type: Boolean,
      required: false,
      default: false
    },
    organizationId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      loading: 0,
      error: null,
      query: '',
      coordinates: null,
      cities: [],
      visible: false
    }
  },
  filters: {
    upcase(string) {
      return string.toUpperCase()
    }
  },
  computed: {
    ...mapState('shared', ['zipcode']),
    isButtonDisabled() {
      // 5 digits, or at least 3 characters
      return !/^(\d{5})|(\D{3,})$/i.test(this.query)
    }
  },
  watch: {
    query() {
      if (this.query === '') {
        this.error = null
        this.cities = []
      } else if (this.error && this.query !== this.lastQuery) {
        this.error = null
      }
    }
  },
  mounted() {
    this.visible = false
    let urlParams = new URLSearchParams(window.location.search)
    
    if (urlParams.has('client_location') && !this.zipcode) {
      this.tryGetZipcode(urlParams.get('client_location'))
    }
  },
  methods: {
    cityPath,
    handleClose() {
      this.$el.parentNode.removeChild(this.$el)
      this.$emit('close')
    },
    async tryGetZipcode(location_id) {
      const result = await graphqlQuery({
        query: googleLocationToZipcode,
        variables: {
          googleLocationId: location_id
        }
      })

      let zipcode = result['data']['googleLocationToZipcode']
      this.query = zipcode ? zipcode['id'] : ''
      this.visible = true
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown,
.dropdown-trigger,
.dropdown-menu {
  width: 100%;
}

.help,
.help a {
  color: inherit;
}

.description-text {
  font-size: 0.875rem;
}

.is-danger {
  border: 2px solid #EF4444;
  border-radius: 2px;
}
</style>
<style lang="scss">

.modal-card-body form {
  max-width: 300px;
  margin: auto;
}

.modal-card-body .control .button:not(.is-danger) {
  background-color: #a8b4c0;
  border-color: #a8b4c0;
  color: #2c4154;
}
</style>
