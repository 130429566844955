<template>
  <div>
    <div class="is-flex is-align-content-center is-justify-content-center is-text-center">
      <div class="order-item-quantity is-size-6 mr-3">
        {{ localValue }}
      </div>
      <button class="control-button control-button-minus is-flex is-align-items-center" :disabled="disabled || localValue === minimum || loading" @click="change(-1)">
        <svg width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.4375 1.29999H16.5625" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <button class="control-button control-button-plus is-flex is-align-items-center" :disabled="disabled || localValue === maximum || loading" @click="change(+1)">
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 9H16.125" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.5625 1.4375V16.5625" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
    <!--<a href="#" class="is-flex has-text-danger is-align-content-center is-justify-content-center is-text-center is-size-7 mt-1" @click.prevent="change(-localValue)">
      <span v-if="localValue > 1" >Reset</span>
      <span v-else>&nbsp;</span>
    </a>-->
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1
    },
    minimum: {
      type: Number,
      default: 1
    },
    maximum: {
      type: Number,
      default: 99
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localValue: this.value,
      loading: false
    }
  },
  methods: {
    change (quantity) {
      this.loading = true
      this.localValue += quantity
      if (this.localValue < this.minimum) {
        this.localValue = this.minimum
      } else if (this.localValue >= this.maximum) {
        this.localValue = this.maximum
      }
      this.$emit('input', this.localValue)
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.control-button {
  display: inline-block;
  margin: 0px;
  width: auto;
  text-align: center;
  vertical-align: middle;
  padding: 4px 7px;
  background: white;
  user-select: none;
  font-weight: bold;
  border: 0;
  outline: 0;
}
.control-button:hover {
  cursor: pointer;
  background-color: #d6d6d6;
}
.quantity-display {
  text-align: center;
  margin: 0px;
  padding: 7px 16px;
  background-color: white;
  font-weight: bold;
}
.control-button-minus {
  border-radius: 2px 0px 0px 2px;
  border:  1px solid #C5C5C5;
}
.control-button-plus {
  border-radius: 0px 2px 2px 0px;
  border-right: 1px solid #C5C5C5;
  border-top: 1px solid #C5C5C5;
  border-bottom: 1px solid #C5C5C5;
}
.is-small {
  .control-button {
    width: auto;
    padding: 4px 7px;
  }
  .quantity-display {
    padding: 5px 10px;
  }
}

.order-item-quantity {
  display: inline-block;
  //background: #182632;
  //color: #ffffff;
  font-weight: 600;
  border-radius: 3px;
  padding: 0px 14px;
  margin-right: 8px;
  text-align: center;
  min-width: 25px;
  vertical-align: text-bottom;
  border: 1px solid currentColor;
  max-height: 28px;


  @media (max-width: 580px) {
    font-size: 12px;
    padding: 0 5px;
  }
}

</style>
