<template>
  <section>
    <aside v-for="(block, index) in blocks" :key="index" class="block">
      <component :is="block.data.__typename" :block="block.data"></component>
    </aside>
  </section>
</template>

<script>
import jsonFromTemplate from 'shared/json-from-template'
import PageGalleryBlock from './page-gallery-block/index.vue'
import PageRichTextBlock from './page-rich-text-block.vue'
import PageHtmlBlock from './page-html-block.vue'
import PageVideoBlock from './page-video-block.vue'
export default {
  components: {
    PageGalleryBlock,
    PageRichTextBlock,
    PageHtmlBlock,
    PageVideoBlock
  },
  data () {
    return {
      blocks: jsonFromTemplate('#page-blocks')
    }
  }
}
</script>
