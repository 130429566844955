
<div>
  <span v-if="type === 'check-item'">
    <!-- Heroicon name: envelope -->
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z" fill="#194985"/>
      <path d="M32.25 19.5L21.2438 30L15.75 24.75" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z" stroke="#194985" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </span>
   <span v-if="type === 'warning-circle'" class="is-flex">
    <!-- Heroicon name: warning-circle -->
   <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25Z" stroke="#767676" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.3125 10.3125H11V15.125H11.6875" stroke="#767676" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.8281 8.25C11.3977 8.25 11.8594 7.78829 11.8594 7.21875C11.8594 6.64921 11.3977 6.1875 10.8281 6.1875C10.2586 6.1875 9.79688 6.64921 9.79688 7.21875C9.79688 7.78829 10.2586 8.25 10.8281 8.25Z" fill="#767676"/>
  </svg>
  </span>
  <span v-if="type === 'phone'" class="is-flex">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5625 15.6C12.5915 17.7251 14.3098 19.439 16.4375 20.4625C16.5944 20.5369 16.768 20.569 16.9412 20.5559C17.1143 20.5427 17.2811 20.4847 17.425 20.3875L20.55 18.3C20.6881 18.2064 20.8476 18.1493 21.0137 18.134C21.1799 18.1187 21.3472 18.1457 21.5 18.2125L27.35 20.725C27.5499 20.8083 27.717 20.9549 27.8254 21.1424C27.9338 21.3298 27.9776 21.5477 27.95 21.7625C27.7646 23.2097 27.0582 24.5397 25.9631 25.5038C24.8679 26.4678 23.459 26.9997 22 27C17.4913 27 13.1673 25.209 9.97919 22.0209C6.79107 18.8327 5 14.5087 5 10C5.00033 8.54104 5.53227 7.13214 6.49628 6.03699C7.4603 4.94183 8.79033 4.23546 10.2375 4.05004C10.4523 4.02245 10.6702 4.06623 10.8577 4.17465C11.0452 4.28307 11.1918 4.4501 11.275 4.65004L13.7875 10.5125C13.8528 10.663 13.8802 10.8272 13.8671 10.9907C13.854 11.1541 13.8009 11.3119 13.7125 11.45L11.625 14.625C11.5321 14.7686 11.4775 14.9337 11.4666 15.1044C11.4556 15.2751 11.4887 15.4457 11.5625 15.6V15.6Z" stroke="#194985" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </span>
</div>
