import { mapState } from 'vuex'
import { ValidationObserver, extend } from 'vee-validate'
import { required, email, regex, integer, min_value } from 'vee-validate/dist/rules'
import ValidatedInput from 'shared/components/validated-input.vue'

const LEAD_ATTRIBUTES = {
  company: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  zipcode: '',
  notes: '',
  leadSource: 'containeralliance.com'
}
const LEAD_DATA_ATTRIBUTES = {
  purchaseType: '',
  quantity: null
}

const ZIP_REGEX = /^\d{5}$/

extend('required', required)
extend('regex', regex)
extend('email', email)
extend('integer',  integer)
extend('min_value', min_value)
extend('zip_code', (value) => ZIP_REGEX.test(value))

export default {
  components: {
    ValidationObserver,
    ValidatedInput
  },
  props: {
    product: Object
  },
  data () {
    return {
      loading: 0,
      optionalActive: false,
      lead: null
    }
  },
  computed: mapState('shared', [ 'zipcode', 'area', 'intention' ]),
  created () {
    this.lead = this.newLead()
    this.lead.data.purchaseType = this.intention
  },
  methods: {
    newLead () {
      return {
        ...LEAD_ATTRIBUTES,
        data: { ...LEAD_DATA_ATTRIBUTES, origin: 'web' },
        zipcode: this.zipcode
      }
    },
    async handleSubmit () {
      this.loading = true
      try {
        if (this.area) {
          this.lead.data.city = this.area.city
        }
        this.lead.data.product = this.product
        const { lead, errors } = await this.$store.dispatch('quote/lead.save', this.lead)
        if (errors && errors.length) {
          console.warn(errors)
          this.$emit('error', errors)
        } else if (lead) {
          this.$refs.validation.reset()
          this.lead = this.newLead()
          if (!this.zipcode) {
            this.$store.commit('shared/zipcode.set', lead.zipcode.id)
          }
          // Facebook Lead Event Tracking
          if (window.fbq) { window.fbq('track', 'Lead') }
          // Google Analytics Lead Event
          if (window.gtag) { window.gtag('event', 'generate_lead', { method : 'Lead Form' }) }
          this.$emit('success', lead)
        }
      } catch (e) {
        console.warn(e)
        this.$emit('error', e)
        this.loading = false
      }
    }
  }
}
