import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'

import Vue from 'vue'
import Feather from 'feather-icons'
//import StickySidebar from 'sticky-sidebar'

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import ahoy from 'shared/ahoy'
import store from 'shared/store'
import initApps from 'shared/init-apps'

import Header from '../application/components/header/index.vue'
import Sidebar from '../application/components/sidebar/index.vue'
import LocationSearch from '../application/components/location-search/index.vue'
import CompleteQuoteModal from '../application/components/quote/complete/modal.vue'
import LeadModal from '../application/components/lead-modal/index.vue'
import LeadForm from '../application/components/lead-form/index.vue'

import Slider from '../application/components/slider/index.vue'
import HomeSlider from '../application/components/home_slider/index.vue'
import PageBlocks from '../application/components/page-blocks/index.vue'
import Cities from '../application/components/cities/index.vue'
import OrganizationSolutions from '../application/components/organization-solutions/index.vue'
import DynamicPhoneNumber from '../application/components/dynamic-phone-number/index.vue'
import SocialShareLinks from '../application/components/social-share-links/index.vue'
import Cover from '../application/components/cover/index.vue'
import ContainerOptions from '../application/components/container-options/index.vue'
import FrequentlyQuestions from '../application/components/frequently-questions/index.vue'
import Testimonials from '../application/components/testimonials/index.vue'

import QuoteProduct from '../application/components/quote/product/index.vue'
import AddToQuote from '../application/components/add-to-quote/index.vue'
import AddToQuoteButton from '../../frontend/shared/components/add-to-quote.vue'
import Cart from '../application/components/quote/cart/index.vue'
import QuoteFlow from '../application/components/quote/flow/index.vue'
import RetrieveQuoteModal from '../application/components/retrieve-quote-modal/index.vue'
import LocationSearchModal from '../application/components/location-search/modal.vue'
import SolutionsPreview from '../application/components/solutions/preview.vue'
import ZipCodeModal from '../application/components/location-search/modal.vue'
import ZipCodeConfirmationModal from '../application/components/zipcode-confirm/modal.vue'

const APP_MAP = {
  'header': Header,
  'sidebar': Sidebar,
  'location-search': LocationSearch,
  'complete-quote-modal': CompleteQuoteModal,
  'lead-modal': LeadModal,
  'lead-form': LeadForm,
  'lead-flow': QuoteFlow, // LeadFlow,
  'slider': Slider,
  'cover': Cover,
  'page-blocks': PageBlocks,
  'cities': Cities,
  'organization-solutions': OrganizationSolutions,
  'dynamic-phone-number': DynamicPhoneNumber,
  'social-share-links': SocialShareLinks,
  // quote v2
  'quote-product': QuoteProduct,
  'cart': Cart,
  'retrieve-quote-modal': RetrieveQuoteModal,
  'add-to-quote': AddToQuote,
  'add-to-quote-button': AddToQuoteButton,
  'container-options': ContainerOptions,
  'home-slider': HomeSlider,
  'frequently-questions': FrequentlyQuestions,
  'testimonials': Testimonials,
  'location-search-modal': LocationSearchModal,
  'solutions-preview': SolutionsPreview,
  'zip-code-modal': ZipCodeModal,
  'zip-code-confirm-modal': ZipCodeConfirmationModal
}

// forEach Pollyfil
if (typeof Array.prototype.forEach != 'function') {
  Array.prototype.forEach = function (callback) {
    for (var i = 0; i < this.length; i++) {
      callback.apply(this, [this[i], i, this])
    }
  }
}
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach
}

const parseProps = (string) => {
  try {
    return JSON.parse(string)
  } catch (e) {
    console.warn('Could not parse props:', string)
    return {}
  }
}

const stripeScript = document.createElement('script')
stripeScript.src = 'https://js.stripe.com/v3/'
document.body.appendChild(stripeScript)

store.commit('quote/flow/close')

document.addEventListener('DOMContentLoaded', () => {
  initApps(APP_MAP)
  // Init Feather Icons
  // Icon Refernce https://feathericons.com/
  Feather.replace()
  // const sidebar = document.getElementById('sidebar')
  // if (sidebar) {
  //   new StickySidebar(sidebar, {
  //     containerSelector: '.column',
  //     innerWrapperSelector: '.sidebar__inner',
  //     topSpacing: 20,
  //     bottomSpacing: 20,
  //     minWidth: 768
  //   })
  // }
  const loader = document.getElementById('loader')
  if (loader && loader.parentNode) {
    setTimeout(function() {
      loader.parentNode.removeChild(loader)
    }, 1000)
  }
})

// Manully init vue apps with a custom event
// const event = new CustomEvent('vue-app:init', { detail: { name: 'lead-flow', props: { ... }}})
// document.dispatchEvent(event)
document.addEventListener('vue-app:init', e => {
  try {
    const { name, props } = e.detail
    const $el = document.createElement('div')
    document.body.appendChild($el)
    new Vue({
      name,
      store,
      render: h => h(APP_MAP[name], {
        props
      })
    }).$mount($el)
  } catch (error) {
    console.error(error)
  }
})

// Init vue apps when data-vue-init attribute is found
document.addEventListener('click', e => {
  const target = e.target.hasAttribute('data-vue-init') ? e.target : e.target.closest('[data-vue-init]')
  if (!target) {
    return
  }
  const name = target.getAttribute('data-vue-init')
  if (name) {
    e.preventDefault()
    const props = parseProps(e.target.getAttribute('data-props'))
    const event = new CustomEvent('vue-app:init', { detail: { name, props } })
    document.dispatchEvent(event)
  }
})

window.initOrganization = (organization) => {
  if (!store || !store.state.shared.organization || !store.state.shared.organization.id) {
    store.commit('shared/organization.set', organization)
  }
  if (!store || !store.state.quote.organization || !store.state.quote.organization.id) {
    store.commit('quote/organization.set', organization)
  }
  if (typeof window.CallTrk !== 'undefined') {
    window.CallTrk.swap()
  }
}

window.initQuote = (organization, token) => {
  store.commit('quote/organization.set', organization)
  const event = new CustomEvent('vue-app:init', { detail: { name: 'cart', props: { token } } })
  document.dispatchEvent(event)
}

window.initBuildQuote = (organization) => {
  store.commit('quote/organization.set', organization)
  const event = new CustomEvent('vue-app:init', { detail: { name: 'lead-flow' } })
  document.dispatchEvent(event)
}

window.setArea = (area) => {
  store.commit('shared/area.set', area)
  if (area) {
    ahoy.track('area.visit', {
      city_id: area.city ? area.city.id : null,
      msa_id: area.msa ? area.msa.id : null,
      state_id: area.state ? area.state.id : null
    })
  }
}

const gclid = new URLSearchParams(window.location.search).get('gclid')
if (gclid) {
  store.commit('quote/flow/gclid.set', gclid)
}