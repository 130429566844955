<template>
  <validation-observer
    tag="form"
    ref="validation"
    v-slot="{ invalid }"
    @submit.prevent="handleSubmit"
  >

    <product v-if="product" :product="product"></product>

    <div class="columns mb-0">
      <div class="column field mb-0">
        <label class="label required">First Name</label>
        <div class="control">
          <validated-input v-model="lead.firstName" name="First name" rules="required" />
        </div>
      </div>

      <div class="column field mb-0">
        <label class="label required">Last Name</label>
        <div class="control">
          <validated-input v-model="lead.lastName" name="Last name" rules="required" />
        </div>
      </div>
    </div>

    <div class="columns mb-0">
      <div class="column field mb-0">
        <label class="label required">Email Address</label>
        <div class="control">
          <validated-input v-model="lead.email" name="Email" rules="required|email" />
        </div>
      </div>

      <div class="column field mb-0">
        <label class="label">Phone Number</label>
        <div class="control">
          <validated-input
            v-model="lead.phone"
            name="Phone number"
            :rules="{ regex: /^\+?[0-9()-]*$/ }"
          />
        </div>
      </div>
    </div>

    <div class="columns mb-0">
      <div class="column field">
        <label class="checkbox">
          <input type="checkbox" class="mr-2 filter-checkbox" v-model="lead.smsConsent">
          <b>I consent to receive SMS text messages from this business.</b>
        </label>
      </div>
    </div>

    <div class="field">
      <label class="label required">Delivery Zip</label>
      <validated-input
        v-model="lead.zipcode"
        name="Delivery zip"
        :rules="{ required: true, regex: /^\d{5}$|^\d{5}-\d{4}$/ }"
      />
    </div>

    <template v-if="optionalActive">

      <div class="field">
        <label class="label">Company or Organization</label>
        <div class="control">
          <input
            v-model="lead.company"
            type="text"
            aria-required="false"
            class="input"
          />
        </div>
      </div>

      <div class="columns mb-0">
        <div class="column field mb-0">
          <label class="label">Rental or Purchase</label>
          <div class="control select is-fullwidth">
            <select v-model="lead.data.purchaseType"
                    class="input"
                    title="Rental or Purchase"
                    aria-required="false">
              <option value="">Please select...</option>
              <option>Rental</option>
              <option>Sale</option>
              <option>Not Sure</option>
            </select>
          </div>
        </div>

        <div class="column field mb-0">
          <label class="label">Quantity</label>
          <div class="control">
            <validated-input v-model="lead.data.quantity" name="Quantity" rules="integer|min_value:0" />
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Is there any other info that will help us respond to your request?</label>
        <div class="control">
          <textarea v-model="lead.notes" class="textarea" title="Notes" aria-required="false"></textarea>
        </div>
      </div>

    </template>

    <div v-else class="p-3 pb-4 has-text-centered	">
      <a @click.prevent="optionalActive = true">Tell us a bit more...</a>
    </div>

    <div class="field">
      <div class="control actions">
        <button
          :class="{ 'disabled': invalid, 'is-loading': loading }"
          :disabled="invalid || loading > 0"
          class="button is-primary is-fullwidth is-medium"
          data-label="submit"
          type="submit"
        >
          Submit
        </button>
      </div>
    </div>

  </validation-observer>
</template>

<script>
import leadForm from 'shared/mixins/lead-form'
import Product from './product.vue'
export default {
  mixins: [leadForm],
  components: {
    Product
  }
}
</script>
