import { mapState } from 'vuex'
import ahoy from 'shared/ahoy'

import ValidatedInput from 'shared/components/validated-input.vue'

import { ValidationObserver, extend, ValidationProvider } from 'vee-validate'
import { required, email, regex, integer, min_value } from 'vee-validate/dist/rules'

extend('required', required)
extend('regex', regex)
extend('email', email)
extend('integer',  integer)
extend('min_value', min_value)

export const Step = {
  components: {
    ValidationObserver,
    ValidatedInput,
    ValidationProvider
  },
  data () {
    return {
      updates: {}
    }
  },
  computed: {
    ...mapState('quote', [ 'lead', 'organization' ]),
    ...mapState('quote/flow', ['options']),
    phoneNumber() {
      return this.organization ? this.organization.phone : '866-533-5094'
    }
  },
  mounted () {
    if (typeof window.CallTrk !== 'undefined') {
      window.CallTrk.swap()
    }
  },
  methods: {
    async save () {
      const { lead, updates } = this
      ahoy.track('lead.update', updates)
      lead.data = { ...lead.data, ...updates }
      const result = await this.$store.dispatch('quote/lead.save', lead)
      if (result.lead) {
        await this.$store.dispatch('quote/flow/options.compute')
      }
      return result
    }
  }
}

export const DynamicStep = (attribute, options={}) => {
  return {
    mixins: [ Step ],
    created () {
      this.$set(this.updates, attribute, null)
      this.$store.commit('quote/lead.option.reset', attribute)
    },
    computed: {
      stepOptions () {
        return this.options[attribute]
      }
    },
    watch: {
      updates: {
        deep: true,
        handler () {
          const value = this.updates[attribute] || ''
          if (options.validator) {
            this.$emit('enabled', options.validator.call(this, value))
          } else if (value instanceof Object) {
            let enabled = true
            Object.keys(value).map(key => {
              if (value[key].length == 0) {
                enabled = false
              }
              return key
            })
            this.$emit('enabled', enabled)
          } else {
            this.$emit('enabled', value.length > 0)
          }
        }
      }
    }
  }
}

export default Step
