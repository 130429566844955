
<div v-if="quote && quote.items && quote.items.length > 0" class="summary-wrapper checkout-form">
  <div class="is-flex is-justify-content-space-between is-align-items-center mb-4">
    <p class="has-text-black has-text-weight-semibold text-18">Summary</p>
    <!--<button v-if="showPrice" @click="generatePdf(quote)" class="button download-pdf-button is-flex"><span class="mr-2">Download</span> <icon type="pdf" /></button>-->

  </div>

  <ul class="order-summary-list-items">
    <p class="neutral-gray text-sm has-text-weight-semibold mb-2 px-2">Container{{ containers.length > 1 ? 's' : '' }}</p>
    <li v-for="item in containers" :key="item.id" class="order-summary-item">        
      <div class="row flex-on-mobile vertical-align-middle row-flex-start-item-max-width is-relative">
        <div class="is-flex row is-3 p-0">
          <a class="remove-icon" @click="removeItem(item.id)">
            <icon type="remove-item-button" />
          </a>
          <figure v-if="item.priceBookEntry.product.primaryPhoto" class="summary-image">
            <img :src="imageUrl(item.priceBookEntry.product.primaryPhoto.key, { resize: { width: 180, height: 130 } } )">
          </figure>
          <div class="item item-8 item-conditional__to-8-on-desktop item-conditional__to-7-on-mobile">
            <div class="order-summary-item-title is-size-6">
              <span class="order-item-title text-sm">{{ item.priceBookEntry.product.name }}</span>
            </div>
          </div>
        </div>
        <div class="is-flex item item-2 text-right m-text-right order-summary-price order-complete">
          <span :class="`order-item-quantity text-18 ${showPrice ? 'mr-4' : 'mr-0'}`">{{ item.quantity }}</span>
          <span v-if="showPrice" class="text-sm has-text-black has-text-weight-semibold min-w-7xl">{{ item.priceCents | dollars }}</span>
        </div>
      </div>
    </li>
    <p class="neutral-gray text-sm has-text-weight-semibold mb-2 px-2">Delivery</p>
    <li v-for="item in deliveries" :key="item.id" class="order-summary-item">        
      <div class="row neutral-gray flex-on-mobile vertical-align-middle row-flex-space-between is-relative">
        <div>
          <div class="is-3 p-0">
            <figure v-if="item.priceBookEntry.product.primaryPhoto" class="summary-image">
              <img :src="imageUrl(item.priceBookEntry.product.primaryPhoto.key, { resize: { width: 180, height: 130 } } )">
            </figure>
          </div>
          <div class="item item-8 item-conditional__to-8-on-desktop item-conditional__to-7-on-mobile">
            <div class="order-summary-item-title is-size-6 item-delivery-row">
              <span class="order-item-title text-sm neutral-gray">{{ item.priceBookEntry.product.name }}</span>
            </div>
          </div>

        </div>
        <div class="is-flex item item-2 text-right m-text-right order-summary-price order-complete">
          <span :class="`order-item-quantity text-18 ${showPrice ? 'mr-4' : 'mr-0'}`">{{ item.quantity }}</span>
          <span v-if="showPrice" class="text-sm has-text-black has-text-weight-semibold min-w-7xl">{{ item.priceCents | dollars }}</span>
        </div>
      </div>
    </li>
  </ul>
</div>
