<template>
  <quote-flow :closeOnCompleteBack="true" />
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import QuoteFlow from '../flow/index.vue'
export default {
  props: {
    token: String
  },
  components: {
    QuoteFlow
  },
  computed: {
    ...mapState('quote', [ 'quote' ]),
    ...mapGetters('quote/flow', [ 'steps' ])
  },
  async mounted () {
    if (this.token) {
      const quote = await this.$store.dispatch('quote/quote.session.init', { token: this.token })
      await this.$store.dispatch('quote/lead.load', quote.opportunity.leadId)
    }
    if (this.quote && this.quote.opportunity && this.quote.opportunity.closed) {
      this.$store.commit('quote/reset')
      this.$store.commit('quote/flow/step.set', this.steps.indexOf('start'))
    } else if (this.quote && this.quote.status == 'draft') {
      this.$store.commit('quote/flow/step.set', this.steps.indexOf('options'))
    }
  },
  methods: {
    reset () {
      this.$store.commit('quote/reset')
      this.close()
    }
  }
}
</script>

<style scoped>
.card {
  border: 1px solid #174984;
}
.card:hover {
  -webkit-box-shadow: -2px 6px 14px 1px rgba(194, 190, 194, 1);
  -moz-box-shadow: -2px 6px 14px 1px rgba(194, 190, 194, 1);
  box-shadow: -2px 6px 14px 1px rgba(194, 190, 194, 1);
}
.card-header {
  padding: 0.75rem 0.5rem;
}
.card-content {
  margin-top: -30px;
}
.chevron {
  height: 24px;
  width: 24px;
}
</style>
