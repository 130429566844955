<template>
  <div class="is-flex is-align-content-center is-justify-content-center is-text-center"> 
    <div class="order-item-quantity is-size-6 mr-3">
      0
    </div>   
    <button class="control-button control-button-plus is-flex is-align-items-center" :disabled="loading" @click="addToQuote">
      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 9H16.125" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.5625 1.4375V16.5625" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    priceBookEntry: {
      type: Object,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: mapState('quote', ['quote', 'priceBook', 'errors']),
  async created () {
    await this['pricing.get']()
  },
  methods: {
    ...mapActions('quote', ['pricing.get']),
    async addToQuote () {
      this.loading = true
      // create a quote via lead if none exists
      const quote = this.quote || await this.$store.dispatch('quote/quote.save')
      if (!quote) { return }
      await this.$store.dispatch('quote/quote.save', {       
        eventName: 'item.add',
        input: {
          item: {
            title: this.priceBookEntry.product.name,
            priceBookEntryId: this.priceBookEntry.id,
            quantity: this.quantity
          }
        }
      })
      this.loading = false
    }
  }
}
</script>

<style scoped>
.quote {
  padding: 1em;
  border: 1px solid blue;
}
</style>

<style lang="scss" scoped>
.control-button {
  display: inline-block;
  margin: 0px;
  width: auto;
  text-align: center;
  vertical-align: middle;
  padding: 4px 14px;
  background: white;
  user-select: none;
  font-weight: bold;
  border: 0;
  outline: 0;
}
.control-button:hover {
  cursor: pointer;
  background-color: #d6d6d6;
}
.quantity-display {
  text-align: center;
  margin: 0px;
  padding: 7px 16px;
  background-color: white;
  font-weight: bold;
}
.control-button-minus {
  border-radius: 2px 0px 0px 2px;
  border:  1px solid #C5C5C5;
}
.control-button-plus {
  border-radius: 2px;
  border: 1px solid #C5C5C5;
}
.is-small {
  .control-button {
    width: auto;
    padding: 4px 7px;
  }
  .quantity-display {
    padding: 5px 10px;
  }
}

.order-item-quantity {
  display: inline-block;
  //background: #182632;
  //color: #ffffff;
  font-weight: 600;
  border-radius: 3px;
  padding: 0px 14px;
  margin-right: 8px;
  text-align: center;
  min-width: 25px;
  vertical-align: text-bottom;
  border: 1px solid currentColor;


  @media (max-width: 580px) {
    font-size: 12px;
    padding: 0 5px;
  }
}

</style>
