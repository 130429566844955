
<div v-if="item">
  <span v-if="product.classification === 'transportation'" >{{ item.quantity }}</span>
  <quantity-control v-else v-model="item.quantity" :minimum="0" @input="updateQuantity" />
</div>
<div v-else> 
  <add-to-quote
    :price-book-entry="priceBookEntry"
    :quantity="1"
    class="is-small"
  ></add-to-quote>
</div>

