<template>
  <modal @close="handleClose" class=" modal-centralized checkout-custom-modal" :showCloseButton="false">
    <div slot="header" class="container">
      <header  class="header-quote is-relative">
        <h4 class="title is-5 has-text-white has-text-centered has-text-weight-bold m-0">
          <slot name="title ">{{type == 'reservation' ? 'Order Reserved' : 'Quote Submitted'}}</slot>
        </h4>
        <a class="close-icon has-text-white" @click.prevent="handleClose">
          <icon type="x-close" />
        </a>
      </header>       
    </div>
    <div slot="body" class="thank-you-body">
      <Confirmation v-if="type == 'reservation'" :quote="closedQuote"/>
      <Quote v-else :close="handleClose" :quote="closedQuote"/>
    </div>

      <div slot="footer" class="is-flex-grow-1">
        <div class="buttons-container">
          <div class="level is-mobile">
            <template>
              <div class="level-item">
                <a href="/solutions/container-sales" class="button close-button is-fullwidth">See Other Containers</a>
                <button @click="handleClose" class="button action-button is-fullwidth">Close</button>
              </div>
            </template>                 
          </div>          
        </div>
      </div>
  </modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import hasModal from 'shared/mixins/has-modal'
import Quote from '../../flow/steps/quote.vue'
import Icon from '../../flow/steps/helpers/icon.vue'
import Confirmation from '../../flow/steps/confirmation.vue'

export default {
  mixins: [ hasModal ],
  components: {
    Quote,
    Icon,
    Confirmation
  },
  props: {
    closeModal: {
      type: Function,
      default: () => true,
      required: false
    },
    type: String,
    closedQuote: {
      type: Object,
      default: () => {},
      required: false
    }
  },
  computed: {
    ...mapState('quote', ['priceBook', 'quote']),
    showPrice () {
      if (this.priceBook.priceVisibility == 'private') {
        return false
      } else if (this.quote) {
        return this.quote.items.filter(item => item.priceBookEntry.priceVisibility == 'private').length == 0
      }
      return true
    }
  },
  async created () {
    await this['pricing.get']()
  },
  methods: {
    ...mapActions('quote', ['pricing.get']),
    handleClose () {
      if (this.isComplete) {        
        this.$store.commit('quote/reset')
        this.$store.commit('quote/flow/reset')                
      } 
      if (!this.showPrice) {
        this.$store.commit('quote/reset')
        this.$store.commit('quote/flow/reset')
      }
      this.closeModal()
      this.close()
    }
  }
}
</script>

<style scoped lang="scss">
  .thank-you-body {
    overflow: hidden;
  }
</style>