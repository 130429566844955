<template>
  <div id="productFilterBar"> <!-- Sticky Sidebar wants this wrapper div -->
    <div class="productFilterBar__inner"> <!-- Sticky Sidebar wants this wrapper div -->
      <h1>Filters</h1>
      <section v-if="!isRent" class="filter-options">
        <h2>Condition</h2>
        <div v-for="(option, label) in conditions" :key="option">
          <label class="checkbox neutral-gray">
            <input type="checkbox" class="mr-2 filter-checkbox" @change="el => update(el, 'condition', option)">
            {{ label }}
          </label>
        </div>
      </section>
      <section class="filter-options">
        <h2>Size</h2>
        <div v-for="(label, size) in sizes" :key="size">
          <label class="checkbox neutral-gray">
            <input type="checkbox" class="mr-2 filter-checkbox" @change="el => update(el, 'size', size)">
            {{ label }}
          </label>
        </div>


      </section>
      <section v-if="!isRent" class="filter-options">
        <h2>Usage Type</h2>
        <div v-for="(option, label) in usageType" :key="option">
          <label class="checkbox neutral-gray">
            <span class="is-flex is-align-items-center">
              <input type="checkbox" class="mr-2 filter-checkbox" @change="el => update(el, 'usageType', option)">
              <icon :type="option" />
              <label class="ml-2"> {{ label }}</label>
            </span>
          </label>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Icon from './helpers/icon.vue'
import Vue from 'vue'
import RelatedProducts from './helpers/related-products-mobile.vue'
const SIZES = {
  'ODD': 'Odd',
  '10': '10\'',
  '15': '15\'',
  '20': '20\'',
  '20HC': '20\' High Cube',
  '24': '24\'',
  '25': '25\'',
  '30': '30\'',
  '40': '40\'',
  '40HC': '40\' High Cube',
  '45': '45\'',
  '45HC': '45\' High Cube',
  '53HC': '53\' High Cube',
  '60': '60\''
}
const SHIPPING_CONDITIONS = [
  'one_trip',
  'iicl',
  'cargo_worthy'
]
export default {
  components: {
    Icon
  },
  props:{
    isRent:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sizes: [],
      products: document.querySelectorAll('.is-mobile [data-config]'),
      carouselComponent: null,
      filters: {
        size: [],
        condition: [],
        usageType: []
      },
      conditions: {
        'One Trip (Like New)': 'one_trip',
        'Cargo Worthy': 'cargo_worthy',
        'Refurbished': 'refurbished',
        'WWT': 'wind_water_tight'
      },
      usageType: {
        'Domestic Storage': 'domestic_storage',
        'Shipping Export': 'shipping_export',
        'Refrigerated Storage': 'working_refrigerated',
        'Insulated Storage': 'non_working_refrigerated'
      }
    }
  },
  mounted() {
    //make filter info section visible
    const clearFilterButton = document.querySelector('.filter-results')
    if(clearFilterButton) clearFilterButton.style.display = 'flex'

    const elements = document.querySelectorAll('[data-config]')
    elements.forEach(i => {
      i.config = JSON.parse(i.getAttribute('data-config'))
      this.sizes[i.config.size] = SIZES[i.config.size] || i.config.size
    })
    // sort sizes
    this.sizes = Object.keys(this.sizes).sort().reduce((obj, key) => {
      obj[key] = this.sizes[key]
      return obj
    }, {})
    this.mountCarousel(this.products)
  },
  methods: {
    mountCarousel(elements) {
      document.querySelectorAll('[data-vue-app="partners-related-products-mobile"]').forEach(el => {
        this.carouselComponent = new Vue({
          render: (h) => h(RelatedProducts, {
            props: {
              elements: elements
            }
          })
        }).$mount(el)
      })
    },
    unmountCarousel() {
      if (this.carouselComponent != null) {
        this.carouselComponent.$destroy()
        const vueCarousel = document.querySelector('.VueCarousel')
        if (vueCarousel) {
          vueCarousel.remove()
        }
        const mobileCarouselContainer = document.querySelector('.hero.is-mobile .hero-body .columns') || document.querySelector('.hero.is-mobile .hero-body')
        if (mobileCarouselContainer) {
          mobileCarouselContainer.insertAdjacentHTML('beforeend', '<div data-vue-app="partners-related-products-mobile" class="rows is-multiline"></div>')
        }
      } 
    },
    toNodeList(arrayOfNodes) {
      var fragment = document.createDocumentFragment()

      arrayOfNodes.forEach(function (item) {
        var clonedNode = item.cloneNode(true)

        if (Object.prototype.hasOwnProperty.call(item, 'config')) {
          clonedNode.config = item.config
        }

        fragment.appendChild(clonedNode)
      })

      return fragment.childNodes
    },
    toggle(elements, filters) {
      let visibleElementsSize = 0
      const visibleElements = []
      for (let x = 0; x < elements.length; x++) {
        let visible = true
        let i = elements[x]
        Object.keys(filters).forEach(key => {
          if (i.config) {
            if (filters[key].length) {
              if (key != 'usageType') {
                visible = visible && filters[key].includes(i.config[key])
              } else {
                if (visible) {
                  if (!filters[key].includes('domestic_storage') && filters[key].includes('shipping_export')) {
                    visible = SHIPPING_CONDITIONS.includes(i.config['condition'])
                  }
                  if (!filters[key].includes('domestic_storage') && !filters[key].includes('shipping_export') && (filters[key].includes('working_refrigerated') || filters[key].includes('non_working_refrigerated'))) {
                    visible = filters[key].includes(i.config['config'])
                  }
                }
              }
            }
          } else {
            visible = false
          }
        })  
        if (visible) {     
          visibleElements.push(i)         
          visibleElementsSize++          
        }
        i.style.display = visible ? 'block' : 'none'        
      }
      
      const resultSpan = document.getElementById('filter-results-size')
      resultSpan.innerText = visibleElementsSize
      this.unmountCarousel()
      this.mountCarousel(this.toNodeList(visibleElements))
    },
    update(element, type, value) {
      if (element.target.checked) {
        this.filters[type].push(value)
      } else {
        this.filters[type] = this.filters[type].filter(el => el != value)
      }
      const elements = document.querySelectorAll('[data-config]') 
      this.toggle(elements, this.filters)
    }
  }
}
</script>

<style scoped lang="scss">
.productFilterBar__inner {
  background-color: hsl(0deg, 0%, 100%);
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
  color: hsl(0deg, 0%, 29%);
  display: block;
  padding: 1.25rem;

  h1 {
    color: #363636;
    font-weight: 600;
    font-size: 1.25rem;
  }

  .filter-options {
    h2 {
      color: #363636;
      font-weight: 700;
      font-size: 1rem;
      margin: 1.375rem 0;
    }

    .checkbox {
      font-size: 0.875rem;
    }
  }
}
</style>