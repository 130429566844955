<template>
  <span style="width: 70px; display: flex; justify-content: center;" v-if="type === 'container-sales'">
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.1717 4.60923L6.56236 7.12486L4.04673 19.7342C4.00731 19.9356 4.01805 20.1435 4.078 20.3397C4.13795 20.5359 4.24526 20.7143 4.39048 20.8592L20.703 37.1717C20.8179 37.2894 20.9553 37.3828 21.1069 37.4467C21.2585 37.5105 21.4213 37.5434 21.5858 37.5434C21.7503 37.5434 21.9131 37.5105 22.0647 37.4467C22.2163 37.3828 22.3536 37.2894 22.4686 37.1717L36.6092 23.0311C36.7269 22.9161 36.8203 22.7788 36.8842 22.6272C36.948 22.4756 36.9809 22.3128 36.9809 22.1483C36.9809 21.9838 36.948 21.821 36.8842 21.6694C36.8203 21.5178 36.7269 21.3804 36.6092 21.2655L20.2967 4.95298C20.1518 4.80776 19.9734 4.70045 19.7772 4.6405C19.581 4.58055 19.3731 4.56981 19.1717 4.60923V4.60923Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.125 15.5625C14.1605 15.5625 15 14.723 15 13.6875C15 12.652 14.1605 11.8125 13.125 11.8125C12.0895 11.8125 11.25 12.652 11.25 13.6875C11.25 14.723 12.0895 15.5625 13.125 15.5625Z" fill="white"/>
    </svg>
  </span>
  <span style="width: 70px; display: flex; justify-content: center;" v-else-if="type === 'container-rentals'">
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 35.5625C28.2843 35.5625 35 28.8468 35 20.5625C35 12.2782 28.2843 5.5625 20 5.5625C11.7157 5.5625 5 12.2782 5 20.5625C5 28.8468 11.7157 35.5625 20 35.5625Z" stroke="white" stroke-width="2.5" stroke-miterlimit="10"/>
      <path d="M20 11.8125V20.5625H28.75" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </span>
  <span style="width: 70px; display: flex; justify-content: center;" v-else-if="type === 'refrigerated-container'">
    <svg width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.72498 17.5821C0.313668 18.1673 -0.561343 15.9466 0.853997 15.3613L4.60405 13.8005L0.749157 11.4759C-0.57344 10.679 0.599962 8.61176 1.91853 9.4046L5.75325 11.7167L5.19276 7.61967C4.97905 6.06305 7.28553 5.73097 7.49521 7.28759L8.30974 13.2609L15.8744 17.827C15.8945 14.8092 16.0155 11.8288 16.0356 8.80271L11.4348 5.10417C10.2332 4.13699 11.6646 2.24829 12.8662 3.21547L16.0518 5.77663L16.08 1.17734C16.0881 -0.400039 18.4147 -0.387586 18.4066 1.18564L18.3784 5.76003L21.5438 3.21132C22.7454 2.24414 24.1769 4.13284 22.9753 5.10002L18.3583 8.81516L18.2978 17.8436C20.8381 16.3575 23.4309 14.9586 25.9713 13.4767L26.7817 7.5242C26.9955 5.96758 29.2979 6.30381 29.0842 7.85628L28.5237 11.9782L32.4068 9.70347C33.7375 8.91893 34.8907 11.0027 33.5641 11.7831L29.7011 14.0413L33.427 15.5896C34.8423 16.179 33.9673 18.3998 32.556 17.8104L27.1205 15.5522L19.5034 20.0187C22.0357 21.5462 24.4793 23.1444 27.0156 24.6761L32.427 22.4263C33.8423 21.8368 34.7133 24.0576 33.298 24.647L29.5479 26.2078L33.4028 28.5324C34.7254 29.3294 33.552 31.3965 32.2334 30.6037L28.3987 28.2916L28.9592 32.3886C29.1729 33.9452 26.8664 34.2773 26.6527 32.7207L25.8422 26.7391L18.2776 22.173C18.2574 25.1908 18.1365 28.1712 18.1163 31.1973L22.7172 34.8958C23.9188 35.863 22.4873 37.7517 21.2857 36.7845L18.1002 34.2234L18.0679 38.8227C18.0599 40.4 15.7332 40.3876 15.7413 38.8144L15.7695 34.24L12.6041 36.7887C11.4025 37.7559 9.97105 35.8672 11.1727 34.9L15.7897 31.1848L15.8502 22.1564C13.3017 23.6508 10.7332 25.0331 8.17667 26.5274L7.36618 32.4799C7.15247 34.0366 4.85002 33.7003 5.06373 32.1479L5.62825 28.0259L1.74514 30.3007C0.414476 31.0852 -0.738765 29.0014 0.587865 28.221L4.45082 25.9629L0.720931 24.4063C-0.694409 23.8168 0.176569 21.5961 1.59191 22.1855L7.02746 24.4478L14.6526 19.9855C12.1203 18.4579 9.67266 16.8598 7.14037 15.3281L1.72498 17.5821Z" fill="white"/>
    </svg>
  </span>
  <span style="width: 70px; display: flex; justify-content: center;" v-else-if="type === 'portable-offices'">
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.75 11.8125H6.25C5.55964 11.8125 5 12.3721 5 13.0625V33.0625C5 33.7529 5.55964 34.3125 6.25 34.3125H33.75C34.4404 34.3125 35 33.7529 35 33.0625V13.0625C35 12.3721 34.4404 11.8125 33.75 11.8125Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M26.25 11.8125V9.3125C26.25 8.64946 25.9866 8.01357 25.5178 7.54473C25.0489 7.07589 24.413 6.8125 23.75 6.8125H16.25C15.587 6.8125 14.9511 7.07589 14.4822 7.54473C14.0134 8.01357 13.75 8.64946 13.75 9.3125V11.8125" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M35 20.2969C30.4411 22.9335 25.2664 24.3188 20 24.3125C14.7326 24.3262 9.55605 22.9404 5 20.2969" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.125 19.3125H21.875" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </span>
  <span style="width: 70px; display: flex; justify-content: center;" v-else-if="type === 'container-modifications'">
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 28.0625C24.1421 28.0625 27.5 24.7046 27.5 20.5625C27.5 16.4204 24.1421 13.0625 20 13.0625C15.8579 13.0625 12.5 16.4204 12.5 20.5625C12.5 24.7046 15.8579 28.0625 20 28.0625Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28.7031 10.7344C29.099 11.099 29.474 11.474 29.8281 11.8594L34.0938 12.4688C34.7888 13.6758 35.3245 14.9678 35.6875 16.3125L33.0938 19.7656C33.0938 19.7656 33.1406 20.8281 33.0938 21.3594L35.6875 24.8125C35.3262 26.1578 34.7904 27.45 34.0938 28.6563L29.8281 29.2656C29.8281 29.2656 29.0938 30.0313 28.7031 30.3906L28.0938 34.6563C26.8867 35.3513 25.5947 35.887 24.25 36.25L20.7969 33.6563C20.2667 33.7031 19.7333 33.7031 19.2031 33.6563L15.75 36.25C14.4047 35.8887 13.1125 35.3529 11.9063 34.6563L11.2969 30.3906C10.9115 30.026 10.5365 29.651 10.1719 29.2656L5.90625 28.6563C5.21122 27.4492 4.67551 26.1572 4.3125 24.8125L6.90625 21.3594C6.90625 21.3594 6.85938 20.2969 6.90625 19.7656L4.3125 16.3125C4.67383 14.9672 5.20963 13.675 5.90625 12.4688L10.1719 11.8594C10.5365 11.474 10.9115 11.099 11.2969 10.7344L11.9063 6.46875C13.1133 5.77372 14.4053 5.23801 15.75 4.875L19.2031 7.46875C19.7333 7.42187 20.2667 7.42187 20.7969 7.46875L24.25 4.875C25.5953 5.23633 26.8875 5.77213 28.0938 6.46875L28.7031 10.7344Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </span>
  <span style="width: 70px; display: flex; justify-content: center;" v-else-if="type === 'container-accessories'">
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.375 27.3908V12.6096C34.3738 12.387 34.314 12.1687 34.2015 11.9767C34.089 11.7847 33.9279 11.6257 33.7344 11.5158L20.6094 4.09395C20.4241 3.98698 20.2139 3.93066 20 3.93066C19.7861 3.93066 19.5759 3.98698 19.3906 4.09395L6.26562 11.5158C6.07211 11.6257 5.91099 11.7847 5.79852 11.9767C5.68605 12.1687 5.6262 12.387 5.625 12.6096V27.3908C5.6262 27.6134 5.68605 27.8316 5.79852 28.0237C5.91099 28.2157 6.07211 28.3747 6.26562 28.4846L19.3906 35.9064C19.5759 36.0134 19.7861 36.0697 20 36.0697C20.2139 36.0697 20.4241 36.0134 20.6094 35.9064L33.7344 28.4846C33.9279 28.3747 34.089 28.2157 34.2015 28.0237C34.314 27.8316 34.3738 27.6134 34.375 27.3908V27.3908Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20 25.625C23.1066 25.625 25.625 23.1066 25.625 20C25.625 16.8934 23.1066 14.375 20 14.375C16.8934 14.375 14.375 16.8934 14.375 20C14.375 23.1066 16.8934 25.625 20 25.625Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    type: String
  }
}
</script>
