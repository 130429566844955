<template>
  <div class="checkout-form-container checkout-form">
    <div class="check-info my-5">
      <icon type="check-item" class="check-item" />
      <span class="mb-2">Delivery Address</span>
    </div>
    <section class="my-body-section">
      <delivery-form ref="deliveryAddress"> </delivery-form>
    </section>
    <div class="check-info my-5">
      <icon type="check-item" class="check-item" />
      <span class="mb-2">Payment Information</span>
    </div>
    <div class="is-flex is-align-items-center mb-2">
      <icon type="warning-circle" />
      <p class="ml-2 neutral-gray" style="font-size: 0.6875rem;">
        A hold will be placed on your card to complete the reservation. You will not be changed
        in full until our team reviews the order and confirms delivery information.
      </p>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import Icon from './icon.vue'
import DeliveryForm from '../../flow/steps/helpers/delivery-form.vue'
import Step from '../../flow/steps/mixin'

export default {
  mixins: [Step],
  data () {
    return {
      customer: {}
    }
  },
  components: {
    Icon,
    DeliveryForm
  },
  computed: {
    ...mapState('quote', ['quote'])
  },
  mounted () {
    if (this.quote) {
      this.updates = this.quote.customer
    } else if (this.lead) {
      const { firstName, lastName, email, phone } = this.lead
      //this.customer = {}
      this.updates = {
        ...this.updates,
        firstName,
        lastName,
        email,
        phone
      }
    }
  },
  methods: {   
    async save() {
      await this.saveDeliveryInfo()
      await this.updateCustomerInfo()      
    },
    saveDeliveryInfo() {
      const { line1, line2, locality, region } = this.$refs.deliveryAddress.deliveryAddress
      
      if (!line1 || !line2 || !locality || !region) {
        return
      }
      
      return this.$store.dispatch('quote/quote.save', {
        eventName: 'delivery_address.add',
        input: {
          deliveryAddress: this.$refs.deliveryAddress.deliveryAddress
        }
      })
    },
    updateCustomerInfo() {
      let companyName = this.$refs.deliveryAddress ? this.$refs.deliveryAddress.customer.companyName : ''
      return this.$store.dispatch('quote/quote.save', {
        eventName: 'customer.update',
        input: {
          customer: {
            ...this.quote.customer,           
            companyName: companyName || ''
          }
        }
      })
    }
  }
}
</script>

<style>
  @import '../../flow/styles/checkout-form.scss';
</style>
<style scoped lang="scss">
  .check-info {
    display: flex;
    align-items: center;

    .check-item {
      margin-right: 12px;
    }

    span {
      color: #767676;
      font-size: 0.875rem;
      font-weight: 600;
    }
  }
</style>