<template>
  <article>
    <div class="columns is-vcentered is-mobile p-3">
      <div class="column is-3 p-0 m-0">
        <figure v-if="product.primaryPhoto" class="image product-figure">
          <img :src="imageUrl(product.primaryPhoto.key, { resize: { width: 180, height: 130 } } )">
        </figure>
      </div>
      <div class="column product-details">
        <h5 class="product-title text-sm has-text-weight-semibold is-size-6-mobile mb-1 neutral-black">
          {{ product.name }}
        </h5>
        <!--<p class="is-hidden-mobile subtitle is-6 mb-2 neutral-gray">
          {{ product.code }}
        </p>-->
        <p v-if="pricingActive" class="text-sm neutral-gray is-size-7-mobile mb-1">
          <span v-if="product.classification === 'container_rental'">
            <!--from-->
            {{ dollars(priceBookEntry.priceCents) }}/month
            <!--<i>&plus; Tax, Delivery  &amp; Pickup</i>-->
          </span>
          <span v-else>
            <!--from-->
            {{ dollars(priceBookEntry.priceCents) }}
            <!--<i class="is-size-7">&plus; Tax &amp; Delivery</i>-->
          </span>
        </p>
        <div class="view-product-container">
          <a :href="productURL()" class="text-sm link-color is-size-7-mobile is-underlined">
            View Product Page
          </a>
          <quote-quantity :price-book-entry="priceBookEntry" />
        </div>
      </div>     
    </div>
  </article>
</template>

<script>
import { imageUrl } from 'shared/s3'
import { dollars } from 'shared/filters'
import QuoteQuantity from './quote-quantity.vue'
import { PRICING_ENABLED } from 'shared/lead-helper'
import { mapState } from 'vuex'
export default {
  components: {
    QuoteQuantity
  },
  props: {
    priceBookEntry: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeDetails: false,
      product: this.priceBookEntry.product,
      pricingActive: PRICING_ENABLED && this.priceBookEntry.priceCents && this.priceBookEntry.priceVisibility != 'private'
    }
  },
  methods: {
    dollars,
    imageUrl,
    productURL() {
      let url = '/partners/' + this.organization.id + '/container-'
      url += this.product.rentalType == 'none' ? 'sales' : 'rentals'
      url += '/' + this.product.slug

      return url
    }
  },
  computed: {
    ...mapState('quote', [ 'organization' ])
  }
}
</script>

<style lang="scss" scoped>
.product-title {
  line-height: 1.2;
}
.details-toggle-button {
  margin-top: 0.5rem;
}
.product-figure {
  display: flex;
  align-items: center;
  max-height: 130px;
}

.product-details {
    @media (max-width: 874px) {
      flex: 1;
    }
  }
  .view-product-container {
    justify-content: space-between;
    display: flex;
    @media (max-width: 450px) {
      display: block;
      div {
        justify-content: flex-start;
        display: flex;
      }
    }
  }
</style>
