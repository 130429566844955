<template>
  <span style="width: 56px; display: flex; justify-content: center;" v-if="type === 'container-sales'">
    <svg width="96" height="56" viewBox="0 0 96 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51.7262 51.0277C52.4576 51.0277 53.0521 50.427 53.0521 49.6882V34.4488C53.0521 33.71 52.4576 33.1094 51.7262 33.1094C50.9949 33.1094 50.4004 33.71 50.4004 34.4488V49.6977C50.4051 50.4366 50.9902 51.0277 51.7262 51.0277Z"
        fill="currentColor" />
      <path
        d="M46.9235 50.9846C47.6548 50.9846 48.2493 50.3839 48.2493 49.6451V33.8385C48.2493 33.0996 47.6548 32.499 46.9235 32.499C46.1922 32.499 45.5977 33.0996 45.5977 33.8385V49.6594C45.5977 50.3935 46.1922 50.9846 46.9235 50.9846Z"
        fill="currentColor" />
      <path
        d="M24.9274 50.7945C25.6587 50.7945 26.2532 50.1939 26.2532 49.455V30.7026C26.2532 29.9637 25.6587 29.3631 24.9274 29.3631C24.1961 29.3631 23.6016 29.9637 23.6016 30.7026V49.4503C23.6016 50.1939 24.1866 50.7945 24.9274 50.7945Z"
        fill="currentColor" />
      <path
        d="M57.2422 29.8352L32.4004 15.6922C33.6413 14.5339 34.434 12.8798 34.434 11.0351C34.434 8.0034 32.3061 5.46272 29.494 4.84304V1.33946C29.494 0.600613 28.8995 0 28.1681 0C27.4368 0 26.8423 0.600613 26.8423 1.33946V7.38372H28.1681C30.1592 7.38372 31.787 9.02826 31.787 11.0398C31.787 12.4317 31.0132 13.6425 29.8809 14.2622L29.1495 13.8522H29.1401C28.7532 13.6425 28.2766 13.6187 27.8662 13.8427L26.7904 14.4338C26.0591 14.1287 25.4362 13.5996 25.0305 12.8941C24.6672 12.2506 23.8509 12.0409 23.2187 12.4079C22.5817 12.7749 22.3741 13.5996 22.7374 14.2383C23.1007 14.8771 23.582 15.43 24.134 15.8924L0.688869 28.7627C0.679432 28.7722 0.669996 28.7817 0.655841 28.7817C0.259505 29.0153 0 29.4491 0 29.9305V54.6605C0 55.3994 0.594503 56 1.32584 56H19.2978C19.3591 56 19.4346 56 19.4959 55.9905H56.6099C57.3412 55.9905 57.9357 55.3899 57.9357 54.651V31.0078C57.9216 30.5121 57.6479 30.0688 57.2422 29.8352ZM18.6608 23.729L12.0599 25.5594L22.2042 19.9871L18.6608 23.729ZM17.9625 26.6891V53.3258H10.0594V28.8914L17.9625 26.6891ZM28.1351 17.3653C28.1445 17.3653 28.154 17.3653 28.1681 17.3653C28.4371 17.3653 28.7107 17.3463 28.9702 17.3034L38.7701 26.7702L21.9542 24.0626L28.1351 17.3653ZM43.1911 27.49C43.1203 27.3327 43.026 27.1849 42.8892 27.0562L37.0385 21.4123L49.5089 28.51L43.1911 27.49ZM2.64695 30.9554L7.42185 29.6302V53.3258H2.64695V30.9554ZM55.2746 53.3115H20.6094V26.5604L55.2746 32.1423V53.3115Z"
        fill="currentColor" />
      <path
        d="M31.0465 50.8481C31.7779 50.8481 32.3724 50.2475 32.3724 49.5086V31.5856C32.3724 30.8467 31.7779 30.2461 31.0465 30.2461C30.3152 30.2461 29.7207 30.8467 29.7207 31.5856V49.5134C29.7207 50.2475 30.3152 50.8481 31.0465 50.8481Z"
        fill="currentColor" />
      <path
        d="M36.2868 50.9004C37.0181 50.9004 37.6126 50.2998 37.6126 49.5609V32.4625C37.6126 31.7237 37.0181 31.123 36.2868 31.123C35.5554 31.123 34.9609 31.7237 34.9609 32.4625V49.5657C34.9657 50.3045 35.5602 50.9004 36.2868 50.9004Z"
        fill="currentColor" />
      <path
        d="M41.6071 50.9516C42.3384 50.9516 42.9329 50.351 42.9329 49.6121V33.2145C42.9329 32.4756 42.3384 31.875 41.6071 31.875C40.8758 31.875 40.2812 32.4756 40.2812 33.2145V49.6121C40.2812 50.3558 40.8758 50.9516 41.6071 50.9516Z"
        fill="currentColor" />
      <path
        d="M12.4704 43.835C13.2017 43.835 13.7962 43.2344 13.7962 42.4955V39.44C13.7962 38.7012 13.2017 38.1006 12.4704 38.1006C11.739 38.1006 11.1445 38.7012 11.1445 39.44V42.4955C11.1445 43.2344 11.739 43.835 12.4704 43.835Z"
        fill="currentColor" />
      <path
        d="M5.37857 39.0831C4.64724 39.0831 4.05273 39.6837 4.05273 40.4226V42.4961C4.05273 43.235 4.64724 43.8356 5.37857 43.8356C6.1099 43.8356 6.70441 43.235 6.70441 42.4961V40.4226C6.70441 39.6742 6.1099 39.0831 5.37857 39.0831Z"
        fill="currentColor" />
      <path
        d="M79.2729 3.23738L69.1854 5.24988L67.1729 15.3374C67.1414 15.4984 67.15 15.6648 67.1979 15.8218C67.2459 15.9787 67.3318 16.1215 67.4479 16.2374L80.4979 29.2874C80.5899 29.3815 80.6998 29.4563 80.821 29.5073C80.9423 29.5584 81.0726 29.5847 81.2042 29.5847C81.3358 29.5847 81.466 29.5584 81.5873 29.5073C81.7086 29.4563 81.8185 29.3815 81.9104 29.2874L93.2229 17.9749C93.317 17.8829 93.3918 17.7731 93.4429 17.6518C93.4939 17.5305 93.5202 17.4002 93.5202 17.2686C93.5202 17.137 93.4939 17.0068 93.4429 16.8855C93.3918 16.7642 93.317 16.6544 93.2229 16.5624L80.1729 3.51239C80.057 3.39621 79.9143 3.31036 79.7573 3.2624C79.6003 3.21444 79.434 3.20585 79.2729 3.23738V3.23738Z"
        stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M74.4355 12C75.264 12 75.9355 11.3284 75.9355 10.5C75.9355 9.67157 75.264 9 74.4355 9C73.6071 9 72.9355 9.67157 72.9355 10.5C72.9355 11.3284 73.6071 12 74.4355 12Z"
        fill="currentColor" />
    </svg>
  </span>
  <span style="width: 56px; display: flex; justify-content: center;" v-else-if="type === 'container-rentals'">
    <svg width="96" height="56" viewBox="0 0 96 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51.7262 51.0277C52.4576 51.0277 53.0521 50.427 53.0521 49.6882V34.4488C53.0521 33.71 52.4576 33.1094 51.7262 33.1094C50.9949 33.1094 50.4004 33.71 50.4004 34.4488V49.6977C50.4051 50.4366 50.9902 51.0277 51.7262 51.0277Z"
        fill="currentColor" />
      <path
        d="M46.9235 50.9846C47.6548 50.9846 48.2493 50.3839 48.2493 49.6451V33.8385C48.2493 33.0996 47.6548 32.499 46.9235 32.499C46.1922 32.499 45.5977 33.0996 45.5977 33.8385V49.6594C45.5977 50.3935 46.1922 50.9846 46.9235 50.9846Z"
        fill="currentColor" />
      <path
        d="M24.9274 50.7945C25.6587 50.7945 26.2532 50.1939 26.2532 49.455V30.7026C26.2532 29.9637 25.6587 29.3631 24.9274 29.3631C24.1961 29.3631 23.6016 29.9637 23.6016 30.7026V49.4503C23.6016 50.1939 24.1866 50.7945 24.9274 50.7945Z"
        fill="currentColor" />
      <path
        d="M57.2422 29.8352L32.4004 15.6922C33.6413 14.5339 34.434 12.8798 34.434 11.0351C34.434 8.0034 32.3061 5.46272 29.494 4.84304V1.33946C29.494 0.600613 28.8995 0 28.1681 0C27.4368 0 26.8423 0.600613 26.8423 1.33946V7.38372H28.1681C30.1592 7.38372 31.787 9.02826 31.787 11.0398C31.787 12.4317 31.0132 13.6425 29.8809 14.2622L29.1495 13.8522H29.1401C28.7532 13.6425 28.2766 13.6187 27.8662 13.8427L26.7904 14.4338C26.0591 14.1287 25.4362 13.5996 25.0305 12.8941C24.6672 12.2506 23.8509 12.0409 23.2187 12.4079C22.5817 12.7749 22.3741 13.5996 22.7374 14.2383C23.1007 14.8771 23.582 15.43 24.134 15.8924L0.688869 28.7627C0.679432 28.7722 0.669996 28.7817 0.655841 28.7817C0.259505 29.0153 0 29.4491 0 29.9305V54.6605C0 55.3994 0.594503 56 1.32584 56H19.2978C19.3591 56 19.4346 56 19.4959 55.9905H56.6099C57.3412 55.9905 57.9357 55.3899 57.9357 54.651V31.0078C57.9216 30.5121 57.6479 30.0688 57.2422 29.8352ZM18.6608 23.729L12.0599 25.5594L22.2042 19.9871L18.6608 23.729ZM17.9625 26.6891V53.3258H10.0594V28.8914L17.9625 26.6891ZM28.1351 17.3653C28.1445 17.3653 28.154 17.3653 28.1681 17.3653C28.4371 17.3653 28.7107 17.3463 28.9702 17.3034L38.7701 26.7702L21.9542 24.0626L28.1351 17.3653ZM43.1911 27.49C43.1203 27.3327 43.026 27.1849 42.8892 27.0562L37.0385 21.4123L49.5089 28.51L43.1911 27.49ZM2.64695 30.9554L7.42185 29.6302V53.3258H2.64695V30.9554ZM55.2746 53.3115H20.6094V26.5604L55.2746 32.1423V53.3115Z"
        fill="currentColor" />
      <path
        d="M31.0465 50.8481C31.7779 50.8481 32.3724 50.2475 32.3724 49.5086V31.5856C32.3724 30.8467 31.7779 30.2461 31.0465 30.2461C30.3152 30.2461 29.7207 30.8467 29.7207 31.5856V49.5134C29.7207 50.2475 30.3152 50.8481 31.0465 50.8481Z"
        fill="currentColor" />
      <path
        d="M36.2868 50.9004C37.0181 50.9004 37.6126 50.2998 37.6126 49.5609V32.4625C37.6126 31.7237 37.0181 31.123 36.2868 31.123C35.5554 31.123 34.9609 31.7237 34.9609 32.4625V49.5657C34.9657 50.3045 35.5602 50.9004 36.2868 50.9004Z"
        fill="currentColor" />
      <path
        d="M41.6071 50.9516C42.3384 50.9516 42.9329 50.351 42.9329 49.6121V33.2145C42.9329 32.4756 42.3384 31.875 41.6071 31.875C40.8758 31.875 40.2812 32.4756 40.2812 33.2145V49.6121C40.2812 50.3558 40.8758 50.9516 41.6071 50.9516Z"
        fill="currentColor" />
      <path
        d="M12.4704 43.835C13.2017 43.835 13.7962 43.2344 13.7962 42.4955V39.44C13.7962 38.7012 13.2017 38.1006 12.4704 38.1006C11.739 38.1006 11.1445 38.7012 11.1445 39.44V42.4955C11.1445 43.2344 11.739 43.835 12.4704 43.835Z"
        fill="currentColor" />
      <path
        d="M5.37857 39.0831C4.64724 39.0831 4.05273 39.6837 4.05273 40.4226V42.4961C4.05273 43.235 4.64724 43.8356 5.37857 43.8356C6.1099 43.8356 6.70441 43.235 6.70441 42.4961V40.4226C6.70441 39.6742 6.1099 39.0831 5.37857 39.0831Z"
        fill="currentColor" />
      <path
        d="M79.9355 28C86.563 28 91.9355 22.6274 91.9355 16C91.9355 9.37258 86.563 4 79.9355 4C73.3081 4 67.9355 9.37258 67.9355 16C67.9355 22.6274 73.3081 28 79.9355 28Z"
        stroke="currentColor" stroke-width="2.5" stroke-miterlimit="10" />
      <path d="M79.9355 9V16H86.9355" stroke="currentColor" stroke-width="2.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  </span>
  <span style="width: 56px; display: flex; justify-content: center;" v-else-if="type === 'refrigerated-container'">
    <svg width="48" height="56" viewBox="0 0 48 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.41497 24.6149C0.439135 25.4343 -0.785881 22.3252 1.1956 21.5058L6.44566 19.3207L1.04882 16.0663C-0.802816 14.9505 0.839947 12.0565 2.68594 13.1664L8.05455 16.4034L7.26987 10.6675C6.97067 8.48827 10.1997 8.02336 10.4933 10.2026L11.6336 18.5652L22.2241 24.9577C22.2523 20.7329 22.4217 16.5603 22.4499 12.3238L16.0087 7.14584C14.3264 5.79179 16.3305 3.14761 18.0127 4.50166L22.4725 8.08729L22.512 1.64827C22.5233 -0.560054 25.7806 -0.54262 25.7693 1.6599L25.7298 8.06404L30.1613 4.49585C31.8436 3.1418 33.8476 5.78598 32.1654 7.14003L25.7016 12.3412L25.6169 24.981C29.1734 22.9005 32.8033 20.9421 36.3598 18.8674L37.4944 10.5339C37.7936 8.35461 41.0171 8.82533 40.7179 10.9988L39.9332 16.7695L45.3695 13.5849C47.2325 12.4865 48.847 15.4038 46.9897 16.4964L41.5816 19.6578L46.7978 21.8254C48.7793 22.6506 47.5543 25.7597 45.5784 24.9345L37.9686 21.7731L27.3048 28.0262C30.85 30.1647 34.271 32.4021 37.8219 34.5465L45.3978 31.3968C47.3793 30.5715 48.5986 33.6806 46.6171 34.5059L41.3671 36.6909L46.7639 39.9453C48.6156 41.0611 46.9728 43.9552 45.1268 42.8452L39.7582 39.6082L40.5429 45.3441C40.8421 47.5234 37.613 47.9883 37.3138 45.809L36.1791 37.4348L25.5887 31.0423C25.5604 35.2671 25.3911 39.4397 25.3628 43.6762L31.8041 48.8542C33.4863 50.2082 31.4823 52.8524 29.8 51.4983L25.3403 47.9127L25.2951 54.3517C25.2838 56.5601 22.0265 56.5426 22.0378 54.3401L22.0773 47.936L17.6458 51.5042C15.9635 52.8582 13.9595 50.214 15.6417 48.86L22.1055 43.6588L22.1902 31.019C18.6224 33.1111 15.0264 35.0463 11.4473 37.1384L10.3126 45.4719C10.0135 47.6512 6.79002 47.1805 7.08922 45.007L7.87955 39.2363L2.44319 42.421C0.580266 43.5193 -1.03427 40.602 0.823011 39.5095L6.23115 36.3481L1.0093 34.1688C-0.972173 33.3436 0.247197 30.2345 2.22867 31.0597L9.83845 34.2269L20.5136 27.9797C16.9684 25.8411 13.5417 23.6037 9.99652 21.4593L2.41497 24.6149Z"
        fill="currentColor" />
    </svg>
  </span>
  <span style="width: 56px; display: flex; justify-content: center;" v-else-if="type === 'portable-offices'">
    <svg width="96" height="56" viewBox="0 0 96 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M51.7262 51.0277C52.4576 51.0277 53.0521 50.427 53.0521 49.6882V34.4488C53.0521 33.71 52.4576 33.1094 51.7262 33.1094C50.9949 33.1094 50.4004 33.71 50.4004 34.4488V49.6977C50.4051 50.4366 50.9902 51.0277 51.7262 51.0277Z"
        fill="currentColor" />
      <path
        d="M46.9235 50.9846C47.6548 50.9846 48.2493 50.3839 48.2493 49.6451V33.8385C48.2493 33.0996 47.6548 32.499 46.9235 32.499C46.1922 32.499 45.5977 33.0996 45.5977 33.8385V49.6594C45.5977 50.3935 46.1922 50.9846 46.9235 50.9846Z"
        fill="currentColor" />
      <path
        d="M24.9274 50.7945C25.6587 50.7945 26.2532 50.1939 26.2532 49.455V30.7026C26.2532 29.9637 25.6587 29.3631 24.9274 29.3631C24.1961 29.3631 23.6016 29.9637 23.6016 30.7026V49.4503C23.6016 50.1939 24.1866 50.7945 24.9274 50.7945Z"
        fill="currentColor" />
      <path
        d="M57.2422 29.8352L32.4004 15.6922C33.6413 14.5339 34.434 12.8798 34.434 11.0351C34.434 8.0034 32.3061 5.46272 29.494 4.84304V1.33946C29.494 0.600613 28.8995 0 28.1681 0C27.4368 0 26.8423 0.600613 26.8423 1.33946V7.38372H28.1681C30.1592 7.38372 31.787 9.02826 31.787 11.0398C31.787 12.4317 31.0132 13.6425 29.8809 14.2622L29.1495 13.8522H29.1401C28.7532 13.6425 28.2766 13.6187 27.8662 13.8427L26.7904 14.4338C26.0591 14.1287 25.4362 13.5996 25.0305 12.8941C24.6672 12.2506 23.8509 12.0409 23.2187 12.4079C22.5817 12.7749 22.3741 13.5996 22.7374 14.2383C23.1007 14.8771 23.582 15.43 24.134 15.8924L0.688869 28.7627C0.679432 28.7722 0.669996 28.7817 0.655841 28.7817C0.259505 29.0153 0 29.4491 0 29.9305V54.6605C0 55.3994 0.594503 56 1.32584 56H19.2978C19.3591 56 19.4346 56 19.4959 55.9905H56.6099C57.3412 55.9905 57.9357 55.3899 57.9357 54.651V31.0078C57.9216 30.5121 57.6479 30.0688 57.2422 29.8352ZM18.6608 23.729L12.0599 25.5594L22.2042 19.9871L18.6608 23.729ZM17.9625 26.6891V53.3258H10.0594V28.8914L17.9625 26.6891ZM28.1351 17.3653C28.1445 17.3653 28.154 17.3653 28.1681 17.3653C28.4371 17.3653 28.7107 17.3463 28.9702 17.3034L38.7701 26.7702L21.9542 24.0626L28.1351 17.3653ZM43.1911 27.49C43.1203 27.3327 43.026 27.1849 42.8892 27.0562L37.0385 21.4123L49.5089 28.51L43.1911 27.49ZM2.64695 30.9554L7.42185 29.6302V53.3258H2.64695V30.9554ZM55.2746 53.3115H20.6094V26.5604L55.2746 32.1423V53.3115Z"
        fill="currentColor" />
      <path
        d="M31.0465 50.8481C31.7779 50.8481 32.3724 50.2475 32.3724 49.5086V31.5856C32.3724 30.8467 31.7779 30.2461 31.0465 30.2461C30.3152 30.2461 29.7207 30.8467 29.7207 31.5856V49.5134C29.7207 50.2475 30.3152 50.8481 31.0465 50.8481Z"
        fill="currentColor" />
      <path
        d="M36.2868 50.9004C37.0181 50.9004 37.6126 50.2998 37.6126 49.5609V32.4625C37.6126 31.7237 37.0181 31.123 36.2868 31.123C35.5554 31.123 34.9609 31.7237 34.9609 32.4625V49.5657C34.9657 50.3045 35.5602 50.9004 36.2868 50.9004Z"
        fill="currentColor" />
      <path
        d="M41.6071 50.9516C42.3384 50.9516 42.9329 50.351 42.9329 49.6121V33.2145C42.9329 32.4756 42.3384 31.875 41.6071 31.875C40.8758 31.875 40.2812 32.4756 40.2812 33.2145V49.6121C40.2812 50.3558 40.8758 50.9516 41.6071 50.9516Z"
        fill="currentColor" />
      <path
        d="M12.4704 43.835C13.2017 43.835 13.7962 43.2344 13.7962 42.4955V39.44C13.7962 38.7012 13.2017 38.1006 12.4704 38.1006C11.739 38.1006 11.1445 38.7012 11.1445 39.44V42.4955C11.1445 43.2344 11.739 43.835 12.4704 43.835Z"
        fill="currentColor" />
      <path
        d="M5.37857 39.0831C4.64724 39.0831 4.05273 39.6837 4.05273 40.4226V42.4961C4.05273 43.235 4.64724 43.8356 5.37857 43.8356C6.1099 43.8356 6.70441 43.235 6.70441 42.4961V40.4226C6.70441 39.6742 6.1099 39.0831 5.37857 39.0831Z"
        fill="currentColor" />
      <path
        d="M90.9355 9H68.9355C68.3833 9 67.9355 9.44772 67.9355 10V26C67.9355 26.5523 68.3833 27 68.9355 27H90.9355C91.4878 27 91.9355 26.5523 91.9355 26V10C91.9355 9.44772 91.4878 9 90.9355 9Z"
        stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M84.9355 9V7C84.9355 6.46957 84.7248 5.96086 84.3498 5.58579C83.9747 5.21071 83.466 5 82.9355 5H76.9355C76.4051 5 75.8964 5.21071 75.5213 5.58579C75.1463 5.96086 74.9355 6.46957 74.9355 7V9"
        stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M91.9355 15.7878C88.2884 17.897 84.1487 19.0053 79.9355 19.0002C75.7216 19.0112 71.5804 17.9026 67.9355 15.7878"
        stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M78.4355 15H81.4355" stroke="currentColor" stroke-width="2.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  </span>
  <span style="width: 56px; display: flex; justify-content: center;" v-else-if="type === 'container-modifications'">
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 38.5C33.799 38.5 38.5 33.799 38.5 28C38.5 22.201 33.799 17.5 28 17.5C22.201 17.5 17.5 22.201 17.5 28C17.5 33.799 22.201 38.5 28 38.5Z"
        stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M40.1859 14.2402C40.7401 14.7507 41.2651 15.2757 41.7609 15.8152L47.7328 16.6684C48.7059 18.3582 49.4558 20.167 49.9641 22.0496L46.3328 26.884C46.3328 26.884 46.3984 28.3715 46.3328 29.1152L49.9641 33.9496C49.4582 35.833 48.7081 37.6421 47.7328 39.3309L41.7609 40.184C41.7609 40.184 40.7328 41.2559 40.1859 41.759L39.3328 47.7309C37.643 48.7039 35.8342 49.4539 33.9516 49.9621L29.1172 46.3309C28.3749 46.3965 27.6282 46.3965 26.8859 46.3309L22.0516 49.9621C20.1682 49.4562 18.3591 48.7061 16.6703 47.7309L15.8172 41.759C15.2776 41.2486 14.7526 40.7236 14.2422 40.184L8.27031 39.3309C7.29727 37.641 6.54728 35.8322 6.03906 33.9496L9.67031 29.1152C9.67031 29.1152 9.60469 27.6277 9.67031 26.884L6.03906 22.0496C6.54493 20.1662 7.29504 18.3571 8.27031 16.6684L14.2422 15.8152C14.7526 15.2757 15.2776 14.7507 15.8172 14.2402L16.6703 8.26836C18.3602 7.29532 20.169 6.54533 22.0516 6.03711L26.8859 9.66836C27.6282 9.60272 28.3749 9.60272 29.1172 9.66836L33.9516 6.03711C35.835 6.54297 37.6441 7.29309 39.3328 8.26836L40.1859 14.2402Z"
        stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </span>
  <span style="width: 56px; display: flex; justify-content: center;" v-else-if="type === 'container-accessories'">
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48.125 38.3471V17.6534C48.1233 17.3418 48.0395 17.0362 47.8821 16.7674C47.7246 16.4986 47.499 16.276 47.2281 16.1221L28.8531 5.73152C28.5937 5.58177 28.2995 5.50293 28 5.50293C27.7005 5.50293 27.4063 5.58177 27.1469 5.73152L8.77187 16.1221C8.50095 16.276 8.27539 16.4986 8.11793 16.7674C7.96047 17.0362 7.87667 17.3418 7.875 17.6534V38.3471C7.87667 38.6587 7.96047 38.9643 8.11793 39.2331C8.27539 39.502 8.50095 39.7245 8.77187 39.8784L27.1469 50.269C27.4063 50.4188 27.7005 50.4976 28 50.4976C28.2995 50.4976 28.5937 50.4188 28.8531 50.269L47.2281 39.8784C47.499 39.7245 47.7246 39.502 47.8821 39.2331C48.0395 38.9643 48.1233 38.6587 48.125 38.3471V38.3471Z"
        stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M28 35.875C32.3492 35.875 35.875 32.3492 35.875 28C35.875 23.6508 32.3492 20.125 28 20.125C23.6508 20.125 20.125 23.6508 20.125 28C20.125 32.3492 23.6508 35.875 28 35.875Z"
        stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    type: String
  }
}
</script>
