
<confirmation v-if="lead" :lead="lead" @close="close"></confirmation>
<modal v-else @close="close">
  <div slot="header">
    <h4 class="title is-4">Contact Us</h4>
    <p class="subtitle is-size-6">
      Please let us know more information and a representative will get back to you shortly.
    </p>
  </div>
  <div slot="body">
    <lead-form class="lead-form" :product="detail" @success="handleSuccess" />
  </div>
</modal>
