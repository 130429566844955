<template>
  <div>
    <!--<location-changed v-if="locationChanged"></location-changed>-->
    <price :product="product" :changeZipCode="changeZipCode" :brand_color="brand_color" :organization_id="organization_id"></price>
    <lead-modal v-if="modalOpen" :product="product" @close="modalOpen = false"
      :redirectType="redirectType"></lead-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
//import LocationChanged from '../location-changed.vue'
import Price from './price.vue'
import LeadModal from './lead-modal.vue'
export default {
  components: {   
    Price,
    LeadModal
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    brand_color: {
      type: String,
      required: false,
      default: () => 'white'
    },
    organization_id: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      modalOpen: false,
      redirectType: ''
    }
  },
  methods: {
    changeZipCode(type) {
      this.redirectType = type
      this.modalOpen = true
    }
  },
  computed: {
    ...mapGetters(['locationChanged']),
    ...mapState('shared', ['zipcode']),
    ...mapState('quote', ['lead', 'organization'])
  }
}
</script>

<style scoped>
.quote {
  padding: 1em;
  border: 1px solid blue;
}

.details-button {
  background-color: white;
  color: #0062a8;
  border-radius: 100px;
  padding: 0.5em 1em;
  border: 1px solid #0062a8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.3rem;
  cursor: pointer;
}

.details-button:hover {
  background-color: #0062a8;
  color: #fff;
}

.zipcode-button {
  font-size: 1rem;
  color: #767676;
  font-weight: 600;
  border-color: #DBDBDB;
}

.truck-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  text-align: center;
}
</style>
