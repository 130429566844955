
<carousel class="slider" :class="slides.length == 1 ? 'is-banner-only' : ''" :perPage="1" :autoplay="false" paginationActiveColor="#174984">
  <slide v-for="(slide, index) in slides" :key="index" class="hero slide has-background-dark" :style="`background-image: url(` + slide.data.image.url + `)`">
    <div class="hero-body">
      <!-- Speeds up image rending -->
      <img :src="slide.data.image.url" :alt="slide.data.image.altText ? slide.data.image.altText : slide.data.image.fileName" style="display: none;" />
    </div>
    <div class="hero-foot">
      <div v-if="slide.data.title || slide.data.subtitle" class="hero-slide-content">
        <component v-if="slide.data.title" :is="is_root && index == 0 ? 'h1' : 'h2'" class="title is-3 has-text-white is-size-4-mobile">
          {{ slide.data.title }}
        </component>
        <h4 v-if="slide.data.subtitle" class="subtitle is-size-6 has-text-white is-size-7-mobile">
          {{ slide.data.subtitle }}
        </h4>
      </div>
    </div>
  </slide>
</carousel>
