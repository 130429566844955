
<div>
  <fieldset class="fieldset">
    <p class="neutral-gray has-text-weight-semibold text-xs mb-5">
      Select a financial option
    </p>
    <div class="field">
      <div class="control radio-list">
        <label v-for="(label, value) in stepOptions" :key="value" class="radio is-flex" style="align-items: center">
          <input v-model="updates.purchaseType" type="radio" :value="value">
          <span class="total ml-1 mr-1">
            {{ label }}
          </span>
         <b-tooltip  position="is-right">                 
          <span v-if="value == 'sale'" class="link-color">
            <icon type="warning-circle" />
          </span>
          <template v-slot:content>
            <span>If purchasing, modifications will be available</span>
          </template>      
        </b-tooltip>
        </label>
      </div>
       
      <div v-if="updates.purchaseType == 'rental'" class="control radio-list">
        <p class="neutral-gray has-text-weight-semibold text-sm mb-5">
          How long will you want the container for?
        </p>
        <validation-provider rules="required" tag="div" class="radio-labels">
          <label v-for="(label, value) in rentalTime" :key="value" class="radio">
            <input v-model="updates.rentalTime" type="radio" :value="value" />
            {{ label }}
          </label>
        </validation-provider>
      </div>
    </div>
  </fieldset>
</div>
