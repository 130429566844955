
<header class="header" :class="navClass">
  <div class="mobile-overlay" @click="toggleNav"></div>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a id="site-logo" class="navbar-item" href="/">Container Alliance</a>
      <a :href="`tel:${organizationPhone || phoneNumber}}`" class="navbar-item has-text-primary is-show-mobile">{{ formatPhoneNumber(organizationPhone || phoneNumber) }}</a>
      <a @click="toggleNav" class="navbar-burger burger" :class="navClass" role="button" aria-label="menu">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    
    <div class="navbar-menu" :class="navClass">
      <div class="navbar-end">
        <a href="#" @click="openModal('sale')" class="navbar-item has-text-primary has-text-weight-bold">Buy a
          Container</a>
        <a href="#" @click="openModal('rental')" class="navbar-item has-text-primary has-text-weight-bold">Rent a
          Container</a>
        <template v-for="page in pages">
          <div v-if="page.pages" :key="page.slug" class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
              {{ page.name }}
            </a>
            <div class="navbar-dropdown">
              <page-link v-for="page in page.pages" :key="page.slug" :page="page" />
            </div>
          </div>
          <page-link v-else :page="page" :key="page.slug" />
        </template>
      </div>
      <hr class="navbar-divider">
      <a href="#" @click="inventoryModalActive = !inventoryModalActive" class="navbar-item">Find Inventory</a>
      <a :href="`tel:${organizationPhone || phoneNumber}}`" class="navbar-item has-text-primary">{{ formatPhoneNumber(organizationPhone || phoneNumber) }}</a>
      <div class="navbar-item">
        <quote-summary-dropdown v-if="quote" />
        <button v-else class="button is-primary is-fullwidth is-small-tablet" data-vue-init="lead-flow" data-props='{ "gtag": "get_quote"}'>
          Get Quote
        </button>
      </div>
    </div>
  </nav>

  <modal v-if="quote" v-show="showMobileQuote" @close="showMobileQuote = false">
    <quote-summary slot="body" @hide="showMobileQuote = false" />
  </modal>

  <zipcodeModal v-if="inventoryModalActive" @close="inventoryModalActive = false" :solutionSlug="solutionSlug" :isTypeChange="true"/>
  <zipcodeModal v-if="modalOpen" @close="modalOpen = false" :solutionSlug="solutionSlug" />
</header>
