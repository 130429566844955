<script>
import { Carousel, Slide } from 'vue-carousel'
export default {
  components: {
    Carousel,
    Slide
  },
  props: {
    elements: NodeList
  },
  data() {
    return {
      slides: [],
      initialPage: 1
    }
  },
  mounted() {
    setTimeout(() => {
      this.initialPage=0
    }, 100)
  },
  created() {
    this.slides = []
    for (let el of this.elements) {
      el.classList.remove('column')
      el.classList.add('mx-2', 'my-3')
      this.slides.push(el)
    }
  },
  render(h) {
    const slides = this.slides.map((i) =>
      h('slide', { domProps: { innerHTML: i.outerHTML } })
    )
    return h(
      'carousel',
      {
        ref: 'carousel',
        props: {
          navigationEnabled: true,
          paginationEnabled: false,
          scrollPerPage: true,
          perPage: 1,
          navigateTo: this.initialPage
        }
      },
      slides
    )
  }
}
</script>
