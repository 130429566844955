
<div>
  <article v-if="errors && errors.length" class="message is-danger is-size-7-mobile">
    <div class="message-body">{{ errors[0].message }}</div>
  </article>
  <fieldset class="fieldset">
    <p class="neutral-gray has-text-weight-semibold text-xs mb-5">
      What Zip Code we will deliver to?
    </p>      
    <div class="field">
      <label class="label span"><b class="required-star">*</b> Zip Code</label>
      <validated-input
        v-model="updates.zipcode"
        name="Delivery zip"
        ref="zipfield"
        :rules="{ required: true, regex: ZIP_REGEX }"
        :showErrorIcon="true"
      />
      <p v-if="error" class="help is-danger is-size-7-mobile">
        We couldn't find that zip code.
        <a @click.prevent="reset" class="has-text-black">Try a different zipcode?</a>
      </p>
    </div>
    <p class="neutral-gray has-text-weight-semibold text-sm mb-5">
      Tell us about yourself.
    </p> 
    <div class="columns">
      <div class="column field">
        <label class="label span"><b class="required-star">*</b> First Name</label>
        <validated-input
          v-model="updates.firstName"
          name="First Name"
          rules="required"
          :showErrorIcon="true"
        />
      </div>
      <div class="column field">
        <label class="label span"><b class="required-star">*</b> Last Name</label>
        <validated-input
          v-model="updates.lastName"
          name="Last Name"
          rules="required"
          :showErrorIcon="true"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column field">
        <label class="label span"><b class="required-star">*</b> Email</label>
        <validated-input
          v-model="updates.email"
          name="Email"
          rules="required|email"
          :showErrorIcon="true"
        />
      </div>
      <div class="column field">
        <label class="label span"><b class="required-star">*</b> Phone</label>
        <validated-input
          v-model="updates.phone"
          name="Phone" 
          :rules="{ required: true, regex: /^\+?[0-9()-]*$/ }"
          :showErrorIcon="true"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column field">
        <label class="checkbox neutral-gray">
          <input type="checkbox" class="mr-2 filter-checkbox" v-model="updates.smsConsent">
          I consent to receive SMS text messages from this business.
        </label>
      </div>
    </div>
    <!--<p class="neutral-gray has-text-weight-semibold text-sm mb-5">
      In case you want to reload a previous quote
    </p> 
    <div class="columns">
      <div class="column field">
        <label class="label span">Existing Quote Number <span class="optional-flag">Optional</span></label>
        <input
          v-model="updates.quoteNumber"
          class="input"
          name="quote_number"           
        />
        <p v-if="otherErrors" class="help is-danger">{{otherErrors}}</p>
      </div>
    </div>-->
    <div class="columns">
      <div class="column field">
        <label class="label span">Notes <span class="optional-flag">Optional</span></label>
        <textarea
          v-model="updates.notes"
          rows="8"    
          name="notes"
          class="textarea"
          placeholder="Type your notes or questions here."  
        />
      </div>
    </div>
  </fieldset>
</div>
