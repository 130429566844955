
<div>
  <div class="is-flex is-align-content-center is-justify-content-center is-text-center h-100">
    <button class="control-button control-button-minus is-flex is-align-items-center" :disabled="disabled || localValue === minimum" @click="change(-1)">
      <svg width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.4375 1.29999H16.5625" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
    <div class="order-item-quantity is-size-6">
      <span class="is-text-center">{{ localValue }}</span>
    </div>
    <button class="control-button control-button-plus is-flex is-align-items-center" :disabled="disabled || localValue === maximum" @click="change(+1)">
      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 9H16.125" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.5625 1.4375V16.5625" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>
  <!--<a href="#" class="is-flex has-text-danger is-align-content-center is-justify-content-center is-text-center is-size-7 mt-1" @click.prevent="change(-localValue)">
    <span v-if="localValue > 1" >Reset</span>
    <span v-else>&nbsp;</span>
  </a>-->
</div>
