<template>
  <div class="container">
    <section class="hero is-small">
      <div class="hero-body">
        <div class="container">

          <div class="columns is-centered">
            <div class="column is-6">
              <div class="box has-background-primary">
                <h5 class="title is-5 has-text-white">
                  <span class="icon is-medium">
                    <i data-feather="search"></i>
                  </span>
                  {{ $props.area.name }} City Search
                </h5>
                <div class="dropdown is-active">
                  <div class="dropdown-trigger">
                    <div class="field">
                      <input v-model="query" @keyup="search" type="search" placeholder="Search" class="input" />
                    </div>
                  </div>
                  <div v-if="query.length" class="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                      <span v-if="filteredCities.length == 0" class="dropdown-item">
                        No results
                      </span>
                      <a v-else v-for="city in filteredCities"
                        :key="city.id"
                        :href="cityPath(city)"
                        class="dropdown-item"
                      >
                        {{ city.name }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <aside class="section">
            <div class="columns is-centered has-text-centered">
              <div class="column is-8">
                <h4 class="title is-4">Popular Cities in {{ $props.area.name }}</h4>
              </div>
            </div>
          </aside>

          <list :cities="topCities" />

          <p class="has-text-centered">
            <a @click="showAllCities = !showAllCities">View all cities in {{ $props.area.name }}</a>
          </p>
          <div v-show="showAllCities">
            <hr />
            <list :cities="allCities" />
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { cityPath } from '../../shared/city-helper'
import jsonFromTemplate from 'shared/json-from-template'
import List from './list.vue'
export default {
  components: {
    List
  },
  props: {
    area: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showAllCities: false,
      query: '',
      topCities: jsonFromTemplate('#topCities'),
      allCities: jsonFromTemplate('#allCities')
    }
  },
  computed: {
    filteredCities () {
      if (this.query.length) {
        return this.allCities.filter(i => i.name.toLowerCase().indexOf(this.query.toLowerCase()) >= 0)
      } else {
        return this.allCities
      }
    }
  },
  methods: {
    cityPath,
    search (event) {
      if (event.key === 'Enter' && this.filteredCities.length === 1) {
        const city = this.filteredCities[0]
        return window.location.href = cityPath(city)
      }
    }
  }
}
</script>

<style>
.dropdown,
.dropdown-trigger,
.dropdown-menu {
  width: 100%;
}
.dropdown-content {
  max-height: 250px;
  overflow: auto;
}
</style>
