
<div class="is-flex is-align-content-center is-justify-content-center is-text-center"> 
  <div class="order-item-quantity is-size-6 mr-3">
    0
  </div>   
  <button class="control-button control-button-plus is-flex is-align-items-center" :disabled="loading" @click="addToQuote">
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 9H16.125" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.5625 1.4375V16.5625" stroke="#767676" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>
</div>
