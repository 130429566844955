
<div class="checkout-form summary pd-0">
  <div class="checkout-form">
    <div class="is-flex is-align-items-center">
      <icon type="warning-circle" />
      <p class="ml-2 neutral-gray is-size-7">
        A hold will be placed on your card to complete the reservation. You will not be changed
        in full until our team reviews the order and confirms delivery information.
      </p>
    </div>
    <section class="my-body-section mt-5">
      <customer-fields />
    </section>
    <section class="my-body-section">
      <delivery-form> </delivery-form>
    </section>
    <section class="my-body-section mt-3">  
      <label class="label span">Notes <span class="optional-flag">Optional</span></label>
      <textarea
        v-model="notes"
        rows="8"
        class="textarea"
        placeholder="Type your notes or questions here."  
      />        
    </section>
    <section class="my-body-section mt-3">              
      <p class="neutral-gray has-text-weight-semibold text-sm my-5">How would you like to be contacted?</p>     
      <div class="relative z-0 shadow-sm rounded-md w-full control radio-list">
        <label class="radio">
          <input v-model="contactForm" type="radio" value="phone">
          <span class="ml-1 text-sm neutral-gray">Phone</span>
        </label>
        <label class="radio">
          <input v-model="contactForm" type="radio" value="email">
          <span class="ml-1 text-sm neutral-gray">Email</span>
        </label>
      </div>     
    </section>
    <section class="my-body-section">
      <div class="last-child">
        <p class="neutral-gray has-text-weight-semibold text-sm mb-4 mt-5">Payment Information</p>
          <!-- <span class="accepted-payments"></span> --> 
        <payment-form ref="payment" />
       
      </div>
    </section>
    <quote-items />
    <quote-totals />
  </div>
</div>
