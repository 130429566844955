<template>
  <a
    :href="page.slug"
    :class="{ 'is-active': active }"
    class="navbar-item"
  >
    {{ page.name }}
  </a>
</template>

<script>
export default {
  props: {
    page: Object
  },
  computed: {
    active () {
      return window.location.pathname === this.page.slug
    }
  }
}
</script>
