
<div>
  <header class="header">
    <h4 class="title is-5">
      Contact Information
    </h4>
    <p class="subtitle is-6">
      Enter your information to receive a formal quote with pricing and availability.
    </p>
  </header>

  <fieldset class="fieldset">
    <div class="columns">
      <div class="column field">
        <label class="label required">First Name</label>
        <div class="control">
          <validated-input v-model="lead.firstName" name="First name" rules="required" />
        </div>
      </div>

      <div class="column field">
        <label class="label required">Last Name</label>
        <div class="control">
          <validated-input v-model="lead.lastName" name="Last name" rules="required" />
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column field">
        <label class="label required">Email Address</label>
        <div class="control">
          <validated-input v-model="lead.email" name="Email" rules="required|email" />
        </div>
      </div>

      <div class="column field">
        <label class="label">Phone Number</label>
        <div class="control">
          <validated-input
            v-model="lead.phone"
            name="Phone number"
            :rules="{ regex: /^\+?[0-9()-]*$/ }"
          />
        </div>
      </div>
    </div>

    <div class="field">
      <label class="label">Company</label>
      <div class="control">
        <input v-model="lead.company" name="Company" class="input" />
      </div>
    </div>

    <div class="field">
      <label class="label">Notes</label>
      <div class="control">
        <textarea v-model="lead.notes" name="Company" class="textarea" />
      </div>
    </div>
  </fieldset>

</div>
