
<div>
  <section class="section">
    <h4 class="title is-spaced is-5">
      Thank you, {{ lead.firstName }}.
    </h4>
    <p class="subtitle is-6 mb-2">
      A sales rep is reviewing your request and will contact you shortly.
    </p>
    <p class="is-6">
      Feel free to reach out to us directly at <b>{{ phoneNumber }}</b>.
    </p>
  </section>
</div>
