
<form class="address-form mt-5">
  <div class="is-flex is-justify-content-space-between">
    <label class="container mr-3">
      <span class="span"><b class="required-star">*</b> Street</span>
      <validated-input v-model="deliveryAddress.line1" name="Street" rules="required" />
    </label>
    <!-- <label class="container">
      <span class="span"><b class="required-star">*</b> Street2</span>
      <validated-input v-model="deliveryAddress.line2" name="Street2" />
    </label> -->
  </div>
  <div class="is-flex is-justify-content-space-between">
    <label class="container mr-3">
      <span class="span"><b class="required-star">*</b> City</span>
      <validated-input v-model="deliveryAddress.locality" name="City" rules="required" />
    </label>
    <label class="container state select mr-3">
      <span class="span"><b class="required-star">*</b> State</span>
      <address-state-select v-model="deliveryAddress.region" class="input custom-select"/>
    </label>
    <label class="container zip">
      <span class="span"><b class="required-star">*</b> Zip</span>
      <input v-model="deliveryAddress.postalCode" name="Zip" readonly class="input"/>
    </label>
  </div>
  <!-- <label class="container">
    <span class="span">Company Name <span class="optional-flag">(optional)</span></span>
    <input v-model="customer.companyName" type="text" name="Company" class="input" />
  </label> -->
</form>
