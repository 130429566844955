
<validation-observer v-slot="{ invalid }" slim>
  <div class="head-container">
    <div v-if="lead && (quote || lead.id)" class="cart-container">
      <div class="left-content">
        <div class="card">
          <div  class="mb-5">
            <!-- Do not allow changes if it's shipping -->
            <div class="mb-1" v-if="lead.data && lead.data.useCase != 'shipping'">
              <label class="is-size-7 neutral-black has-text-weight-semibold">Type</label>
              <div class="select is-fullwidth mt-1">
                <select @change="applyFilter()" v-model="updates.purchaseType" class="custom-select">
                  <option value="rental">Renting</option>
                  <option value="sale">Buying</option>
                </select>
              </div>
            </div>
            <div class="filters">
              <div class="mr-3 is-fullwidth">
                <label class="is-size-7 neutral-black has-text-weight-semibold">Length</label>
                <div class="select is-fullwidth mt-1">
                  <select @change="applyFilter()" v-model="updates.size" class="custom-select">
                  <option :value="value" v-for="(label, value) in availableSizes" :key="value">{{label}}</option>            
                  </select>
                </div>
              </div>      
              <div class="mr-3 is-fullwidth" v-if="updates.purchaseType == 'sale'">
                <label class="is-size-7 neutral-black has-text-weight-semibold">Condition</label>
                <div class="select is-fullwidth mt-1">
                  <select @change="applyFilter()" v-model="updates.condition" class="custom-select">
                    <option v-for="(label, value) in conditions" :value="value" :key="value">{{label}}</option>             
                  </select>
                </div>
              </div>  
              <div class="mr-3 is-fullwidth">
                <label class="is-size-7 neutral-black has-text-weight-semibold">Door Config</label>
                <div class="select is-fullwidth mt-1">
                  <select @change="applyFilter()" v-model="updates.door" class="custom-select">
                    <option :value="value" v-for="(label, value) in doorConfiguration" :key="value">{{label}}</option>      
                  </select>
                </div>
              </div>
              <div class="is-fullwidth">
                <label class="is-size-7 neutral-black has-text-weight-semibold">Height</label>
                <div class="select is-fullwidth mt-1">
                  <select @change="applyFilter()" v-model="updates.height" class="custom-select">
                    <option value="any">Any</option>
                    <option value="standard">8'6</option>
                    <option value="HC">9'6</option>
                  </select>
                </div>
              </div>     
            </div>
          </div>
          <section class="product-list">
            <article class="product-option container-item" v-for="priceBookEntry in currentPriceBooks" :key="priceBookEntry.id">
              <product-details :price-book-entry="priceBookEntry"></product-details>
            </article>
            <p v-if="!currentPriceBooks.length" class="mt-5">
              Currently we have no products available for the selected options - <a href="/contact">Contact Us</a>
            </p>
          </section>
        </div>
        <div v-if="(personalInfoVisible || orderInfoVisible) || (showPrice && orderInfoVisible)" :class="personalInfoVisible || orderInfoVisible ? 'mt-5' : ''" class="card checkout-form checkout-form-container">                  
          <personal-info-form :showPrice="showPrice" ref="personalInfoForm" v-if="personalInfoVisible || orderInfoVisible"/>
          <checkout-form ref="checkoutForm" v-if="showPrice && orderInfoVisible"/>
          <payment-form ref="payment" v-if="showPrice && orderInfoVisible" />
        </div>
        <div class="card card-gray">
          <div v-if="showPrice" class="is-flex is-justify-content-space-between">
            <div 
              v-if="!orderInfoVisible"
              class="level-item is-halfwidth"                
            >
              <button
                @click="requestQuote" 
                class="button is-fullwidth"
                :class="[loading ? 'is-loading' : '', personalInfoVisible ? 'action-button' : 'close-button']"
                :disabled="personalInfoVisible && (loading || invalid || (quote && !quote.items.length) || !quote)"
          
              >
                {{personalInfoVisible ? 'Send Quote' : 'Email Quote'}}
              </button>
            </div>

            <div 
              v-if="!personalInfoVisible"
              class="level-item is-halfwidth" 
            >
              <button 
                  @click="reserveOrder" 
                  class="button action-button is-fullwidth" 
                  :disabled="(loading || invalid || (quote && !quote.items.length) || !quote)"
                  :class="{ 'is-loading': loading }"
                >
                {{orderInfoVisible ? 'Confirm Order' : 'Reserve Order'}}     
              </button>
            </div>
            <div 
              v-if="personalInfoVisible || orderInfoVisible"
              class="level-item is-halfwidth" 
            >
              <button 
                  @click="cancel" 
                  class="button close-button is-fullwidth"                    
                >
                  Cancel       
              </button>
            </div>
          </div>
          <div v-if="!showPrice">
            <div class="level-item is-fullwidth">
              <button
                @click="requestQuote" 
                class="button action-button is-fullwidth"
                :disabled="loading || invalid || (quote && !quote.items.length) || !quote"
                :class="{ 'is-loading': loading }"
              >
                {{personalInfoVisible ? 'Email Quote' : 'Request Quote'}}                  
              </button>
            </div>
          </div>
        </div>                          
      </div>       
      <div v-if="quote && quote.items.length > 0" class="right-content ">
        <div class="card" >
          <quote-items />
          <quote-totals />
        </div>
        <div class="card card-gray" v-if="quote && getRecommendedProductInPricebooks(currentPriceBooks).length > 0">
          <section class="product-list recommended-products">
            <span class="neutral-gray has-text-weight-semibold text-sm mb-4">Other solutions you may like</span>
            <div class="product-option mt-5" v-for="recommended in getRecommendedProductInPricebooks(currentPriceBooks)" :key="recommended.id">
              <recommended-products :price-book-entry="recommended"></recommended-products>
            </div>
          </section>
        </div>
      </div>
    </div>
    <p class="call">Have a question? Call
      <icon type="phone" />
      <a
        :href="`tel:${organization.phone}`"
      >
        {{ formatPhoneNumber(organization.phone) }}
      </a>
    </p>
  </div>
  <div v-if="!lead || !lead.id" class="cart-empty">
    <p class="is-size-5 neutral-black has-text-centered">Your cart is empty</p>
    <p class="is-size-6 neutral-black has-text-centered"><a href="/solutions/container-sales"> See our product list</a></p> 
  </div> 
  <div v-if="thankYouModalVisible">
    <thanks :closeModal="closeModal" :type="actionType" :closedQuote="closedQuote"/>
  </div>
</validation-observer>
