
<div class="page-gallery-block">
  <div v-if="block.galleryLayout === 'Slider'" class="slider-wrapper">
    <carousel class="slider" :perPage="1" :autoplay="false" paginationActiveColor="#174984" ref="carousel">
      <slide v-for="(slide, index) in block.slides" :key="index">
        <figure class="image">
          <img :src="slide.url" :alt="slide.fileName"/>
        </figure>
        <figcaption v-if="slide.altText">
          {{ slide.altText }}
        </figcaption>
      </slide>
    </carousel>
    <button @click.prevent="modalImage = block.images[$refs.carousel.currentPage]" class="button is-text slider-modal-button" aria-label="maximize slider image">
      <span class="icon">
        <i data-feather="maximize"></i>
      </span>
    </button>
  </div>
  <div v-else-if="block.galleryLayout === 'Grid_1x'" class="gallery-grid gallery-container">
    <grid-item :block="block" grid="is-12" v-on:setModalImage="modalImage = $event"/>
  </div>
  <div v-else-if="block.galleryLayout === 'Grid_2x'" class="gallery-grid gallery-container">
    <grid-item :block="block" grid="is-6" v-on:setModalImage="modalImage = $event" />
  </div>
  <div v-else-if="block.galleryLayout === 'Grid_3x'" class="gallery-grid gallery-container">
    <grid-item :block="block" grid="is-4" v-on:setModalImage="modalImage = $event" />
  </div>
  <div v-else-if="block.galleryLayout === 'Grid_4x'" class="gallery-grid gallery-container">
    <grid-item :block="block" grid="is-3" v-on:setModalImage="modalImage = $event"/>
  </div>
  <div v-else class="card">
    <div class="card-content">
      <div class="has-text-centered">
        <figure class="image">
          <img :src="activeImage.url" @click="modalImage = activeImage" :alt="activeImage.fileName"/>
        </figure>
      </div>
    </div>
    <div class="card-thumbnails">
      <div class="columns is-multiline is-mobile">
        <div v-for="(thumb, index) in block.thumbnails" :key="thumb.id" class="column is-2">
          <figure @mouseover="activeImage = block.images[index]" class="image">
            <img :src="thumb.url" @click="modalImage = block.images[index]" :alt="thumb.fileName" />
          </figure>
        </div>
      </div>
    </div>
  </div>
  <figcaption v-if="block.galleryCaption" class="gallery-caption">
    {{ block.galleryCaption }}
  </figcaption>

  <modal v-if="modalImage" @close="modalImage = null" :body-min-height="'650'" class="is-responsive">
    <div slot="body">
      <img :src="modalImage.url" :alt="modalImage.fileName" />

      <figcaption v-if="modalImage.altText">
        {{ modalImage.altText }}
      </figcaption>

    </div>
  </modal>
</div>
