<template>
  <validation-observer v-slot="{ invalid }" slim>
    <div class="head-container">
      <div v-if="lead && (quote || lead.id)" class="cart-container">
        <div class="left-content">
          <div class="card">
            <div  class="mb-5">
              <!-- Do not allow changes if it's shipping -->
              <div class="mb-1" v-if="lead.data && lead.data.useCase != 'shipping'">
                <label class="is-size-7 neutral-black has-text-weight-semibold">Type</label>
                <div class="select is-fullwidth mt-1">
                  <select @change="applyFilter()" v-model="updates.purchaseType" class="custom-select">
                    <option value="rental">Renting</option>
                    <option value="sale">Buying</option>
                  </select>
                </div>
              </div>
              <div class="filters">
                <div class="mr-3 is-fullwidth">
                  <label class="is-size-7 neutral-black has-text-weight-semibold">Length</label>
                  <div class="select is-fullwidth mt-1">
                    <select @change="applyFilter()" v-model="updates.size" class="custom-select">
                    <option :value="value" v-for="(label, value) in availableSizes" :key="value">{{label}}</option>            
                    </select>
                  </div>
                </div>      
                <div class="mr-3 is-fullwidth" v-if="updates.purchaseType == 'sale'">
                  <label class="is-size-7 neutral-black has-text-weight-semibold">Condition</label>
                  <div class="select is-fullwidth mt-1">
                    <select @change="applyFilter()" v-model="updates.condition" class="custom-select">
                      <option v-for="(label, value) in conditions" :value="value" :key="value">{{label}}</option>             
                    </select>
                  </div>
                </div>  
                <div class="mr-3 is-fullwidth">
                  <label class="is-size-7 neutral-black has-text-weight-semibold">Door Config</label>
                  <div class="select is-fullwidth mt-1">
                    <select @change="applyFilter()" v-model="updates.door" class="custom-select">
                      <option :value="value" v-for="(label, value) in doorConfiguration" :key="value">{{label}}</option>      
                    </select>
                  </div>
                </div>
                <div class="is-fullwidth">
                  <label class="is-size-7 neutral-black has-text-weight-semibold">Height</label>
                  <div class="select is-fullwidth mt-1">
                    <select @change="applyFilter()" v-model="updates.height" class="custom-select">
                      <option value="any">Any</option>
                      <option value="standard">8'6</option>
                      <option value="HC">9'6</option>
                    </select>
                  </div>
                </div>     
              </div>
            </div>
            <section class="product-list">
              <article class="product-option container-item" v-for="priceBookEntry in currentPriceBooks" :key="priceBookEntry.id">
                <product-details :price-book-entry="priceBookEntry"></product-details>
              </article>
              <p v-if="!currentPriceBooks.length" class="mt-5">
                Currently we have no products available for the selected options - <a href="/contact">Contact Us</a>
              </p>
            </section>
          </div>
          <div v-if="(personalInfoVisible || orderInfoVisible) || (showPrice && orderInfoVisible)" :class="personalInfoVisible || orderInfoVisible ? 'mt-5' : ''" class="card checkout-form checkout-form-container">                  
            <personal-info-form :showPrice="showPrice" ref="personalInfoForm" v-if="personalInfoVisible || orderInfoVisible"/>
            <checkout-form ref="checkoutForm" v-if="showPrice && orderInfoVisible"/>
            <payment-form ref="payment" v-if="showPrice && orderInfoVisible" />
          </div>
          <div class="card card-gray">
            <div v-if="showPrice" class="is-flex is-justify-content-space-between">
              <div 
                v-if="!orderInfoVisible"
                class="level-item is-halfwidth"                
              >
                <button
                  @click="requestQuote" 
                  class="button is-fullwidth"
                  :class="[loading ? 'is-loading' : '', personalInfoVisible ? 'action-button' : 'close-button']"
                  :disabled="personalInfoVisible && (loading || invalid || (quote && !quote.items.length) || !quote)"
            
                >
                  {{personalInfoVisible ? 'Send Quote' : 'Email Quote'}}
                </button>
              </div>
  
              <div 
                v-if="!personalInfoVisible"
                class="level-item is-halfwidth" 
              >
                <button 
                    @click="reserveOrder" 
                    class="button action-button is-fullwidth" 
                    :disabled="(loading || invalid || (quote && !quote.items.length) || !quote)"
                    :class="{ 'is-loading': loading }"
                  >
                  {{orderInfoVisible ? 'Confirm Order' : 'Reserve Order'}}     
                </button>
              </div>
              <div 
                v-if="personalInfoVisible || orderInfoVisible"
                class="level-item is-halfwidth" 
              >
                <button 
                    @click="cancel" 
                    class="button close-button is-fullwidth"                    
                  >
                    Cancel       
                </button>
              </div>
            </div>
            <div v-if="!showPrice">
              <div class="level-item is-fullwidth">
                <button
                  @click="requestQuote" 
                  class="button action-button is-fullwidth"
                  :disabled="loading || invalid || (quote && !quote.items.length) || !quote"
                  :class="{ 'is-loading': loading }"
                >
                  {{personalInfoVisible ? 'Email Quote' : 'Request Quote'}}                  
                </button>
              </div>
            </div>
          </div>                          
        </div>       
        <div v-if="quote && quote.items.length > 0" class="right-content ">
          <div class="card" >
            <quote-items />
            <quote-totals />
          </div>
          <div class="card card-gray" v-if="quote && getRecommendedProductInPricebooks(currentPriceBooks).length > 0">
            <section class="product-list recommended-products">
              <span class="neutral-gray has-text-weight-semibold text-sm mb-4">Other solutions you may like</span>
              <div class="product-option mt-5" v-for="recommended in getRecommendedProductInPricebooks(currentPriceBooks)" :key="recommended.id">
                <recommended-products :price-book-entry="recommended"></recommended-products>
              </div>
            </section>
          </div>
        </div>
      </div>
      <p class="call">Have a question? Call
        <icon type="phone" />
        <a
          :href="`tel:${organization.phone}`"
        >
          {{ formatPhoneNumber(organization.phone) }}
        </a>
      </p>
    </div>
    <div v-if="!lead || !lead.id" class="cart-empty">
      <p class="is-size-5 neutral-black has-text-centered">Your cart is empty</p>
      <p class="is-size-6 neutral-black has-text-centered"><a href="/solutions/container-sales"> See our product list</a></p> 
    </div> 
    <div v-if="thankYouModalVisible">
      <thanks :closeModal="closeModal" :type="actionType" :closedQuote="closedQuote"/>
    </div>
  </validation-observer>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Step from '../flow/steps/mixin'
import ProductDetails from '../flow/steps/helpers/product-details.vue'
import QuoteItems from '../flow/steps/helpers/items.vue'
import QuoteTotals from '../summary/totals.vue'
import RecommendedProducts from '../flow/steps/helpers/recommended-products.vue'
import CheckoutForm from './helpers/checkout-form.vue'
import PersonalInfoForm from './helpers/personal-info-form.vue'
import PaymentForm from '../flow/steps/helpers/payment-form.vue'
import { ValidationObserver } from 'vee-validate'
import Thanks from './helpers/thanks.vue'
import ahoy from 'shared/ahoy'
import Icon from './helpers/icon.vue'
import { formatPhoneNumber } from '../../../shared/utils'

export default {
  components: {
    ProductDetails,
    QuoteItems,
    QuoteTotals,
    RecommendedProducts,
    CheckoutForm,
    ValidationObserver,
    PaymentForm,
    Thanks,
    PersonalInfoForm,
    Icon
  },
  mixins: [ Step ],
  props: {
    token: {
      type: String,
      required: false,
      default: ''
    }
  },
  async mounted () {
    if (this.token) {
      const quote = await this.$store.dispatch('quote/quote.session.init', { token: this.token })
      //await this.$store.dispatch('quote/lead.load', quote.opportunity.leadId)
      await this.$store.dispatch('quote/pricing.load')
      if (quote && quote.opportunity && quote.opportunity.closed) {
        this.$store.commit('quote/reset')
        this.$store.commit('quote/flow/step.set', this.steps.indexOf('start'))
      } else if (quote && quote.status == 'draft') {
        window.location.href = '/cart'
      }
    }
  },
  async created () {
    await this['pricing.get']()
    
    if (!this.lead) {
      return
    }

    const { purchaseType, size, useCase, condition, door, height } = this.lead.data
    let currentCondition = condition 
    if (!currentCondition) {
      currentCondition = purchaseType == 'rental' ? 'used' : 'one_trip'
    }
    this.updates = {
      ...this.updates,
      purchaseType, 
      size, 
      useCase,
      door: door || 'any',
      height: height || 'any',
      condition: currentCondition
    }
    if (this.quote && this.quote.items.length > 0) {
      if (!this.updates.purchaseType) {
        this.updates.purchaseType = this.quote.items[0].priceBookEntry.product.rentalType == 'none' ? 'sale' : 'rental'
      }
      if (!this.updates.size) {
        this.updates.size = this.quote.items[0].priceBookEntry.product.size
      }
      if (!this.updates.condition) {           
        this.updates.condition = this.quote.items[0].priceBookEntry.product.condition
      }
    }
    this.$store.dispatch('quote/flow/options.compute')
    this.applyFilter()
  },
  data() {
    return {
      priceBooks: null,
      personalInfoVisible: false,
      orderInfoVisible: false,
      doorConfiguration: {
        any: 'Any',
        cargo_door: 'Cargo Door',
        double_door: 'Double Door',
        open_side: 'Open Side',
        open_top: 'Open Top',
        soft_top: 'Soft Top',
        hard_top: 'Hard Top',
        roll_door: 'Roll Door',
        flat_rack: 'Flat Rack',
        working_refrigerated: 'Working Refrigerated',
        non_working_refrigerated: 'Non Working Refrigerated',
        other: 'Other'
      },    
      shippingConditions: [
        'one_trip',
        'iicl',
        'cargo_worthy'
      ],
      conditions: {
        'one_trip': 'One Trip',
        'refurbished': 'Refurbished',
        'cargo_worthy': 'Cargo Worthy',
        'wind_water_tight': 'Wind & Water Tight'   
      },
      loading: false,
      thankYouModalVisible: false,
      actionType: '',
      closedQuote: {}
    }
  },
  computed: {
    ...mapState('quote', [  'priceBook', 'quote', 'lead', 'errors' ]),
    ...mapState('quote/flow', [ 'priceBookEntries', 'options', 'gtagEvent', 'originalPriceBookEntries' ]),    
    ...mapGetters('quote/flow', [ 'steps', 'stepComponent', 'isComplete' ]),
    showPrice() {
      if (this.priceBook && this.priceBook.priceVisibility == 'private') {
        return false
      } else if (this.quote) {
        return this.quote.items.filter(item => item.priceBookEntry.priceVisibility == 'private').length == 0
      }
      return true
    },
    availableSizes() {
      const sizes = {
        '10': '10ft',
        '20': '20ft',
        '40': '40ft',
        '45': '45ft'
      }
      const { useCase } = this.lead.data
      if (useCase) {
        switch (useCase) {
        case 'storage':
          return sizes
        case 'shipping':
          delete sizes['10']
          return sizes
        default:
          delete sizes['10']
          delete sizes['45']
          return sizes
        }
      }
      return sizes
    },
    currentPriceBooks: {
      get() {
        return this.priceBooks !== null ? this.priceBooks : this.priceBookEntries
      },
      set(newValue) {
        this.priceBooks = [...newValue]
      }
    }
  },
  methods: {
    ...mapActions('quote', ['pricing.get']),
    async save () {
      const { lead, updates } = this
      ahoy.track('lead.update', updates)
      lead.data = { ...lead.data, ...updates }
      return await this.$store.dispatch('quote/lead.save', lead)
    },
    cancel() {
      this.personalInfoVisible = false
      this.orderInfoVisible = false
    },
    filterOptions(items, option, value) {
      return items.filter(i => i.product[option] && i.product[option] === value)
    },
    async applyFilter() {
      const { purchaseType, size, useCase, condition, height } = this.lead.data
      let priceBooks = [...this.originalPriceBookEntries]
      let selectedPurchaseType = this.updates.purchaseType || purchaseType
      let selectedSize = this.updates.size || size
      let selectedCondition = this.updates.condition || condition
      let doorConfiguration = this.updates.door || ''
      let selectedHeight = this.updates.height || height
      let classification = selectedPurchaseType === 'sale' ? 'container' : 'container_rental'
      if (selectedPurchaseType == 'rental') {
        selectedCondition = 'other'
      } else {
        selectedCondition = this.updates.condition || 'one_trip'
      }
      priceBooks = priceBooks.filter(i => i.product.classification === classification)
      priceBooks = priceBooks.filter(i => i.product.size && i.product.size !== 'ODD' && i.product.size.replace(/\D/g,'') == selectedSize)
      if (selectedCondition) {
        priceBooks = priceBooks.filter(i => {
          return i.product.condition == selectedCondition
        })
      }
      if (doorConfiguration != 'working_refrigerated' && doorConfiguration != 'non_working_refrigerated') {
        switch (useCase) {
        case 'coldStorage':
          priceBooks = this.filterOptions(priceBooks, 'config', 'working_refrigerated')
          break
        case 'insulatedStorage':
          priceBooks =  this.filterOptions(priceBooks, 'config', 'non_working_refrigerated')
          break
        case 'shipping':
          priceBooks = priceBooks.filter(i => this.shippingConditions.includes(i.product.condition))
          break
        case 'storage':
          priceBooks = priceBooks.filter(i => i.product.config && i.product.config != 'working_refrigerated' && i.product.config != 'non_working_refrigerated')
          break
        }
      } else if (doorConfiguration == 'working_refrigerated') {
        priceBooks = this.filterOptions(priceBooks, 'config', 'working_refrigerated')
      } else if (doorConfiguration != 'non_working_refrigerated') {
        priceBooks =  this.filterOptions(priceBooks, 'config', 'non_working_refrigerated')
      }
      if (doorConfiguration && doorConfiguration != 'any') {   
        priceBooks = this.filterOptions(priceBooks, 'config', doorConfiguration)         
      }
      if (selectedHeight && selectedHeight != 'any') {  
        priceBooks = priceBooks.filter(i => {
          if (i.product.size.includes('HC') && selectedHeight == 'HC') {
            return i
          } else if (!i.product.size.includes('HC') && selectedHeight != 'HC') {
            return i
          } else {
            return false
          }
        })        
      }
      this.currentPriceBooks = priceBooks
    },
    getRecommendedProductInPricebooks(currentPriceBooks) {
      let recommended = []
      const alreadyOnReccomended = []
      const productsOnFilteredPriceBook = currentPriceBooks.map(item => item.product.id)

      this.quote.items.forEach(item => {
        const recommendedIds = item.priceBookEntry.product.recommendedProducts.map(recommendedProduct => recommendedProduct.relatedProduct.id)
        recommended = recommended.concat(this.originalPriceBookEntries.filter(priceBook => {          
          if (recommendedIds.includes(priceBook.product.id) && 
            productsOnFilteredPriceBook.includes(item.priceBookEntry.product.id) && 
            !alreadyOnReccomended.includes(priceBook.product.id) && 
            !productsOnFilteredPriceBook.includes(priceBook.product.id)
          ) {
            alreadyOnReccomended.push(priceBook.product.id)
            return true
          }
          return false
        }))
      })
      return recommended
    },
    async reserveOrder() {
      if (!this.orderInfoVisible) {
        this.orderInfoVisible = true
        return
      }
      if (this.loading) return
      this.loading = true
      const personalInfo = await this.$refs.personalInfoForm.save()
      if (personalInfo && personalInfo.errors && personalInfo.errors.length) {
        console.warn(personalInfo.errors)
      } else {
        const checkout = await this.$refs.checkoutForm.save()
        if (checkout && checkout.errors && checkout.errors.length) {
          console.warn(checkout.errors)
        } else {
          this.closedQuote = this.quote
         
          const { token } = await this.$refs.payment.tokenize()
          if (!token) {
            this.$emit('enabled', true)
            this.loading = false
            return { errors: ['missing credit card information'] }
          } else {
            await this.createPayment(token)
            await this.closeOpportunity()
            this.thankYouModalVisible = !this.thankYouModalVisible
            this.actionType = this.thankYouModalVisible ? 'reservation' : ''
            window.scrollTo(0,0)
            this.orderInfoVisible = false
            this.loading = false
          }
        }
      }
    },
    createPayment(token) {
      return this.$store.dispatch('quote/quote.save', {
        eventName: 'payment.add',
        input: {
          payment: {
            paymentType: 'card',
            stripeSourceId: token.id,
            notes: 'Reserve Order'
          }
        }
      })
    },
    closeOpportunity() {
      return this.$store.dispatch('quote/opportunity.save', {
        eventName: 'close',
        input: {
          quoteId: this.quote.id,
          status: 'closed_won',
          paymentMethod: 'credit_card'
        }
      })
    },
    closeModal() {
      this.thankYouModalVisible = false
      if (!this.quote || !this.lead) {
        window.location.reload() 
      }
    },
    async requestQuote() {
      if (!this.personalInfoVisible) {
        this.personalInfoVisible = true
        return
      }
      if (this.loading) return
      this.loading = true
      const result = await this.$refs.personalInfoForm.save()
      if (result && result.errors && result.errors.length) {
        console.warn(result.errors)
      } else {
        this.closedQuote = this.quote
        this.thankYouModalVisible = true
        this.actionType = this.thankYouModalVisible ? 'email' : ''
        window.scrollTo(0,0)
      }
      if (window.gtag) {
        window.gtag('event', this.gtagEvent)
        window.gtag('event', 'conversion', {'send_to': 'AW-1039884839/0NUSCPGWqawYEKfE7e8D'})
      }
      this.loading = false
      this.personalInfoVisible = false
    },
    formatPhoneNumber
  }
}
</script>
<style lang="scss">
  .header-banner {
    width:100%;
    @media (max-width: 520px) {
      height: 90px;
    } 
  }
  .header-title {
    position: absolute; 
    top: 2.5rem; 
    color: white; 
    left: 10%;

    h1 {
      font-size: 2rem;
      font-weight: 600;
      text-shadow: 4px 4px 10px black;
    }

    h2 {
      text-shadow: 4px 4px 10px black;
      font-size: 1.125rem;
      font-weight: 600;
    }
    @media (max-width: 868px) {    
        top: 1.5rem;  

        h1 {
          font-size: 2rem;
        }    
      
    }
    @media (max-width: 768px) {    
        top: 1rem;  

        h1 {
          font-size: 1.375rem;
        }
        h2 {
          font-size: 0.875rem;
        }
      
    }
  } 
</style>
<style scoped lang="scss">
  .head-container {
    padding: 4.125rem 7.75rem;
    padding-bottom: 1.5rem;
    background-color: #f7f9ff;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 874px) {
      padding: 0 0 4.125rem 0rem;
    }
  }
  .filters {
    display: flex;
    @media (max-width: 575px) {
      display: block;
    }
  }
  .call {
    color: #767676;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    align-items: center;
    div {
      margin-left: 12px;
    }
    a {
      margin-left: 4px;
    }
  }
  .cart-container {
    background-color: #f7f9ff;
    display: flex;
    justify-content: center;
    // display: grid;
    // grid-template-columns: repeat(2, minmax(0, 1fr));
    // gap: 2.75rem;

    .left-content {
      width: 60%;
      padding: 0.75rem;
    }
    .right-content {
      width: 40%;
      padding: 0.75rem;
    }
    .is-fullwidth {
      width: 100%;
    }
    .neutral-gray {
      color: #767676;
    }
    .product-option {
      border: 1px solid #061627;
      border-radius: 2px;
      padding: 0.75rem;
      margin-top: 0.75rem;
    }
    .card {
      background: #fff;
      padding: 1.375rem;
      margin-bottom: 2.75rem;

      .summary-wrapper.checkout-form {
        padding-top: 0;
      }
    }
    .card-gray {
      background: #EDEFF7;
      padding: 1.375rem;

      .product-option {
        border: unset;
        padding: 0;

        .recommended-product {
          border-radius: 2px;
        }
      }
    }

    @media (max-width: 1200px) {
      padding: 4.125rem 1.5rem;
    }

    @media (max-width: 968px) {
      display: grid;
      padding: 4.125rem 2.75rem;

      .left-content {
        width: 100%;
      }
      .right-content {
        width: 100%;
      }

      .level-item:not(:last-child) {
        margin-bottom: 0
      }
    }

    @media (max-width: 480px) {
      padding: 4.125rem 1.25rem;
    }
  }
  .cart-empty {
    padding: 5rem;
    color: #061627;
  }
</style>