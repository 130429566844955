<template>
<tbody class="">
  <tr class="has-text-weight-semibold is-borderless">
    <td class="has-text-centered">
      <span class="control-wrapper" v-if="product.classification !== 'transportation'">
        <quantity-control
          v-model="quantity"
          :minimum="0"
          :disabled="loading"
          class="is-small"
          @input="handleQuantityChange" />
      </span>
      <span v-else class="py-1 px-2 delivery-quantity">{{ item.quantity }}</span>
    </td>
    <td>
      <template v-if="product.classification !== 'transportation'">
        <a :href="`/products/${product.slug}`">{{ product.name }}</a>
      </template>
      <template v-else>
        {{ product.name }}
      </template>
    </td>
    <td v-if="showPrice" class="has-text-right">{{ dollars(item.priceCents) }}</td>
  </tr>
  </tbody>
</template>

<script>
import { dollars } from 'shared/filters'
import QuantityControl from 'shared/components/quantity-control.vue'
export default {
  components: {
    QuantityControl
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    showPrice: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      loading: false,
      quantity: this.item.quantity
    }
  },
  computed: {
    product () {
      return this.item.priceBookEntry.product
    }
  },
  methods: {
    dollars,
    // async remove () {
    //   await this.$store.dispatch('quote/quote.save', {
    //     eventName: 'item.delete',
    //     input: {
    //       item: {
    //         id: this.item.id
    //       }
    //     }
    //   })
    // },
    async handleQuantityChange () {
      this.loading = true
      await this.$store.dispatch('quote/quote.save', {
        eventName: 'item.update',
        input: {
          item: {
            id: this.item.id,
            quantity: this.quantity
          }
        }
      })
      this.loading = false
    }
  }
}
</script>

<style scoped>
.table td,
.table th {
  border: 0 !important;
}
.delivery-quantity {
  border-radius: 4px;
  background: #eee;
  position: relative;
  top: 8px;
}
</style>
