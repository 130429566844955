
<p class="has-text-centered">
  <a v-if="status" class="button is-static">{{ status }}</a>
  <a v-else @click.prevent="geolocate()" class="button p-0" :class="showIcon ? '' : 'pl-0'" href="#">
    <span class="icon is-small" v-if="showIcon">
      <i data-feather="crosshair"></i>
    </span>
    <span class="has-text-weight-bold">Use my current location</span>
  </a>
</p>
